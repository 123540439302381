import { Text } from "@mantine/core";
import { Box, Slider } from "@mui/material";

const SliderInput: React.FC<{
  id: string;
  title: string;
  data: any;
  setData: any;
}> = ({ id, title, data, setData }) => {
  const filter = id.split(".")[0];
  const age = id.split(".")[1];
  return (
    <Box>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "24px",
          paddingBottom: "8px",
        }}
      >
        <Text mb={"lg"}>{title}</Text>
        <Box
          sx={{
            background: "#FFFFFF",
            boxShadow: "unset",
            borderRadius: "8px",
            alignItems: "center",
            padding: "9px 16px 1px 16px ",
            border: "1px solid #767676",
          }}
        >
          <Slider
            min={16}
            value={Object.values(Object.values(data[filter][age]) ?? {})}
            onChange={(event: Event, value: number | number[]) => {
              if (typeof value == "number") return;
              const filter = {
                ...data.filter,
                age: { min: value[0] < 16 ? 16 : value[0], max: value[1] },
              };
              setData({ ...data, filter: filter });
            }}
            valueLabelDisplay="auto"
            size="small"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SliderInput;
