import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FC } from "react";
import { FormErrors } from "@mantine/form";
import useLang from "../../../hooks/useLang";
import { Box } from "@mantine/core";

const languages = {
  kaz: {
    Birth_date: "Туған күні",
  },
  rus: {
    Birth_date: "Дата рождения",
  },
};

interface UserData {
  first_name: string;
  last_name: string;
  birth_date: Date | null;
  promo?: string;
}

interface Props {
  data: UserData;
  errors: FormErrors;
  SetData: (data: UserData) => void;
}

export const CustomDatePicker: FC<Props> = ({ data, errors, SetData }) => {
  const { language } = useLang();
  return (
    <Box
      style={{
        marginBottom: "18px",
        borderRadius: "20px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
        <DatePicker
          label={languages[language].Birth_date}
          value={data.birth_date}
          onChange={(value: string | null) => {
            if (!value) return;
            SetData({ ...data, birth_date: new Date(value) });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                sx: {
                  fontFamily: "Gilroy",
                  borderRadius: 3,
                  backgroundColor: "#FFFFFF",
                  height: "47px",
                  width: "140px",
                },
              }}
              placeholder="DD/MM/YYYY"
              sx={{
                "& input": {
                  paddingLeft: "20px",
                },
                "& label": {
                  fontFamily: "Gilroy",
                  marginTop: "-4px",
                  fontWeight: 400,
                  left: "8px",
                  "&.MuiInputLabel-shrink": {
                    marginTop: "0px",
                    left: "0px",
                  },
                },
              }}
              error={errors["user.birth_date"] !== undefined}
              helperText={errors["user.birth_date"]?.toString()}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};
