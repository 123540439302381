import { Flex, Grid, Paper } from "@mantine/core";
import { FC, useEffect } from "react";
import MainLayout from "../components/Shared/MainLayout";
import { TransactionInputCard } from "../components/add-transactions/TransactionInputCard";
import { Controls } from "../components/add-transactions/Controls";
import { useForm } from "@mantine/form";
import { IconPlus } from "@tabler/icons-react";
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "react-query";
import { CreateTransactionsRequest } from "../services/requests/CreateTransactionsRequest";
import { balanceService } from "../services/Balance";

export const AddTransactionsPage: FC = () => {
  const form = useForm<{
    message: { kaz: string; rus: string };
    transactions: { key: string; phone_number: string; amount: number }[];
  }>({
    initialValues: {
      message: {
        kaz: "Сізге сауалнама үшін бонус түсті",
        rus: "Вам начислен бонус за опрос",
      },
      transactions: [],
    },
  });

  const mutation = useMutation(
    async (data: CreateTransactionsRequest) => {
      return await balanceService.CreateTransactions(data);
    },
    {
      onSuccess: () => {
        form.reset();
      },
      onError: () => {},
    }
  );

  return (
    <MainLayout>
      <form
        onSubmit={form.onSubmit((values) => {
          mutation.mutate(values);
        })}
      >
        <Grid columns={10} p={"20px 10px"}>
          <Grid.Col span={6}>
            <Flex direction="column" gap="sm">
              {form.values.transactions.map((item, index) => (
                <TransactionInputCard
                  key={item.key}
                  value={item}
                  setValue={(value: {
                    phone_number: string;
                    amount: number;
                  }) => {
                    let temp = form.values.transactions;
                    temp[index] = { ...value, key: temp[index].key };
                    form.setFieldValue("transactions", temp);
                  }}
                  onClear={() => {
                    form.setFieldValue(
                      "transactions",
                      form.values.transactions.filter((_, i) => index !== i)
                    );
                  }}
                />
              ))}
              <Paper
                p="md"
                radius="lg"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  form.setFieldValue("transactions", [
                    ...form.values.transactions,
                    {
                      key: uuidv4(),
                      phone_number: "",
                      amount:
                        form.values.transactions.length > 0
                          ? form.values.transactions[
                              form.values.transactions.length - 1
                            ].amount
                          : 0,
                    },
                  ])
                }
              >
                <Flex align="center" justify="center">
                  <IconPlus size="32px" />
                </Flex>
              </Paper>
            </Flex>
          </Grid.Col>
          <Grid.Col span={4}>
            <Controls
              message={form.values.message}
              setKazMessage={(values) =>
                form.setFieldValue("message.kaz", values)
              }
              setRusMessage={(values) =>
                form.setFieldValue("message.rus", values)
              }
              isLoading={mutation.isLoading}
              setValues={(values) => {
                form.setFieldValue("transactions", [
                  ...form.values.transactions,
                  ...values.map((item) => ({ key: uuidv4(), ...item })),
                ]);
              }}
              onClear={form.reset}
            />
          </Grid.Col>
        </Grid>
      </form>
    </MainLayout>
  );
};
