import { Button } from "@mui/material";
import styled from "@emotion/styled";

const SuccessButtom = styled(Button)(({ width }: any) => {
  return {
    color: "#333333",
    textTransform: "none",
    fontSize: "11px",
    width: width,
    borderRadius: "8px",

    "&.MuiButton-contained": {
      backgroundColor: "#73A9C2",
      color: "white",
    },
    "&.MuiButton-outlined": {
      border: "1px solid #333333",
      color: "#333333",
    },

    "&.Mui-disabled": {
      backgroundColor: "#AEAEAE",
      color: "#FFFFFF",
      cursor: "not-allowed",
      "&.MuiButton-contained": {
        backgroundColor: "#AEAEAE",
        color: "#FFFFFF",
      },
    },
  };
});

const FastButton = ({
  children,
  variant,
  width,
  style,
  click,
  disabled,
  type,
}: any) => {
  style.fontWeight = 700;
  return (
    <SuccessButtom
      variant={variant}
      onClick={click}
      width={width}
      style={style}
      disabled={disabled}
      type={type}
    >
      {children}
    </SuccessButtom>
  );
};

export default FastButton;
