import { Box } from "@mui/system";
import FastButton from "../UI/FastButton";
import { useModal } from "../../hooks/useModal";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import useMobile from "../../hooks/useMobile";

const DeleteMember = ({ fun }: any) => {
  const { setModal } = useModal();
  const { language } = useLang();
  const { isMobile } = useMobile();

  const width = (window.innerWidth * (isMobile ? 70 : 100)) / 100;

  return (
    <Box sx={{ width: isMobile ? width : "484px" }}>
      <Box
        sx={{
          borderBottom: "1px solid #E0E0E0",
          borderTop: "1px solid #E0E0E0",
          padding: "24px 0",
          margin: "24px 0",
        }}
      >
        {languages[language].ConfirmDeleteManager}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <FastButton
          click={() => setModal(false)}
          style={{
            padding: "8px 30px",
            fontSize: "14px",
            border: "none",
            background: "none",
          }}
        >
          {languages[language].Cancel}
        </FastButton>
        <FastButton
          click={() => {
            fun();
            setModal(false);
          }}
          variant={"contained"}
          style={{ padding: "8px 30px", fontSize: "14px" }}
          // disabled={btn}
        >
          {languages[language].Delete}
        </FastButton>
      </Box>
    </Box>
  );
};

export default DeleteMember;
