import { useState } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import FastIcon from "../UI/FastIcon";
import useLang from "../../hooks/useLang";

function LangSwitch() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { language, setLang } = useLang();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    language == "rus" ? "Рус" : "Қаз"
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        style={{
          border: "none",
          textTransform: "capitalize",
          color: "black",
          fontSize: "17px",
        }}
        endIcon={<FastIcon name="down" />}
      >
        {selectedLanguage}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          sx={{
            padding: "10px 50px",
          }}
          onClick={() => {
            handleLanguageChange("Қаз");
            setLang("kaz");
          }}
        >
          Қаз
        </MenuItem>
        <MenuItem
          sx={{
            padding: "10px 50px",
          }}
          onClick={() => {
            handleLanguageChange("Рус");
            setLang("rus");
          }}
        >
          Рус
        </MenuItem>
      </Menu>
    </>
  );
}

export default LangSwitch;
