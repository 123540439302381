import {useContext, useEffect, useState} from "react";
import {TabletContext} from "../context/TabletProvider";

export default function useTablet() {
    const { isTablet, setTabletFun } = useContext(TabletContext);

    return {
      isTablet,
      setTabletFun,
    };
}
