import { Box } from "@mui/system";
import MainLayout from "../Shared/MainLayout";
import FastButton from "../UI/FastButton";
import FastIcon from "../UI/FastIcon";
import FastInput from "../UI/FastInput";
import { Left } from "./Blocks/Left";
import Right from "./Blocks/Right";
import useMobile from "../../hooks/useMobile";
import { useEffect, useState } from "react";
import { balanceService } from "../../services/Balance";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "@mantine/core";
import { useQuery as uq } from "react-query";
import { ErrorResponse } from "../../models/error";

const Wallet = ({ props }: any) => {
  const { isMobile } = useMobile();
  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ["balance"],
    queryFn: balanceService.get,
    refetchInterval: () => 1000,
  });

  const transactions = uq(
    "list_transactions",
    async () => {
      return await balanceService.listTransactions();
    },
    {
      onSuccess: (response) => {},
      onError: (error: ErrorResponse) => {},
      refetchInterval: () => 1000,
    }
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: isMobile ? "flex" : "grid",
        flexDirection: isMobile ? "column" : "unset",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "78px",
        minHeight: "87vh",
      }}
    >
      {isLoading ? null : (
        <>
          <Left balance={data?.balance || 0} data={transactions.data}>
            {isMobile ? (
              <Right
                balance={data?.balance || 0}
                _refetch={() => {
                  transactions.refetch();
                  refetch();
                }}
              />
            ) : null}
          </Left>
          {isMobile ? null : (
            <Right
              balance={data?.balance || 0}
              _refetch={() => {
                transactions.refetch();
                refetch();
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Wallet;
