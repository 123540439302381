import { Box } from "@mui/system";
import useMobile from "../../../hooks/useMobile";
import useLang from "../../../hooks/useLang";
import { languages } from "../../../Languages/languages";
import React, { FC } from "react";
import { ListTransactions } from "../ListTransactions";
import { ListTransactionsResponse } from "../../../services/responses/ListTransactions";

interface Props {
  balance: number;
  children: React.ReactNode;
  data: ListTransactionsResponse[] | undefined;
}

export const Left: FC<Props> = ({ balance, children, data }) => {
  const { language } = useLang();
  const { isMobile } = useMobile();

  return (
    <Box sx={{ padding: isMobile ? "16px" : "0 0 0 24px" }}>
      <Box
        sx={{
          fonWweight: 900,
          fontSize: "24px",
          lineHeight: "120%",
          color: "#333333",
          marginBottom: "30px",
          marginTop: "40px",
        }}
      >
        {languages[language].Wallet}
      </Box>
      <Box
        sx={{
          padding: "24px",
          background: "#73A9C2",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
          borderRadius: "24px",
          justifyContent: "center",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          color: "#F1F1F1",
        }}
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "120%",
            paddingBottom: "8px",
          }}
        ></Box>
        <Box sx={{ fontWeight: 700, fontSize: "20px", lineHeight: "120%" }}>
          {languages[language].WalletAmount} {balance}
        </Box>
      </Box>
      {children}
      <Box sx={{ padding: "36px 0" }}>
        <Box
          sx={{
            fonWweight: 700,
            fontSize: "20px",
            lineHeight: "120%",
            color: "#333333",
            marginBottom: "24px",
          }}
        >
          {languages[language].Left_t}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListTransactions data={data} />
        </Box>
      </Box>
    </Box>
  );
};
