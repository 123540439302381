import { DEFAULT_THEME, LoadingOverlay, createStyles } from "@mantine/core";
import { Box } from "@mui/system";
import { IconLoader } from "@tabler/icons-react";
import useLoading from "../../hooks/useLoading";
import FastIcon from "../UI/FastIcon";

const useStyles = createStyles(() => ({
  overlay: {
    position: "fixed",
    bottom: 0,
  },
}));

const customLoader = (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "20px",
    }}
  >
    <FastIcon name={"logo"} height={"20px"} />
    <Box pt="20px">
      <svg
        width="54"
        height="54"
        viewBox="-6 -6 48 48"
        xmlns="http://www.w3.org/2000/svg"
        stroke={DEFAULT_THEME.colors.blue[6]}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="4">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </Box>
  </Box>
);

export default () => {
  const { isLoading } = useLoading();
  const { classes } = useStyles();
  return (
    <LoadingOverlay
      className={classes.overlay}
      h={"100%"}
      visible={isLoading}
      loader={customLoader}
      overlayBlur={100}
    />
  );
};
