import { Box } from "@mui/material";
import { title } from "process";
import { FC } from "react";
import useMobile from "../../hooks/useMobile";

export interface AnalysisBoxProps {
  onClick?: () => void;
  title: string;
  label: string;
}

export const AnalysisBox: FC<AnalysisBoxProps> = ({
  onClick,
  title,
  label,
}) => {
  const { isMobile } = useMobile();
  return (
    <Box
      onClick={onClick}
      sx={{
        padding: "13px 21px",
        background: "#73A9C2",
        borderRadius: "12px",
        marginRight: isMobile ? 0 : "36px",
        marginBottom: isMobile ? "24px" : 0,
        cursor: onClick !== undefined ? "pointer" : "",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "100%" : "unset",
          paddingBottom: "8px",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "17px",
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "24px",
          lineHeight: "29px",
        }}
      >
        {label}
      </Box>
    </Box>
  );
};
