import { Box } from "@mui/system";
import SurveyCard from "./Blocks/SurveyCard";
import { surveyService } from "../../services/Surveys";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FastIcon from "../UI/FastIcon";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import useMobile from "../../hooks/useMobile";
import { UserSurveyResponse } from "../../services/responses/SurveyResponse";

const Surveys = () => {
  const [surveyList, setSurveyList] = useState<UserSurveyResponse[]>([]);
  const { language } = useLang();
  const navigate = useNavigate();
  const { isMobile } = useMobile();

  const getSurveys = () => {
    surveyService
      .getSurveys(true)
      .then((data) => {
        setSurveyList(data);
      })
      .catch((err) => {
        navigate("/");
      });
  };

  useEffect(() => {
    getSurveys();
  }, []);

  if (surveyList?.length === 0 || surveyList === null) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: 400,
            height: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <FastIcon name="search" />
          <Box
            sx={{
              marginTop: "16px",
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            {languages[language].Survey_f}
          </Box>
          <Box
            sx={{
              marginTop: "1px",
              textAlign: "center",
              marginLeft: "20px",
              fontSize: "20px",
            }}
          >
            {languages[language].Survey_t}
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={
          isMobile
            ? {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "24px",
              }
            : {
                display: "grid",
                gridRowGap: "60px",
                gridColumnGap: "16px",
                gridTemplateColumns: "300px 300px 300px",
              }
        }
      >
        {surveyList?.map((survey: UserSurveyResponse) => (
          <SurveyCard
            key={survey.id}
            open_on_start={survey.type === "registration"}
            survey={survey}
            update={getSurveys}
          />
        ))}
      </Box>
    );
  }
};

export default Surveys;
