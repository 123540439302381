import axios, { AxiosResponse } from "axios";
import { arrayBuffer } from "stream/consumers";
import { Field } from "../components/AddSurveys/Survey";
import { CreateSurveyRequest } from "./requests/CreateSurveyRequest";
import { FileWithPath } from "file-selector";
import { CreateImageResponse } from "./responses/CreateImageResponse";
import { GetFilterUsersCountRequest } from "./requests/GetFilterUsersCountRequest";
import { GetFilterUsersCountResponse } from "./responses/GetFilterUsersCountResponse";
import { FilterOptions } from "../components/AddSurveys/helpers/CreateFilter";
import { delay, filter } from "lodash";
import { GetSurveyAnalysisResponse } from "./responses/GetSurveyAnalysisResponse";
import { ErrorContext } from "../components/Error/ErrorContext";
import { Language } from "../context/LangProvider";
import { CreateResponseRequest } from "./requests/CreateResponseRequest";
import { AdminSurveyResponse, UserSurveyResponse } from "./responses/SurveyResponse";
import { UpdateSurveyRequest } from "./requests/UpdateSurveyRequest";
import { languages } from "../Languages/languages";

const axiosApiInstance = axios.create();


const headers = (language?: string) => {
  return {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      Accept: "application/json",
      "Lang" : language,
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    },
  };
};

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

class DataService {
  async GetFilterUsersCount(body: GetFilterUsersCountRequest) {
    return axiosApiInstance
      .post<GetFilterUsersCountResponse>(
        `https://api.pikirlab.kz/filters/count`,
        body,
        headers(),
      )
      .then((response) => response.data);
  }

  async SaveFilter(filterOptions: FilterOptions) {
    return axiosApiInstance
      .post<FilterOptions>(
        `https://api.pikirlab.kz/filters`,
        filterOptions,
        headers(),
      )
      .then((response) => response.data);
  }

  async UpdateFilter(filterOptions: FilterOptions) {
    return axiosApiInstance
      .patch<FilterOptions>(
        `https://api.pikirlab.kz/filters`,
        filterOptions,
        headers(),
      )
      .then((response) => response.data);
  }

  async DeleteFilter(name: string) {
    return axiosApiInstance
      .delete<FilterOptions>(`https://api.pikirlab.kz/filters/${name}`, headers())
      .then((response) => response.data);
  }

  async getSurveys(typeSurveys: boolean = true) {
    return axiosApiInstance
      .get<UserSurveyResponse[]>(
        `https://api.pikirlab.kz/surveys?active=${typeSurveys}`,
        headers(),
      )
      .then((response) => response.data);
  }

  async UploadImage(file: FileWithPath) {
    var formData = new FormData();
    formData.append("file", file);

    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    };

    return await axios.post<CreateImageResponse>(
      `https://api.pikirlab.kz/files`,
      formData,
      headers
    );
  }

  async CreateSurvey(survey: CreateSurveyRequest) {
    return axiosApiInstance
      .post(`https://api.pikirlab.kz/surveys`, survey, headers())
      .then((response) => response.data);
  }

  async getCreateSurveyFilters(form_id: string) {
    return axiosApiInstance
      .get<Field[]>(`https://api.pikirlab.kz/surveys/filter/${form_id}`, headers())
      .then((response) => response.data);
  }

  async getSurvey(id: string) {
    return axiosApiInstance
      .get<AdminSurveyResponse>(`https://api.pikirlab.kz/surveys/${id}`, headers())
      .then((response) => response.data);
  }

  async UpdateSurvey( id: string, data: UpdateSurveyRequest) {
    return axiosApiInstance
      .patch(`https://api.pikirlab.kz/surveys/${id}`, data, headers())
      .then((response) => response.data);
  }

  async getFilters() {
    return axiosApiInstance
      .get<FilterOptions[]>("https://api.pikirlab.kz/filters", headers())
      .then((response) => response.data);
  }

  async getImage(link: any) {
    return axiosApiInstance.get(link, headers()).then((response: any) => {});
  }

  async getSurveyAnalysis(id: string, language: string, field: string) {
    return axiosApiInstance
      .get<GetSurveyAnalysisResponse>(
        `https://api.pikirlab.kz/responses/${id}/analysis?field=${field}`,
        headers(language)
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  }

  async GetSurveyAnalysisTime(id: string) {
    return (await axiosApiInstance.get<GetSurveyAnalysisResponse>(
      `https://api.pikirlab.kz/responses/${id}/time`,
      headers()
    )).data
  }

  async GetSurveyAnalysisCount(id: string) {
    return (await axiosApiInstance.get<GetSurveyAnalysisResponse>(
      `https://api.pikirlab.kz/responses/${id}/count`,
      headers()
    )).data
  }

  async downloadFile(id: string, lang: string) {
    return axiosApiInstance
      .get(`https://api.pikirlab.kz/responses/download?id=${id}`, headers(lang))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  async deleteSurvey(id: any) {
    return axiosApiInstance
      .delete(`https://api.pikirlab.kz/surveys/${id}`, headers(),)
      .then((response) => response.data);
  }

  async patchSurvey(id: any, data: any) {
    return axiosApiInstance
      .patch(`https://api.pikirlab.kz/surveys/${id}`, data, headers(),)
      .then((response) => response.data);
  }

  async postResponse(data: CreateResponseRequest, lang: Language) {
    await sleep(1000);
    return axiosApiInstance
      .post(`https://api.pikirlab.kz/responses`, data, headers(lang),)
      .then((response) => response.data);
  }

  async SendInvitations(id: string) {
    return axiosApiInstance
      .post(`https://api.pikirlab.kz/emails/invitations/${id}`, null, headers(),)
      .then((response) => response.data);
  }
}
export const surveyService = new DataService();
