import { Box } from "@mui/system";
import FastInput from "../UI/FastInput";
import FastButton from "../UI/FastButton";
import useMobile from "../../hooks/useMobile";
import { FC, useEffect, useState } from "react";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import PhoneInput from "./Blocks/PhoneInput";
import { matchIsValidTel } from "mui-tel-input";
import { PostFeedbackRequest } from "../../services/requests/PostFeedbackRequest";
import { GetFeedbackCategories } from "../../services/responses/GetFeedbackCategories";
import { Loader, Textarea, Title } from "@mantine/core";
import { emailsService } from "../../services/EmailsService";
import CustomSelect from "./Blocks/CustomSelect";
import { SxProps, Theme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";

export interface HelpWindowProps {
  sx?: SxProps<Theme>;
}

export const HelpWindow: FC<HelpWindowProps> = ({ sx }) => {
  const { language } = useLang();
  const [categories, setCategories] = useState<GetFeedbackCategories>({
    kaz: [],
    rus: [],
  });
  const [request, setRequest] = useState<PostFeedbackRequest>({
    category: "",
    phone_number: "+7",
    email: "",
    text: "",
  });
  const { isMobile } = useMobile();

  const mutation = useMutation(
    async (data: PostFeedbackRequest) => {
      return await emailsService.PostFeedback(data);
    },
    {
      onSuccess: (data) => {
        setRequest({ category: "", phone_number: "+7", email: "", text: "" });
        notifications.show({
          message: languages[language].FeedbackSuccess,
          autoClose: 10000,
          styles: (theme) => ({
            root: {
              backgroundColor: "#73A9C2",
              borderColor: "#73A9C2",
              "&::before": { backgroundColor: theme.white },
            },
            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.blue[7] },
            },
          }),
        });
      },
      onError: (error: any) => {
        notifications.show({
          title: languages[language].Error,
          message: languages[language].EmptyFields,
          autoClose: 10000,
          styles: (theme) => ({
            root: {
              borderColor: "#73A9C2",
              "&::before": { backgroundColor: theme.colors.red[5] },
            },
            title: { color: theme.colors.red[5] },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.blue[7] },
            },
          }),
        });
      },
    }
  );

  const submit = async () => {
    mutation.mutate(request);
  };

  const getProblems = () => {
    emailsService.GetFeedbackCategories().then((data) => {
      setCategories(data);
    });
  };

  useEffect(() => {
    getProblems();
  }, []);

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.25)",
        borderRadius: "14px",
        padding: "21px 16px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Title size="28px" color="dimmed">
        {languages[language].feedback_title}
      </Title>
      <Box
        sx={{
          display: isMobile ? "flex" : "grid",
          flexDirection: isMobile ? "column" : "unset",
          gridTemplateColumns: "1fr 1.5fr",
          gridColumnGap: "33px",
        }}
      >
        <Box>
          <CustomSelect
            style={{
              background: "#F5F4F3",
              marginBottom: isMobile ? "10px" : "50px",
            }}
            options={categories[language]?.map((el: any) => {
              return { text: el, value: el };
            })}
            sx={
              sx ?? {
                height: "47px",
                marginBottom: "10px",
              }
            }
            inner={{
              sx: {
                height: "47px",
              },
            }}
            data={request}
            setData={setRequest}
          />
          <PhoneInput
            style={{
              marginTop: isMobile ? "10px" : "20px",
              marginBottom: isMobile ? "10px" : "unset",
              background: "#F5F4F3",
              borderRadius: "8px",
            }}
            onChange={(value: string) => {
              setRequest({ ...request, phone_number: value });
            }}
            error={
              request.phone_number !== "+7" &&
              !matchIsValidTel(request.phone_number)
            }
            value={request.phone_number}
            label={languages[language].FeedBackPhoneNumber}
          />
          <FastInput
            sx={{
              marginTop: isMobile ? "10px" : "20px",
              marginBottom: isMobile ? "20px" : "unset",
              background: "#F5F4F3",
              borderRadius: "8px",
            }}
            value={request.email}
            change={(value: any) => {
              setRequest({ ...request, email: value.target.value });
            }}
            label={languages[language].FeedBackEmail}
          />
          <FastButton
            style={{
              width: "100%",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "12px",
              padding: "20px",
              borderRadius: "8px",
              marginTop: "20px",
              display: isMobile ? "none" : "block",
            }}
            variant={"contained"}
            click={() => submit()}
          >
            {mutation.isLoading ? (
              <Loader color="white" size="1.5rem" />
            ) : (
              languages[language].FeedbackSend
            )}
          </FastButton>
        </Box>
        <Box>
          <Textarea
            placeholder={languages[language].FeedBackText}
            autosize
            minRows={9}
            radius="4px"
            onChange={(value) =>
              setRequest({ ...request, text: value.target.value })
            }
            value={request.text}
            styles={(theme) => ({
              borderRadius: "5px",

              wrapper: {
                border: "1px solid #ced4da",
                borderRadius: "4px",
              },
              input: {
                backgroundColor: "#F5F4F3",

                fontWeight: 400,
                fontSize: "1rem",
                borderColor: "#F5F4F3",
                borderWidth: "2px",
              },
            })}
          />
        </Box>
        <FastButton
          style={{
            width: "100%",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "12px",
            padding: "20px",
            borderRadius: "0",
            marginTop: "33px",
            display: !isMobile ? "none" : "block",
          }}
          click={() => submit()}
          variant={"contained"}
        >
          {mutation.isLoading ? (
            <Loader color="white" size="1.5rem" />
          ) : (
            languages[language].FeedbackSend
          )}
        </FastButton>
      </Box>
    </Box>
  );
};
