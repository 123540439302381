import { Box } from "@mui/material";
import { FC } from "react";
import { HelpWindow } from "../components/HelpWindow/HelpWindow";
import { EmailVerification } from "../components/Notification/EmailVerification";
import Surveys from "../components/Surveys/Surveys";
import useLang from "../hooks/useLang";
import useLoading from "../hooks/useLoading";
import useMobile from "../hooks/useMobile";
import useUser from "../hooks/useUser";
import { languages } from "../Languages/languages";

export const UserPage: FC = () => {
  const { language } = useLang();
  const { isMobile } = useMobile();
  const { userData } = useUser();
  const { isLoading } = useLoading();
  if (isLoading) {
    return null;
  }
  return (
    <Box
      sx={
        isMobile
          ? {
              padding: "0 24px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }
          : {
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }
      }
    >
      {userData.user.email?.verified ? <></> : <EmailVerification />}
      <Box
        id="survey"
        style={{
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "30px",
        }}
      >
        {languages[language].Side_one}
      </Box>
      <Surveys />
      {/* <span id="help">
        <HelpWindow
          sx={{
            height: "47px",
            marginBottom: isMobile ? "10px" : "0px",
          }}
        />
      </span> */}
    </Box>
  );
};
