import axios from "axios";
import { LoginRequest } from "./requests/LoginRequest";
import { ResetPasswordRequest } from "./requests/ResetPasswordRequest";
import { VerifyAccountRequest } from "./requests/VerifyAccountRequest";
import { LoginResponse } from "./responses/LoginResponse";
import { SignupRequest } from "./requests/SignupRequest";

const axiosApiInstance = axios.create();

let headers = {
  headers: {
    Authorization: "",
    Accept: "application/json",
    Lang: "",
  },
};

class AuthService {
  async GetSMSCode(data: ResetPasswordRequest) {
    data.phone_number = data.phone_number?.replace(/\D/g, "");
    return axiosApiInstance
      .post("https://api.pikirlab.kz/passwords/reset", data, headers)
      .then((response) => response.data);
  }

  async VerifyAccount(data: VerifyAccountRequest, language: string) {
    data.phone_number = data.phone_number?.replace(/\D/g, "");
    headers.headers.Lang = language;
    return axiosApiInstance
      .post("https://api.pikirlab.kz/passwords/verify", data, headers)
      .then((response) => response.data);
  }

  async Signup(data: SignupRequest) {
    return axiosApiInstance
      .post("https://api.pikirlab.kz/signup", data, headers)
      .then((response) => response.data);
  }

  async Login(data: LoginRequest, language: string) {
    headers.headers.Lang = language;
    return axiosApiInstance
      .post<LoginResponse | { message: string }>(
        "https://api.pikirlab.kz/login",
        data,
        headers
      )
      .then((response) => response.data);
  }
}

export const authService = new AuthService();
