import { Box } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { LoginRequest } from "../../../services/requests/LoginRequest";
import PhoneInput from "../../ResetPassword/Blocks/PhoneInput";
import FastInput, { FastInputProps } from "../../UI/FastInput";
import { LoginForm } from "../types";

const validate = (value: string, pattern: RegExp) => {
  return pattern.test(value);
};

const TextInput: FC<FastInputProps> = (props: FastInputProps): ReactElement => {
  return (
    <FastInput
      InputProps={{
        sx: {
          borderRadius: 3,
          backgroundColor: "#FFFFFF",
        },
      }}
      {...props}
    />
  );
};

export interface FieldsProps {
  form: LoginForm;
  requestBody: LoginRequest;
  setRequestBody: (body: LoginRequest) => void;
  errors: boolean[];
  setErrors: (error: boolean[]) => void;
  helperTexts: string[];
}

export const Fields: FC<FieldsProps> = ({
  form,
  requestBody,
  setRequestBody,
  errors,
  setErrors,
  helperTexts,
}) => {
  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    const phone_number = "+7" + requestBody.phone_number.slice(1);
    const isValidate = validate(
      phone_number.replace(/\D/g, ""),
      form.fields[0].regex ?? /^.*$/
    );
    setValidated(isValidate);
    setErrors([!isValidate, requestBody.password === ""]);
  }, [requestBody]);

  return (
    <Box>
      <PhoneInput
        InputProps={{
          sx: {
            borderRadius: 3,
          },
        }}
        value={"+7" + requestBody.phone_number.slice(1)}
        onChange={(value: string) => {
          const phone_number = "+7" + value.replace(/\D/g, "").slice(1);
          setRequestBody({
            ...requestBody,
            phone_number: phone_number.replace(/\D/g, ""),
          });
        }}
        style={{
          marginBottom: "18px",
          borderRadius: "10px",
        }}
        error={!validated || helperTexts[0] !== ""}
        helperText={helperTexts[0]}
      />
      <TextInput
        type={"password"}
        change={(event: React.ChangeEvent<HTMLInputElement>) => {
          setRequestBody({ ...requestBody, password: event.target.value });
        }}
        value={requestBody.password}
        label={form.fields[1].label}
        style={{
          marginBottom: "18px",
          borderRadius: "20px",
        }}
        error={helperTexts[0] !== ""}
        helperText={helperTexts[1]}
      />
    </Box>
  );
};
