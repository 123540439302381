import { Box } from "@mui/system";
import FastSearch from "../UI/FastSearch";
import FastButton from "../UI/FastButton";
import { useModal } from "../../hooks/useModal";
import { useState } from "react";
import { promote } from "../../services/Promote";
import useMobile from "../../hooks/useMobile";
import { languages } from "../../Languages/languages";
import useLang from "../../hooks/useLang";

const AddMember = ({ fun }: any) => {
  const { setModal } = useModal();
  const [promo, setPromo] = useState("");
  const [err, setErr] = useState(false);
  const { isMobile } = useMobile();
  const { language } = useLang();

  const addMember = () => {
    promote
      .addMember(promo)
      .then((data) => {
        setModal(false);
        fun();
      })
      .catch((err) => {
        setErr(true);
      });
  };

  return (
    <Box
      sx={{
        width: isMobile ? "300px" : "518px",
        paddingTop: isMobile ? "0px" : "0",
        paddingBottom: isMobile ? "10px" : "0",
        fontWeight: 400,
        fontSize: isMobile ? "14px" : "16px",
        lineHeight: isMobile ? "18px" : "20px",
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          fontWeight: 300,
          fontSize: isMobile ? "14px" : "16px",
          lineHeight: isMobile ? "15px" : "15px",
          paddingBottom: isMobile ? "9px" : "16px",
          marginTop: isMobile ? "9px" : "24px",
          borderTop: "1px solid #E0E0E0",
          paddingTop: "24px",
        }}
      >
        Промо код
      </Box>
      <FastSearch
        placeholder={languages[language].EnterPromo}
        error={err}
        change={(el: any) => setPromo(el.target.value)}
        style={{ padding: "10px", width: isMobile ? "auto" : "291px" }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingTop: isMobile ? "10px" : "24px",
          marginTop: isMobile ? "10px" : "24px",
          width: "100%",
          borderTop: "1px solid #E0E0E0",
        }}
      >
        <FastButton
          click={() => setModal(false)}
          style={{
            padding: isMobile ? "5px 10px" : "1px 30px",
            fontSize: isMobile ? "12px" : "16px",
            border: "none",
            background: "none",
            marginBottom: isMobile ? "10px" : "",
          }}
        >
          Назад
        </FastButton>
        <FastButton
          click={() => addMember()}
          variant={"contained"}
          style={{ padding: "8px 30px", fontSize: "14px" }}
          disabled={promo === ""}
        >
          Добавить
        </FastButton>
      </Box>
    </Box>
  );
};

export default AddMember;
