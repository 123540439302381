import { Modal } from "@mantine/core";
import { FC, useRef, useState, useEffect } from "react";
import { DocumentViewer } from "react-documents";
import { usePdf } from "react-pdf-js";
import useMobile from "../../hooks/useMobile";
import useTablet from "../../hooks/useTablet";

export interface FileViewerModalProps {
  title: string;
  fileID: string;
  isOpen: boolean;
  handleClose: () => void;
}

export const FileViewerModal: FC<FileViewerModalProps> = ({
  title,
  fileID,
  isOpen,
  handleClose,
}) => {
  const { isMobile } = useMobile();
  const { isTablet } = useTablet();

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={title}
      radius={"20px"}
      size="auto"
      styles={{
        root: {
          display: "flex",
          justifyContent: "center",
        },
        content: {
          width: isTablet ? "700px" : isMobile ? "90%" : "1280px",
          height: isTablet ? "auto" : isMobile ? "95%" : "1000px",
        },
        body: {
          height: isTablet ? "auto" : isMobile ? "90%" : "90%",
        },
      }}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <iframe
          src={`https://drive.google.com/file/d/${fileID}/preview`}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </Modal>
  );
};
