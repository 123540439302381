import { Box } from "@mui/system";
import FastButton from "../UI/FastButton";
import { useModal } from "../../hooks/useModal";
import { useState } from "react";
import FastInput from "../UI/FastInput";
import { faqService } from "../../services/faqService";
import { CreateFAQCategoryRequest } from "../../services/requests/CreateFAQCategoryRequest";
import useMobile from "../../hooks/useMobile";
import { Container, Text } from "@mantine/core";
import TextInput from "../Shared/TextInput";

const AddCategory = ({ fun }: any) => {
  const { setModal } = useModal();
  const { isMobile } = useMobile();
  const [category, setCategory] = useState<CreateFAQCategoryRequest>({
    kaz: {
      name: undefined,
    },
    rus: {
      name: undefined,
    },
  });

  const addCategory = () => {
    faqService.createCategory(category).then((data) => {
      setModal(false);
      fun();
    });
  };

  return (
    <Box sx={{ width: isMobile ? "300px" : "484px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingTop: "24px",
          borderTop: "1px solid #E0E0E0",
          marginTop: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Text
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            Категорияның аты *
          </Text>
          <TextInput
            id="kaz.name"
            placeholder={"Қазақша ат еңгізіңіз"}
            data={category}
            setData={setCategory}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Text
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            Название категорий *
          </Text>
          <TextInput
            id="rus.name"
            placeholder={"Введите название на русском"}
            data={category}
            setData={setCategory}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "24px",
          marginTop: "24px",
          borderTop: "1px solid #E0E0E0",
        }}
      >
        <FastButton
          click={() => setModal(false)}
          style={{
            padding: "8px 30px",
            fontSize: "14px",
            border: "none",
            background: "none",
          }}
        >
          Вернутся назад
        </FastButton>
        <FastButton
          click={() => addCategory()}
          variant={"contained"}
          style={{ padding: "8px 30px", fontSize: "14px" }}
          // disabled={btn}
        >
          Далее
        </FastButton>
      </Box>
    </Box>
  );
};

export default AddCategory;
