import { Box, Center, Flex, Text, Title } from "@mantine/core";
import { FC, useEffect } from "react";
import useLang from "../hooks/useLang";
import useLoading from "../hooks/useLoading";
import FastIcon from "../components/UI/FastIcon";
import LangSwitch from "../components/Shared/LangSwitch";
import { useNavigate } from "react-router-dom";

const languages = {
  kaz: {
    title: "Хабарлама",
    descriptions: [
      "Құрметті пайдаланушы,",
      "Пайдаланушы келісіміне сәйкес алаяқтық әрекеттерге күдіктенгендіктен сіздің есептік жазбаңыз бұғатталған.",
      "Сізде жеке кабинетке, жаңа сауалнамаларға, сондай-ақ ұпай жинау және шығару мүмкіндігі жабық.",
      "Егер сіз шешіммен келіспесеңіз, деректер мен дәлелдер беру арқылы негізгі беттегі кері байланыс түрінде хабарласа аласыз.",
    ],
    end: "Түсінгеніңіз үшін рахмет,",
  },
  rus: {
    title: "Уведомление",
    descriptions: [
      "Уважаемый пользователь,",
      "Ваш аккаунт заблокирован из-за подозрений в мошеннической активности согласно Пользовательскому соглашению.",
      "У вас закрыт доступ в личный кабинет, к новым опросам, а также возможность накапливать и выводить баллы.",
      "Если вы не согласны с решением, вы можете обратиться в форме обратной связи на главной странице, предоставив данные и вашу аргументацию.",
    ],
    end: "Спасибо за понимание,",
  },
};

export const SuspendedPage: FC = () => {
  const { language } = useLang();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: "#FBF7F4",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          padding: "40px",
        }}
      >
        <FastIcon
          name={"logo"}
          height={"20px"}
          width={"98px"}
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "30px",
        }}
      >
        <LangSwitch />
      </Box>
      <Center h={"100%"} my={"auto"}>
        <Flex w="80%" direction="column" gap="20px">
          <Title color="#73A9C3" align="center">
            {languages[language].title}
          </Title>
          <Text align="start">{languages[language].descriptions[0]}</Text>
          <Text align="start">{languages[language].descriptions[1]}</Text>
          <Text align="start">{languages[language].descriptions[2]}</Text>
          <Text align="start">{languages[language].descriptions[3]}</Text>
          <Text>{languages[language].end}</Text>
          <Text color="#73A9C3" size="xl">
            Pikirlab
          </Text>
        </Flex>
      </Center>
    </Box>
  );
};
