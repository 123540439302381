import axios from "axios";
import { SetUserDataRequest } from "./requests/SetUserDataRequest";
import { ListUsersResponse } from "./responses/ListUsersResponse";

const axiosApiInstance = axios.create();

let headers = {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  },
};

class AccountService {
  async Delete(ids: string[]) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .post(`https://api.pikirlab.kz/users/delete`, { ids: ids }, headers)
      .then((response) => response.data);
  }

  async UpdateStatus(status: string, ids: string[]) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .post(
        `https://api.pikirlab.kz/users/status`,
        { status: status, ids: ids },
        headers
      )
      .then((response) => response.data);
  }
}

export const accountService = new AccountService();
