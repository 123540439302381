import {useContext, useEffect, useState} from "react";
import {MobileContext} from "../context/MobileProvider";

export default function useMobile() {
    const { isMobile, setMobileFun } = useContext(MobileContext);

    return {
        isMobile,
        setMobileFun,
    };
}
