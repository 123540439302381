import { LoadingProvider } from "./LoadingProvider";
import { ModalContentProvider } from "./ModalContentProvider";
import { ModalProvider } from "./ModalProvider";
import { UserProvider } from "./UserProvider";
import { MobileProvider } from "./MobileProvider";
import { LangProvider } from "./LangProvider";
import { TabletProvider } from "./TabletProvider";
import { ModalsProvider } from "@mantine/modals";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
export const ContextProviders = ({ children }: any) => {
  return (
    <QueryClientProvider client={queryClient}>
      <MobileProvider>
        <TabletProvider>
          <UserProvider>
            <LoadingProvider>
              <ModalProvider>
                <LangProvider>
                  <ModalsProvider>
                    <ModalContentProvider>{children}</ModalContentProvider>
                  </ModalsProvider>
                </LangProvider>
              </ModalProvider>
            </LoadingProvider>
          </UserProvider>
        </TabletProvider>
      </MobileProvider>
    </QueryClientProvider>
  );
};
