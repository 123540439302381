import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Loader,
  Text,
  createStyles,
} from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { useMutation } from "react-query";
import useLang from "../../hooks/useLang";
import { ErrorResponse } from "../../models/error";
import { Transaction as TransactionModel } from "../../models/transaction";
import { UserData } from "../../models/user_data";
import { balanceService } from "../../services/Balance";
import { userService } from "../../services/UserService";
import { LoginResponse } from "../../services/responses/LoginResponse";
import { ListAccountTransactions } from "./ListAccountTransactions";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    background: "white",
    borderRadius: "16px",
    gap: "8px",
  },
}));

const languages = {
  kaz: {
    age: "Жас",
    gender: "Жыныс",
    residence: "Тұратын жер",
    country: "Тұратын ел",
    region: "Облыс",
    nationality: "Ұлт",
    language: "Сөйлесу тілі",
    family_status: "Тұрмыстық жағдай",
    family_members_count: "Отбасыдағы адамдар саны",
    children_count: "18 жасқа дейінгі балалар",
    total_income: "Отбасының жалпы кірісі",
    financial_situation: "Қаржылық жағдайы",
    employment_status: "Жумыс статусы",
    work_obligation: "Қызмет көрсету",
    ownership: "Көрсетілгендерден не бар",
    social_network: "Тізімделгендерден не пайдаланады",

    accept: "Рұқсат беру",
    reject: "Қайтару",
  },
  rus: {
    age: "Возраст",
    gender: "пол",
    residence: "Местность проживания",
    country: "Страна проживания",
    region: "Регион",
    nationality: "Национальность",
    language: "Язык общаение",
    family_status: "Семейное положение",
    family_members_count: "Количество людей в семье",
    children_count: "Дети до 18 лет",
    total_income: "Общий доход семьи",
    financial_situation: "Финансовое положение",
    employment_status: "Статус занятости",
    work_obligation: "Занятость",
    ownership: "Что имеется из указанного",
    social_network: "Чем из перечисленного пользуются",

    accept: "Разрешить",
    reject: "Отказать",
  },
};

interface Props {
  selected: TransactionModel | null;
  clear: () => void;
  refetch: () => void;
}

export const TransactionData: FC<Props> = ({ selected, refetch, clear }) => {
  const { classes } = useStyles();
  const { language } = useLang();
  const [data, setData] = useState<LoginResponse | undefined>(undefined);
  const [userData, setUserData] = useState<
    { kaz: UserData; rus: UserData } | undefined
  >(undefined);

  useEffect(() => {
    if (selected === null) return;
    mutation.mutate(selected);
  }, [selected]);

  const mutation = useMutation(
    "GetUserData",
    async (selected: TransactionModel) => {
      if (selected === null) return;
      setData(undefined);
      return await userService.GetUserData(selected.account_id);
    },
    {
      onSuccess: (response) => {
        setData(response);
        if (
          !response ||
          !response.user.user_data_kaz ||
          !response.user.user_data_rus
        )
          return;
        setUserData({
          kaz: response.user.user_data_kaz,
          rus: response.user.user_data_rus,
        });
      },
      onError: (error: ErrorResponse) => {},
    }
  );

  const update_mutation = useMutation(
    async (data: { type: "accept" | "reject"; transaction_id: string }) => {
      switch (data.type) {
        case "accept":
          return await balanceService.AcceptTransaction(data.transaction_id);
        case "reject":
          return await balanceService.RejectTransaction(data.transaction_id);
        default:
          break;
      }
    },
    {
      onSuccess: () => {
        refetch();
        clear();
      },
      onError: (error: any) => {
        alert(JSON.stringify(error.response.data.error));
      },
    }
  );

  const submit = (data: {
    type: "accept" | "reject";
    transaction_id: string;
  }) => update_mutation.mutate(data);

  if (selected === null) {
    return (
      <Box className={classes.wrapper} h="100px">
        <Text m="auto" align="center" c="gray">
          Выберите транзакцию
        </Text>
      </Box>
    );
  }

  if (data === undefined) {
    return (
      <Box className={classes.wrapper} h="100px">
        <Loader m="auto" />
      </Box>
    );
  }

  if (!userData || Object.keys(userData[language]).length === 0) {
    return (
      <Box className={classes.wrapper} h="100px">
        <Text c="gray" m="auto">
          пользователь не прошел первый опрос
        </Text>
        <Flex direction="row" justify="end" gap="md" pt="20px">
          <Button
            color="red"
            variant="outline"
            loading={update_mutation.isLoading}
            onClick={() =>
              submit({ type: "reject", transaction_id: selected.id })
            }
          >
            {languages[language].reject}
          </Button>
          <Button
            style={{ background: "#73A9C2" }}
            loading={update_mutation.isLoading}
            onClick={() =>
              submit({ type: "accept", transaction_id: selected.id })
            }
          >
            {languages[language].accept}
          </Button>
        </Flex>
      </Box>
    );
  }

  return (
    <Box className={classes.wrapper}>
      <Text fw={700} size="lg">
        Данные о пользователе
      </Text>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].gender}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].gender}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].country}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].country}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].employment_status}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].employment_status}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].family_members_count}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].family_members_count}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].family_status}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].family_status}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].financial_situation}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].financial_situation}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].children_count}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].children_count}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].language}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].language}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].nationality}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].nationality}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].ownership}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Flex gap={"xs"} wrap="wrap">
            {(userData[language].ownership ?? []).map((item) => (
              <Badge key={item} variant="outline">
                {item}
              </Badge>
            ))}
          </Flex>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].region}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].region}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].residence}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].residence}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].social_network}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Flex gap={"xs"} wrap="wrap">
            {(userData[language].social_network ?? []).map((item) => (
              <Badge key={item} variant="outline">
                {item}
              </Badge>
            ))}
          </Flex>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].total_income}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Text>{userData[language].total_income}</Text>
        </Grid.Col>
      </Grid>
      <Divider />
      <Grid>
        <Grid.Col span={4}>
          <Text>{languages[language].work_obligation}</Text>
        </Grid.Col>
        <Divider orientation="vertical" />
        <Grid.Col span={7}>
          <Flex gap={"xs"} wrap="wrap">
            {(userData[language].work_obligation ?? []).map((item) => (
              <Badge key={item} variant="outline">
                {item}
              </Badge>
            ))}
          </Flex>
        </Grid.Col>
      </Grid>
      <Divider />
      <ListAccountTransactions account_id={selected.account_id} />
      <Flex direction="row" justify="end" gap="md" pt="20px">
        <Button
          color="red"
          variant="outline"
          loading={update_mutation.isLoading}
          onClick={() =>
            submit({ type: "reject", transaction_id: selected.id })
          }
        >
          {languages[language].reject}
        </Button>
        <Button
          style={{ background: "#73A9C2" }}
          loading={update_mutation.isLoading}
          onClick={() =>
            submit({ type: "accept", transaction_id: selected.id })
          }
        >
          {languages[language].accept}
        </Button>
      </Flex>
    </Box>
  );
};
