import { Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as React from "react";
import { Text } from "@mantine/core";

const DateTime: React.FC<{
  title: string;
  id: string;
  placeholder: string;
  data: any;
  setData: any;
}> = ({ title, id, placeholder, data, setData }) => {
  return (
    <Box>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "24px",
          paddingBottom: "8px",
        }}
      >
        <Text mb={"lg"}>{title}</Text>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
          <DatePicker
            label={placeholder}
            value={data[id]}
            onChange={(value) => {
              setData({ ...data, [id]: value.toISOString() });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="DD/MM/YYYY"
                fullWidth
                sx={{
                  backgroundColor: "#FFFFFF",
                  ".css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
                    "border-radius": "12px",
                  },
                  "& input": {
                    padding: "12px",
                  },
                  "& label": {
                    fontFamily: "Gilroy",
                    fontWeight: 400,
                    left: "8px",
                    top: "-3px",
                    "&.MuiInputLabel-shrink": {
                      left: "0px",
                      top: "0px",
                    },
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DateTime;
