import axios from "axios";

const axiosApiInstance = axios.create();

class DataService {
  async passwordVerify(data: any, languages: string) {
    const headers = {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        Lang: languages,
      },
    };

    return axiosApiInstance
      .post("https://api.pikirlab.kz/verify", data, headers)
      .then((response) => response.data);
  }

  async getData() {
    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    return axiosApiInstance
      .get("https://api.pikirlab.kz/user", headers)
      .then((response) => response.data);
  }

  getPromo() {
    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      },
    };
    return axiosApiInstance
      .post("https://api.pikirlab.kz/promo", {}, headers)
      .then((response) => {
        window.localStorage.setItem("promo", response.data.code);
        return response.data;
      });
  }
}
export const dataService = new DataService();
