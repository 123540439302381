import styled from "@emotion/styled";
import { MuiTelInput } from "mui-tel-input";
import React from "react";
import useLang from "../../../hooks/useLang";
import { languages } from "../../../Languages/languages";

const PhoneInputStyled = styled(MuiTelInput)(() => ({
  width: "100%",
  // height: "44px",
  "& input": {
    fontWeight: 400,
    lineHeight: "15px",
    color: "#000000",
    opacity: 0.6,
    borderRadius: "10px",
    padding: "12px 20px 12px 0px",
  },
  "& label": {
    left: "8px",
    top: "-2px",
    zIndex: 0,

    "&.MuiInputLabel-shrink": {
      left: "0px",
      top: "0px",
    },
  },
}));

const PhoneInput: React.FC<{
  value: string;
  label?: string;
  onChange: (value: string) => void;
  style: React.CSSProperties;
  error: boolean;
  InputProps?: {
    sx: {
      borderRadius?: number;
      backgroundColor?: string;
    };
  };
}> = ({ value, label, onChange, style, error, InputProps }) => {
  const { language } = useLang();
  return (
    <PhoneInputStyled
      label={label ?? languages[language].PhoneNumber}
      value={value}
      onChange={(value: string) => onChange(value)}
      onlyCountries={["KZ"]}
      focusOnSelectCountry
      defaultCountry="KZ"
      inputProps={InputProps ?? { maxLength: 15 }}
      style={style}
      error={error}
    />
  );
};

export default PhoneInput;
