import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SxProps,
  Theme,
} from "@mui/material";
import useLang from "../../../hooks/useLang";
import { languages } from "../../../Languages/languages";
import { PostFeedbackRequest } from "../../../services/requests/PostFeedbackRequest";

export interface SelectOptions {
  value: string;
  text: string;
}

const CustomSelect: React.FC<{
  options: SelectOptions[];
  style?: React.CSSProperties;
  inner: { sx?: SxProps<Theme> };
  sx?: SxProps<Theme>;
  data: PostFeedbackRequest;
  setData: (data: PostFeedbackRequest) => void;
}> = ({ options, style, inner, sx, data, setData }) => {
  const { language } = useLang();
  return (
    <FormControl
      fullWidth
      sx={
        sx ?? {
          height: "47px",
        }
      }
    >
      <InputLabel
        id="demo-simple-select-label"
        sx={{
          left: "10px",
          top: "-3px",
          height: "47px",
          "&.MuiInputLabel-shrink": {
            left: "0px",
            top: "0px",
          },
        }}
      >
        {languages[language].FeedBackCategory}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Укажите проблему"
        style={style}
        sx={inner.sx}
        value={data.category}
        onChange={(value) => {
          setData({ ...data, category: value.target.value });
        }}
      >
        {options?.map((el: any) => {
          return (
            <MenuItem key={el.value} value={el.value}>
              {el.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
