import { Box, Checkbox, Text, UnstyledButton } from "@mantine/core";
import { FC, useState } from "react";
import { FileViewerModal } from "../../FileViewer/FileViewerModal";
import { FormErrors } from "@mantine/form";
import useLang from "../../../hooks/useLang";

const languages = {
  kaz: {
    checkboxSignUpFirst: "Қолдану ережелерін ",
    And: "және ",
    checkboxSignUpSecond: "құпиялылық саясатты ",
    checkboxSignUpAccept: "қабылдаймын",
  },
  rus: {
    checkboxSignUpAccept: "Принимаю ",
    checkboxSignUpFirst: "пользовательское соглашение ",
    And: "и ",
    checkboxSignUpSecond: "политику конфиденциальности",
  },
};

interface AccountData {
  phone_number: string;
  accept_date: Date;
  accepted: boolean;
  password: string;
  confirm_password: string;
}

interface Props {
  data: AccountData;
  errors: FormErrors;
  SetData: (data: AccountData) => void;
}

export const CustomCheckbox: FC<Props> = ({ data, SetData }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [fileID, setFileID] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const { language } = useLang();
  return (
    <>
      <FileViewerModal
        isOpen={open}
        handleClose={() => setOpen(false)}
        title={title}
        fileID={fileID}
      />
      <Box
        style={{
          display: "flex",
          marginBottom: "18px",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <Checkbox
          defaultChecked={data.accepted}
          styles={{
            input: {
              cursor: "pointer",
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            SetData({ ...data, accepted: !data.accepted });
          }}
        />

        {language === "kaz" ? (
          <Text
            sx={{
              lineHeight: 1.2,
            }}
          >
            <Text
              span
              inherit
              sx={{
                color: "blue",
                cursor: "pointer",
              }}
              onClick={(event) => {
                event.stopPropagation();
                setTitle("Политика конфиденциальности");
                setFileID("1DM5mrmOcZJx1drtBsOFkWUVMHmxerkRy");
                setOpen(true);
              }}
            >
              {languages[language].checkboxSignUpFirst}
            </Text>
            {languages[language].And}
            <Text
              span
              inherit
              sx={{
                color: "blue",
                cursor: "pointer",
              }}
              onClick={(event) => {
                event.stopPropagation();
                setTitle("Пользовательское соглашение");
                setFileID("1aGeGiYWCy8AmgPGxzL_XaT9SmAJ2JSj6");
                setOpen(true);
              }}
            >
              {languages[language].checkboxSignUpSecond}
            </Text>
            {languages[language].checkboxSignUpAccept}
          </Text>
        ) : (
          <Text
            sx={{
              lineHeight: 1.2,
            }}
          >
            {languages[language].checkboxSignUpAccept}
            <Text
              span
              inherit
              sx={{
                color: "blue",
                cursor: "pointer",
              }}
              onClick={(event) => {
                event.stopPropagation();
                setTitle("Пользовательское соглашение");
                setFileID("1aGeGiYWCy8AmgPGxzL_XaT9SmAJ2JSj6");
                setOpen(true);
              }}
            >
              {languages[language].checkboxSignUpFirst}
            </Text>
            {languages[language].And}
            <Text
              span
              inherit
              sx={{
                color: "blue",
                cursor: "pointer",
              }}
              onClick={(event) => {
                event.stopPropagation();
                setTitle("Политика конфиденциальности");
                setFileID("1DM5mrmOcZJx1drtBsOFkWUVMHmxerkRy");
                setOpen(true);
              }}
            >
              {languages[language].checkboxSignUpSecond}
            </Text>
          </Text>
        )}
      </Box>
    </>
  );
};
