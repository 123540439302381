import { Box, Typography } from "@mui/material";
import FastButton from "../../UI/FastButton";
import "dayjs/locale/ru";
import { FC, useState } from "react";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import useMobile from "../../../hooks/useMobile";
import { Link } from "react-router-dom";
import useLang from "../../../hooks/useLang";
import { languages, signin_errors } from "../../../Languages/languages";
import { Loader } from "@mantine/core";
import { authService } from "../../../services/auth";
import { LoginForm } from "../types";
import { LoginRequest } from "../../../services/requests/LoginRequest";
import { Fields } from "./Fields";
import { PhoneVerify } from "../../Signup/Blocks/PhoneVerify";
import { useModal } from "../../../hooks/useModal";
import { useModalContent } from "../../../hooks/useModalContent";

export interface FormProps {
  form: LoginForm;
  requestBody: LoginRequest;
  setRequestBody: (body: LoginRequest) => void;
}

const Form: FC<FormProps> = ({ form, requestBody, setRequestBody }) => {
  const { language } = useLang();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { setUserData } = useUser();
  const { setLoading } = useLoading();
  const [isDisabled, SetDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<boolean[]>([true, true]);
  const [helperTexts, setHelperTexts] = useState<string[]>(["", ""]);
  const { setModal } = useModal();
  const { setModalContent } = useModalContent();

  const VerifyAccountModal = (phone_number: string) => {
    setModal(true);
    setModalContent({
      title: languages[language].ConfirmAccount,
      content: (
        <PhoneVerify
          phone_number={phone_number}
          navigate={() => {
            navigate("/dashboard");
          }}
        />
      ),
    });
  };

  const submit = () => {
    setLoading(true);
    SetDisabled(true);
    try {
      authService
        .Login(requestBody, language)
        .then((response) => {
          if ("access_token" in response) {
            setUserData(response);
            window.localStorage.setItem("token", response.access_token);
            if (response.account.status === "inactive") navigate("/suspended");
            else navigate("/dashboard");
          } else {
            VerifyAccountModal(requestBody.phone_number);
          }
          setLoading(false);
          SetDisabled(false);
        })
        .catch((err) => {
          setLoading(false);
          SetDisabled(false);
          setHelperTexts([
            signin_errors[language].account_not_found,
            signin_errors[language].password_incorrect,
          ]);
        });
    } catch (error) {
      setLoading(false);
      SetDisabled(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          fontWeight: "700",
          fontSize: "22px",
          lineHeight: "27px",
          color: "#000000",
          paddingBottom: "20px",
          display: isMobile ? "none" : "block",
        }}
      >
        {form.title}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: isMobile ? "17px" : "unset",
        }}
      >
        <Box
          sx={{
            display: isMobile ? "block" : "none",
            fontWeight: 700,
            fontSize: isMobile ? "22px" : "24px",
            lineHeight: "28px",
            paddingBottom: "36px",
          }}
        >
          {languages[language].Btn_Sign_up}
        </Box>

        <Fields
          form={form}
          requestBody={requestBody}
          setRequestBody={setRequestBody}
          errors={errors}
          setErrors={setErrors}
          helperTexts={helperTexts}
        />
        <Box>
          <Typography
            variant="body2"
            sx={{
              color: "#1F3840",
              marginBottom: "14px",
              opacity: "0.6",
            }}
            component={Link}
            to="/forgetpass"
          >
            {languages[language].Btn_Forget_Pass}
          </Typography>
        </Box>
        <FastButton
          click={() => submit()}
          variant={"contained"}
          type="submit"
          style={{
            padding: "8px 30px",
            fontSize: "14px",
            marginBottom: "14px",
            marginTop: "10px",
          }}
          disabled={errors[0] || errors[1]}
        >
          {isDisabled ? (
            <Loader color="white" size="1.5rem" />
          ) : (
            languages[language].Btn_Sing_in
          )}
        </FastButton>
        <FastButton
          type="submit"
          variant={"outlined"}
          style={{
            padding: "8px 30px",
            fontSize: "14px",
          }}
          click={() => {
            navigate("/signup");
          }}
        >
          {languages[language].Btn_Create}
        </FastButton>
      </Box>
    </Box>
  );
};

export default Form;
