import React, { createContext, useState } from "react";

export const MobileContext = createContext<any>({});

export const MobileProvider = ({ children }: any) => {
  const [isMobile, setMobile] = useState(true);

  const setMobileFun = (moduleState: any) => {
    setMobile(moduleState);
  };

  return (
    <MobileContext.Provider
      value={{
        isMobile,
        setMobileFun,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};
