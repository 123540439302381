import { createContext, useState } from "react";
import { languages } from "../Languages/languages";

export type Language = "rus" | "kaz";

interface ILangContext {
  language: Language;
  setLang: (lang: Language) => void;
}

export const LangContext = createContext<ILangContext>({
  language: (window.localStorage.getItem("language") ??
    Object.keys(languages)[1]) as Language,
  setLang: () => {},
});

export const LangProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [language, setLangFun] = useState<Language>(
    (window.localStorage.getItem("language") ??
      Object.keys(languages)[1]) as Language
  );

  const setLang = (language: Language) => {
    window.localStorage.setItem("language", language);
    setLangFun(language);
  };

  return (
    <LangContext.Provider
      value={{
        language,
        setLang,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
