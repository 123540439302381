import { Text } from "@mantine/core";
import { Box } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import FastInput from "../../UI/FastInput";

const NumberInput: React.FC<{
  id: string;
  title: string;
  placeholder: string;
  data: any;
  setData: any;
}> = ({ id, title, placeholder, data, setData }) => {
  return (
    <Box>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "24px",
          paddingBottom: "8px",
        }}
      >
        <Text mb={"lg"}>{title}</Text>
        <FastInput
          label={placeholder}
          variant="outlined"
          change={(value: any) => {
            setData({
              ...data,
              [id]: value.target.value != "" ? parseInt(value.target.value) : 0,
            });
          }}
          value={data[id]}
          InputProps={{
            sx: {
              borderRadius: 3,
              marginBottom: "16px",
              backgroundColor: "#FFFFFF",
              zIndex: -1,
            },
          }}
        >
          text
        </FastInput>
      </Box>
    </Box>
  );
};

export default NumberInput;
