import { Box, SvgIcon } from "@mui/material";
import icon from "../Icon/icon";

const FastIcon = ({ name, width, height, style, ...props }: any) => {
  return (
    <Box
      sx={{
        width: width,
        height: height,
        ...style,
        "&.svg": { width: "50px" },
      }}
      {...props}
    >
      {icon[name]}
    </Box>
  );
};

export default FastIcon;
