import { Button, Paper, Select, SimpleGrid } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { IconTableDown } from "@tabler/icons-react";
import { FC } from "react";
import { useMutation } from "react-query";
import { ErrorResponse } from "../../models/error";
import { balanceService } from "../../services/Balance";
import { DownloadTransactionRequest } from "../../services/requests/DownloadTransactionRequest";

const from = () => {
  let d = new Date();
  d.setMonth(d.getMonth() - 1);
  return d;
};

export const DownloadTransactions: FC = () => {
  const form = useForm<DownloadTransactionRequest>({
    initialValues: {
      from: from(),
      to: new Date(),
      status: "accepted",
    },
  });

  const mutation = useMutation(
    async (data: DownloadTransactionRequest) => {
      return await balanceService.DownloadTransactions(data);
    },
    {
      onSuccess: (response, request) => {
        form.reset();
      },
      onError: (errorResponse: ErrorResponse) => {},
    }
  );

  return (
    <Paper radius="lg" p="md">
      <form onSubmit={form.onSubmit((values) => mutation.mutate(values))}>
        <SimpleGrid cols={2} spacing="md">
          <DatePickerInput
            radius="md"
            label={"Со дня"}
            {...form.getInputProps("from")}
          />
          <DatePickerInput
            radius="md"
            label={"До дня"}
            {...form.getInputProps("to")}
          />
          <Select
            radius="md"
            label="Статус"
            data={[
              {
                label: "Принят",
                value: "accepted",
              },
              {
                label: "В ожидании",
                value: "pending",
              },
              {
                label: "Отказан или ошибка",
                value: "rejected",
              },
            ]}
            allowDeselect={false}
            {...form.getInputProps("status")}
          />
          <Button
            type="submit"
            radius="md"
            loading={mutation.isLoading}
            mt="auto"
            style={{ background: "#73A9C2" }}
            leftIcon={<IconTableDown />}
          >
            Скачать эксель
          </Button>
        </SimpleGrid>
      </form>
    </Paper>
  );
};
