import { FC, ReactElement, useEffect } from "react";
import useLang from "../../../hooks/useLang";
import FastInput, { FastInputProps } from "../../UI/FastInput";
import { Box, createStyles } from "@mantine/core";
import { FormErrors } from "@mantine/form";
import { CustomDatePicker } from "./DatePicker";
import { Promo } from "./Promo";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FastButton from "../../UI/FastButton";
import { useForceUpdate } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  header: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#000000",
    paddingBottom: "20px",
    padding: "0px 0px 20px 0px",
    [theme.fn.smallerThan(802)]: {
      padding: "10px",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    [theme.fn.smallerThan(802)]: {
      padding: "17px",
    },
  },

  buttons_wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.fn.smallerThan(802)]: {
      justifyContent: "flex-end",
      flexDirection: "column-reverse",
    },
  },

  link: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    marginRight: "20px",

    [theme.fn.smallerThan(802)]: {
      marginRight: 0,
    },
  },

  button: {
    padding: "8px 50px",
    fontSize: "14px",
    marginBottom: "0",

    [theme.fn.smallerThan(802)]: {
      padding: "8px 60px",
      marginBottom: "20px",
    },
  },
}));

interface UserData {
  first_name: string;
  last_name: string;
  birth_date: Date | null;
  promo?: string;
}

interface Props {
  data: UserData;
  errors: FormErrors;
  SetData: (data: UserData) => void;
  next: () => void;
}

const languages = {
  kaz: {
    title: "Тіркелу",
    name: "Аты",
    surname: "Тегі",
    have_account: "Аккаунтыңыз бар болса, басыңыз",
    login: "Кіру",
    next: "Келесі",
  },
  rus: {
    title: "Регистрация",
    name: "Имя",
    surname: "Фамилия",
    have_account: "Если у вас есть аккаунт, нажмите",
    login: "Войти",
    next: "Далее",
  },
};

const TextInput: FC<FastInputProps> = (props: FastInputProps): ReactElement => {
  return (
    <FastInput
      InputProps={{
        sx: {
          borderRadius: 3,
          backgroundColor: "#FFFFFF",
        },
      }}
      style={{
        marginBottom: "18px",
        borderRadius: "20px",
      }}
      {...props}
    />
  );
};

export const UserFields: FC<Props> = ({ data, SetData, errors, next }) => {
  const { language } = useLang();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, [data]);

  return (
    <Box w="100%">
      <Box className={classes.header}>{languages[language].title}</Box>
      <Box className={classes.content}>
        <TextInput
          label={languages[language].name}
          value={data.first_name}
          change={(event: React.ChangeEvent<HTMLInputElement>) => {
            SetData({ ...data, first_name: event.target.value });
          }}
          error={errors["user.first_name"] !== undefined}
          helperText={errors["user.first_name"]?.toString()}
        />
        <TextInput
          label={languages[language].surname}
          value={data.last_name}
          change={(event: React.ChangeEvent<HTMLInputElement>) => {
            SetData({ ...data, last_name: event.target.value });
          }}
          error={errors["user.first_name"] !== undefined}
          helperText={errors["user.first_name"]?.toString()}
        />
        <CustomDatePicker data={data} SetData={SetData} errors={errors} />
        <Promo data={data} SetData={SetData} errors={errors} />

        <Box className={classes.buttons_wrapper}>
          <Box
            className={classes.link}
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "17px",
            }}
          >
            {languages[language].have_account} &nbsp;
            <Link
              onClick={() => navigate("/signin")}
              sx={{ cursor: "pointer" }}
            >
              {languages[language].login}
            </Link>
          </Box>
          <FastButton
            click={next}
            variant={"contained"}
            style={{
              padding: "8px 50px",
              fontSize: "14px",
              marginBottom: "20px",
            }}
            disabled={
              data.first_name === "" ||
              data.last_name === "" ||
              errors["user.birth_date"] !== undefined ||
              data.birth_date === null
            }
          >
            {languages[language].next}
          </FastButton>
        </Box>
      </Box>
    </Box>
  );
};
