import { FC, ReactElement } from "react";
import useLang from "../../../hooks/useLang";
import FastInput, { FastInputProps } from "../../UI/FastInput";
import { Box, Loader, createStyles } from "@mantine/core";
import { FormErrors } from "@mantine/form";
import { PhoneInput } from "./PhoneInput";
import { CustomCheckbox } from "./Checkbox";
import FastButton from "../../UI/FastButton";

const useStyles = createStyles((theme) => ({
  header: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#000000",
    paddingBottom: "20px",
    padding: "0px 0px 20px 0px",
    [theme.fn.smallerThan(802)]: {
      padding: "10px",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    [theme.fn.smallerThan(802)]: {
      padding: "17px",
    },
  },
}));

const languages = {
  kaz: {
    title: "Тіркелу",
    phone_number: "Телефон нөмірі",
    password: "Құпиясөз",
    password_verification: "Құпиясөзді қайталаңыз",
    back: "Артқа қайту",
    next: "Келесі",
  },
  rus: {
    title: "Регистрация",
    phone_number: "Номер телефона",
    password: "Пароль",
    password_verification: "Подтвердить пароль",
    back: "Вернуться назад",
    next: "Далее",
  },
};

const TextInput: FC<FastInputProps> = (props: FastInputProps): ReactElement => {
  return (
    <FastInput
      InputProps={{
        sx: {
          borderRadius: 3,
          backgroundColor: "#FFFFFF",
        },
      }}
      style={{
        marginBottom: "18px",
        borderRadius: "20px",
      }}
      {...props}
    />
  );
};

interface AccountData {
  phone_number: string;
  accept_date: Date;
  accepted: boolean;
  password: string;
  confirm_password: string;
}

interface Props {
  data: AccountData;
  errors: FormErrors;
  SetData: (data: AccountData) => void;
  previous: () => void;
  loading: boolean;
}

export const AccountFields: FC<Props> = ({
  data,
  SetData,
  errors,
  previous,
  loading,
}) => {
  const { language } = useLang();
  const { classes } = useStyles();

  return (
    <Box w="100%">
      <Box className={classes.header}>{languages[language].title}</Box>
      <Box className={classes.content}>
        <PhoneInput
          label={languages[language].phone_number}
          value={"+7" + data.phone_number.slice(1)}
          onChange={(value: string) => {
            SetData({ ...data, phone_number: value.replace(/\D/g, "") });
          }}
          error={errors["account.phone_number"] !== undefined}
          helperText={errors["account.phone_number"]?.toString()}
        />
        <TextInput
          type={"password"}
          label={languages[language].password}
          value={data.password}
          change={(event: React.ChangeEvent<HTMLInputElement>) => {
            SetData({ ...data, password: event.target.value });
          }}
          error={errors["account.password"] !== undefined}
          helperText={errors["account.password"]?.toString()}
        />
        <TextInput
          type={"password"}
          label={languages[language].password_verification}
          value={data.confirm_password}
          change={(event: React.ChangeEvent<HTMLInputElement>) => {
            SetData({ ...data, confirm_password: event.target.value });
          }}
          error={errors["account.confirm_password"] !== undefined}
          helperText={errors["account.confirm_password"]?.toString()}
        />
        <CustomCheckbox data={data} errors={errors} SetData={SetData} />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FastButton
            click={previous}
            style={{
              padding: "8px 30px",
              fontSize: "14px",
              border: "none",
              background: "none",
            }}
          >
            {languages[language].back}
          </FastButton>
          <FastButton
            type="submit"
            variant={"contained"}
            style={{ padding: "8px 50px", fontSize: "14px" }}
            disabled={
              errors["account.phone_number"] !== undefined ||
              data.phone_number === "" ||
              errors["account.password"] !== undefined ||
              data.password === "" ||
              errors["account.confirm_password"] !== undefined ||
              data.confirm_password === "" ||
              !data.accepted ||
              loading
            }
          >
            {loading ? (
              <Loader color="gray" size="xs" />
            ) : (
              languages[language].next
            )}
          </FastButton>
        </Box>
      </Box>
    </Box>
  );
};
