import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useLang from "../../hooks/useLang";
import useLoading from "../../hooks/useLoading";
import { useModal } from "../../hooks/useModal";
import useUser from "../../hooks/useUser";
import { languages } from "../../Languages/languages";
import { userService } from "../../services/UserService";
import FastButton from "../UI/FastButton";

export default ({ userForm, setUserForm }: any) => {
  const { setLoading } = useLoading();
  const { setModal } = useModal();
  const { userData, setUserData } = useUser();
  const { language } = useLang();
  const submit = async () => {
    setLoading(true);

    if (userForm?.email == "") userForm.email = undefined;
    if (userForm?.first_name == "") userForm.first_name = undefined;
    if (userForm?.last_name == "") userForm.last_name = undefined;
    await userService
      .UpdateUser(userForm)
      .then((data) => {
        setUserForm(userForm);
        setUserData({ ...userData, user: { ...userData.user, ...userForm } });
        setLoading(false);
        setModal(false);
      })
      .catch((err) => {
        setLoading(false);
        setModal(false);
      });
  };
  return (
    <Box sx={{ height: "15	0px" }}>
      <Box
        sx={{
          textAlign: "center",
          paddingBottom: "14px",
          borderBottom: "1px solid #333333",
          color: "#333333",
          fontSize: "30px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          paddingTop: "16px",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box sx={{ whiteSpace: "nowrap" }}>
          {languages[language].SaveChangesPart1}
        </Box>
        <Box>{languages[language].SaveChangesPart2}</Box>
        <Box>{languages[language].SaveChangesPart3}</Box>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          paddingBottom: "14px",
          borderBottom: "1px solid #333333",
          color: "#333333",
          fontSize: "30px",
        }}
      ></Box>
      <FastButton
        style={{
          fontWeight: 500,
          lineHeight: "2қpx",
          marginLeft: "auto",
          fontSize: "15px",
          color: "#FFFFFF",
          marginTop: "20px",
        }}
        click={() => submit()}
        variant="contained"
      >
        {languages[language].Btn_Save}
      </FastButton>
    </Box>
  );
};
