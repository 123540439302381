import React, { Fragment, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useModal } from "../../hooks/useModal";
import { useModalContent } from "../../hooks/useModalContent";
import SurveyInfo from "./SurveyInfo";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import useLang from "../../hooks/useLang";
import { surveyService } from "../../services/Surveys";
import useLoading from "../../hooks/useLoading";
import { ErrorModal } from "../Error/Modal";
import { ErrorContext } from "../Error/ErrorContext";
import { GetSurveyAnalysisResponse } from "../../services/responses/GetSurveyAnalysisResponse";
import { AxiosError } from "axios";
import { ActionIcon, Divider } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { ChangeSurveyModal } from "../Surveys/ChangeSurveyModal";

interface Props {
  title: string;
  subtitle: string;
  estimated_responses: number;
  respondents_count: number;
  backgroundColor: string;
  finishes_at: string;
  text: string;
  id: string;
  status: string;
  reload: () => void;
  setError: (title: string, message: string) => void;
}

const StyledCard = styled(Card)(({ theme }: any) => ({
  width: "304px",
  height: "180px",
  backgroundColor: "white",
  borderRadius: "22.6866px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px",
  margin: "30px",
}));

const titleStyle = {
  fontWeight: 600,
  fontSize: 20,
  marginLeft: "auto",
  color: "#27104E",
};

const subtitleStyle = {
  fontWeight: "300px",
  fontSize: "16px",
  marginLeft: "auto",
  color: "#000000",
};

const progressTextStyle = {
  fontSize: "14px",
  marginTop: "8px",
};

const dividerStyle = {
  borderBottom: "2.66866px solid rgba(217, 217, 217, 0.5)",
  marginTop: "10px",
  marginBottom: "100px",
  marginLeft: "6.58%",
  marginRight: "4.61%",
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#73A9C2" : "#73A9C2",
  },
}));

const SurveyComponent: React.FC<Props> = ({
  title,
  subtitle,
  estimated_responses,
  respondents_count,
  status,
  backgroundColor,
  finishes_at,
  text,
  id,
  setError,
  reload,
}) => {
  const { setModal } = useModal();
  const { setModalContent } = useModalContent();
  const { language } = useLang();
  const { setLoading } = useLoading();
  const [open, setOpen] = useState<boolean>(false);

  const percentage = Math.floor(
    (respondents_count /
      (estimated_responses === 0 ? 1 : estimated_responses)) *
      100
  );
  const dateObj = new Date(finishes_at);
  const month = dateObj.getUTCMonth() + 1;
  const formattedDate = `${dateObj.getUTCDate()}.${
    month < 10 ? 0 : ""
  }${month}.${dateObj.getUTCFullYear()}`;
  return (
    <Fragment>
      <ChangeSurveyModal id={id} isOpen={open} close={() => setOpen(false)} />
      <StyledCard
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          setLoading(true);
          setModalContent({
            title: "SurveyInfo",
            content: <SurveyInfo status={status} id={id} reload={reload} />,
          });
          setModal(true);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#D9D9D9",
            }}
          >
            {formattedDate}
          </Typography>
          <ActionIcon
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <IconEdit color="orange" />
          </ActionIcon>
        </Box>
        <CardContent>
          <Typography sx={titleStyle}>{title}</Typography>
          <Typography sx={subtitleStyle}>{subtitle}</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <BorderLinearProgress
              variant="determinate"
              value={percentage > 100 ? 100 : percentage}
              sx={{ marginTop: "10px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <Box>{percentage > 100 ? 100 : percentage}%</Box>

              <Box>
                {respondents_count}/{estimated_responses}
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Box sx={dividerStyle}></Box>
      </StyledCard>
    </Fragment>
  );
};

export default SurveyComponent;
