import { Box } from "@mui/material";
import { FC, useState } from "react";
import useLang from "../../hooks/useLang";
import useMobile from "../../hooks/useMobile";
import { useModal } from "../../hooks/useModal";
import useTablet from "../../hooks/useTablet";
import { languages } from "../../Languages/languages";
import { faqService } from "../../services/faqService";
import { AdminFAQ } from "../../services/responses/AdminFAQs";
import { FAQ } from "../../services/responses/FAQ";
import TextInput from "../Shared/TextInput";
import FastButton from "../UI/FastButton";

export interface EditQuestionProps {
  data: AdminFAQ;
  func: () => void;
}

export const EditQuestion: FC<EditQuestionProps> = ({ data, func }) => {
  const { language } = useLang();
  const { setModal } = useModal();
  const { isMobile } = useMobile();
  const { isTablet } = useTablet();
  const [faq, setFAQ] = useState<FAQ>({
    question: data.question,
    answer: data.answer,
  });

  const submitQuestion = () => {
    faqService.updateFAQ(data.id, faq, language).then((data) => {
      setModal(false);
      func();
    });
  };
  return (
    <Box
      sx={{
        paddingTop: "24px",
        width: isTablet ? "700px" : isMobile ? "100%" : "800px",
        height: isMobile ? window.screen.height - 200 : "auto",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          overflowY: "auto",
          borderTop: "1px solid #E0E0E0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            marginTop: "24px",
            paddingBottom: "8px",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {languages[language].Question}
        </Box>
        <TextInput
          id="question"
          placeholder={languages[language].AnswerInput}
          data={faq}
          value={faq.question}
          setData={setFAQ}
          InputProps={{
            sx: {
              borderRadius: 2,
              marginBottom: "16px",
              backgroundColor: "#FBF7F4",
            },
          }}
        />
        <Box
          sx={{
            padding: "8px 0",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {languages.kaz.Answer}
        </Box>
        <textarea
          value={faq.answer}
          onChange={(value: any) => {
            setFAQ({ ...faq, answer: value.target.value });
          }}
          style={{
            resize: "none",
            width: "100%",
            height: "181px",
            background: "#FBF7F4",
            fontSize: "16px",
            padding: "20px",
            border: "1px solid #767676",
            borderRadius: "12px",
            outline: "none",
            boxSizing: "border-box",
            borderColor: "#0000003b",
          }}
          placeholder={"Жауапты еңгізіңіз"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          paddingTop: "24px",
          borderTop: "1px solid #E0E0E0",
        }}
      >
        <FastButton
          variant={"contained"}
          style={{
            padding: "8px 30px",
            fontSize: "14px",
            marginLeft: "24px",
          }}
          click={() => submitQuestion()}
        >
          {languages[language].Btn_Save}
        </FastButton>
        <FastButton
          style={{
            padding: "8px 30px",
            fontSize: "14px",
          }}
          click={() => setModal(false)}
        >
          {languages[language].Cancel}
        </FastButton>
      </Box>
    </Box>
  );
};
