import { FC, useEffect } from "react";
import ReactGA from "react-ga";
import { Route, Routes, useLocation } from "react-router-dom";
import FAQPage from "./pages/FAQPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import LandingPage from "./pages/LandingPage";
import { LoginPage } from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import MemberManagementPage from "./pages/MemberManagementPage";
import NotFound from "./pages/NotFound";
import SettingsPage from "./pages/SettingsPage";
import { SignupPage } from "./pages/SignupPage";
import { SuspendedPage } from "./pages/SuspendedPage";
import { TransactionsPage } from "./pages/TransactionsPage";
import WalletPage from "./pages/WalletPage";
import { AddTransactionsPage } from "./pages/AddTransactionsPage";
ReactGA.initialize("G-YQZ25NB1BY");

export const CustomRouter: FC = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname + location.search);
  }, [location]);
  return (
    <Routes>
      <Route path="/signin" element={<LoginPage />}></Route>
      <Route path="/signup" element={<SignupPage />}></Route>
      <Route path="/forgetpass" element={<ForgetPasswordPage />}></Route>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/dashboard" element={<MainPage />}></Route>
      <Route path="/faq" element={<FAQPage />}></Route>
      <Route path="/transactions" element={<TransactionsPage />}></Route>
      <Route path="/add-transactions" element={<AddTransactionsPage />}></Route>
      <Route path="/suspended" element={<SuspendedPage />}></Route>
      <Route path="/wallet" element={<WalletPage />}></Route>
      <Route path="/settings" element={<SettingsPage />}></Route>
      <Route path="/member" element={<MemberManagementPage />}></Route>
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
};
