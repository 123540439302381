import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import FastIcon from "../UI/FastIcon";
import { useEffect, useState } from "react";
import FastButton from "../UI/FastButton";
import { useLocation, useNavigate } from "react-router-dom";
import InviteFriends from "./InviteFriends";
import { useModalContent } from "../../hooks/useModalContent";
import { useModal } from "../../hooks/useModal";
import useUser from "../../hooks/useUser";
import useMobile from "../../hooks/useMobile";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import {
  IconAlignBoxBottomCenterFilled,
  IconMessage,
  IconCreditCardFilled,
  IconCreditCardPay,
} from "@tabler/icons-react";
import { Badge } from "@mantine/core";
import { useQuery } from "react-query";
import { balanceService } from "../../services/Balance";
import { CountTransactionsResponse } from "../../services/responses/CountTransactions";

const SuccessDrawer = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#FBF7F4",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "left",
  flexDirection: "column",
  borderRight: " 1.00069px solid #DFDBD8",
  height: "100vh",
  padding: "0px 30px",
}));

const ActiveSurveysIcon = styled(IconAlignBoxBottomCenterFilled)`
  path {
    fill: rgb(167, 167, 167);
  }
`;

const FAQIcon = styled(IconMessage)`
  path {
    fill: rgb(167, 167, 167);
    stroke: rgb(167, 167, 167);
  }
`;

function SideBar({ pageClick }: any) {
  const { language } = useLang();
  const data: any = {
    USER: [
      // { name: "Главная страница", icon: <FastIcon name={"dash"} />, id: "dash" },
      {
        name: languages[language].Side_one,
        icon: <FastIcon name={"surv"} />,
        id: "survey",
      },
      // { name: "Help Centre", icon: <FastIcon name={"surv"} />, id: "help" },
      {
        name: languages[language].Wallet,
        icon: <FastIcon name={"prof"} />,
        id: "wallet",
        page: "/wallet",
      },
      {
        name: languages[language].Parameters,
        icon: <FastIcon name={"sett"} />,
        id: "settings",
        page: "/settings",
      },
    ],
    MANAGER: [
      {
        name: languages[language].AdminPanel,
        icon: <FastIcon name={"dash"} />,
        id: "dash",
      },
      {
        name: languages[language].CreateSurvey,
        icon: <FastIcon name={"surv"} />,
        id: "analysis",
      },
      {
        name: languages[language].ActiveSurveys,
        icon: <ActiveSurveysIcon width="20px" height="20px" />,
        id: "active_surveys",
      },
      {
        name: languages[language].FinishedSurveys,
        icon: <FastIcon name={"prof"} />,
        id: "finished",
      },
      {
        name: "FAQ",
        icon: <FAQIcon width="20px" height="20px" />,
        id: "faq",
        page: "/faq",
      },
      {
        name: languages[language].Parameters,
        icon: <FastIcon name={"sett"} />,
        id: "settings",
        page: "/settings",
      },
    ],
    ADMIN: [
      {
        name: languages[language].AdminPanel,
        icon: <FastIcon name={"dash"} />,
        id: "dash",
      },
      {
        name: languages[language].CreateSurvey,
        icon: <FastIcon name={"surv"} />,
        id: "analysis",
      },
      {
        name: languages[language].ActiveSurveys,
        icon: <ActiveSurveysIcon width="20px" height="20px" />,
        id: "active_surveys",
      },
      {
        name: languages[language].FinishedSurveys,
        icon: <FastIcon name={"prof"} />,
        id: "finished",
      },
      {
        name: languages[language].transactions,
        icon: (
          <IconCreditCardFilled width="20px" height="20px" opacity={0.54} />
        ),
        id: "transactions",
        page: "/transactions",
      },
      {
        name: languages[language].add_transactions,
        icon: <IconCreditCardPay width="20px" height="20px" opacity={0.54} />,
        id: "add-transactions",
        page: "/add-transactions",
      },
      {
        name: languages[language].Administrator,
        icon: <FastIcon name={"admin"} />,
        id: "member",
        page: "/member",
      },
      {
        name: "FAQ",
        icon: <FAQIcon width="20px" height="20px" />,
        id: "faq",
        page: "/faq",
      },
      {
        name: languages[language].Parameters,
        icon: <FastIcon name={"sett"} />,
        id: "settings",
        page: "/settings",
      },
    ],
  };
  const { userData } = useUser();
  const { isMobile } = useMobile();

  const [selectedItem, setSelectedItem] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { setModalContent } = useModalContent();
  const { setModal } = useModal();
  const [role, setRole] = useState("USER");

  const [transactionsCount, setTransactionsCount] = useState<number>(0);

  const { refetch } = useQuery(
    "GetTransactionsCount",
    async () => {
      if (role === "USER") return;
      return await balanceService.CountTransactions("pending");
    },
    {
      onSuccess: (response: CountTransactionsResponse) => {
        setTransactionsCount(response?.count);
      },
      onError: () => {},
    }
  );

  const handleItemClick = (itemName: any) => {
    if (itemName.page) {
      setSelectedItem(itemName.id);
      navigate(itemName.page);
    } else {
      setSelectedItem(itemName.id);
      if (location.pathname !== "/dashboard") {
        navigate("/dashboard");
      }
      pageClick(itemName.id);
    }
  };

  const LogOut = () => {
    window.localStorage.removeItem("token");
    navigate("/signin");
  };

  useEffect(() => {
    if (userData?.account?.role) {
      setRole(userData.account.role);
      refetch();
    }
  }, [userData]);
  useEffect(() => {
    if (location.pathname !== "/dashboard") {
      setSelectedItem(location.pathname.slice(1));
    }
  }, []);

  const getList = () => (
    <div style={{ width: 250 }}>
      {data[role]?.map((item: any, index: any) => (
        <ListItem
          button
          key={index}
          onClick={() => handleItemClick(item)}
          sx={
            {
              fontWeight: selectedItem === item.id && "bold",
              color: selectedItem === item.id ? "#333333" : "#A7A7A7",
            } as SxProps<Theme>
          }
        >
          <ListItemIcon
            sx={
              {
                color: selectedItem === item.name && "#A7A7A7",
              } as SxProps<Theme>
            }
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.name} />
          {item.id === "transactions" && transactionsCount > 0 && (
            <Badge color="red" size="xs">
              {transactionsCount > 99 ? "99+" : transactionsCount}
            </Badge>
          )}
        </ListItem>
      ))}
    </div>
  );

  const InviteFriend = () => {
    return (
      <Box
        sx={{
          padding: "36px 39px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "18px",
          background:
            "linear-gradient(236.95deg, #FFFFFF 19.39%, #FBF7F4 83.13%)",
          borderRadius: "18px",
          marginTop: "64px",
        }}
      >
        <Box sx={{ paddingBottom: "11px" }}>{languages[language].Invite}</Box>
        <FastButton
          variant="contained"
          click={() => {
            setModal(true);
            setModalContent({
              title: languages[language].Invite,
              content: <InviteFriends />,
            });
          }}
          style={{ backgroundColor: "#333333" }}
        >
          {languages[language].Invite_f}
        </FastButton>
      </Box>
    );
  };

  const Exit = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingTop: "26px",
          borderTop: "1px solid #D2D2D2",
          alignContent: "flex-end",
          marginTop: "auto",
          paddingBottom: "40px",
          cursor: "pointer",
        }}
        onClick={() => LogOut()}
      >
        <FastIcon name="exitSideBar" />
        <Box sx={{ paddingLeft: "10px" }}>{languages[language].Logout}</Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        hxeight: "100%",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 100,
      }}
    >
      <SuccessDrawer
        sx={{
          overflowY: "scroll",
        }}
      >
        <FastIcon
          name={isMobile ? "logoMobile" : "logo"}
          height={"86px"}
          width={"178px"}
          style={{ paddingTop: "30px", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        />
        {getList()}
        {userData.user.email && userData.user.email.verified && (
          <InviteFriend />
        )}
        <Exit />
      </SuccessDrawer>
    </Box>
  );
}

export default SideBar;
