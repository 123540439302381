import React, { createContext, useState } from "react";

export const LoadingContext = createContext<any>(null);

export const LoadingProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(true);

  const setLoading = (loadingState: any) => {
    setIsLoading(loadingState);
  };

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
