import { Button, Flex, Group, Input, PinInput, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { FC, useState } from "react";
import useLang from "../../../hooks/useLang";
import { useMutation } from "react-query";
import { balanceService } from "../../../services/Balance";
import { CreateInvoiceResponse } from "../../../services/responses/CreateInvoiceResponse";
import { PaymentRequest } from "../../../services/requests/PaymentRequest";

const languages = {
  kaz: {
    label: "Пин код",
    submit: "Ұпайларды шығару",
    description: [
      "Сізге бұрын көрсетілген",
      "телефон нөміріңізге 4-санды код жіберілді.\n Соны еңгізіңіз",
    ],
  },
  rus: {
    label: "Пин код",
    submit: "Вывести баллы",
    description: [
      "На ваш номер телефона",
      "указанный до этого был отправлен 4 значный код.\n Пожалуйста укажите его",
    ],
  },
};

interface Props {
  phone_number: string;
  refetch: () => void;
  amount?: number;
}

export const VerifyPhoneNumberModal: FC<Props> = ({
  phone_number,
  refetch,
  amount,
}) => {
  const { language } = useLang();
  const [error, setError] = useState<string>("");
  const [request, setRequest] = useState<PaymentRequest>({
    verification_code: "",
    amount: amount,
  });

  const mutation = useMutation(
    async (data: PaymentRequest) => {
      return await balanceService.createInvoice(data, language);
    },
    {
      onSuccess: (data: CreateInvoiceResponse) => {
        window.open(data.link, "_blank");
        setRequest({ verification_code: "", amount: amount });
      },
      onError: (error: any) => {
        setError(error.response.data.error[0].message);
      },
    }
  );

  const submit = async () => {
    mutation.mutate(request);
  };

  return (
    <Flex direction="column" gap="10px">
      <Text>
        {languages[language].description[0]}{" "}
        <Text span weight={700}>
          +{phone_number.slice(0, 1)} ({phone_number.slice(1, 4)}){" "}
          {phone_number.slice(4, 7)} {phone_number.slice(7)}{" "}
        </Text>{" "}
        {languages[language].description[1]}
      </Text>
      <Flex direction="column" gap="10px" mx="xl">
        <Input.Label>{languages[language].label}</Input.Label>
        <Group position="center">
          <PinInput
            type="number"
            length={4}
            onChange={(value) =>
              setRequest({ verification_code: value, amount: amount })
            }
          />
        </Group>
      </Flex>
      <Button
        fullWidth
        styles={(theme) => ({
          root: {
            background: "#73A9C2",
            ["&:hover "]: {
              background: theme.fn.darken("#73A9C2", 0.1),
            },
          },
        })}
        onClick={() => {
          submit();
          refetch();
          modals.closeAll();
        }}
        mt="md"
      >
        {languages[language].submit}
      </Button>
    </Flex>
  );
};
