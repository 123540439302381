import { SimpleGrid } from "@mantine/core";
import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import AddSurveys from "../components/AddSurveys/AddSurveys";
import { ErrorModal } from "../components/Error/Modal";
import GeneralInfoAdmin from "../components/GeneralInfo/GeneralInfoAdmin";
import SurveyComponent from "../components/SurveyComponent/SurveyComponent";
import useLang from "../hooks/useLang";
import useLoading from "../hooks/useLoading";
import useMobile from "../hooks/useMobile";
import { languages } from "../Languages/languages";
import { surveyService } from "../services/Surveys";

export const AdminPage: FC = () => {
  const { language } = useLang();
  const { isMobile } = useMobile();
  const [active, setActive] = useState([]);
  const [finished, setFinished] = useState([]);
  const [error, setError] = useState<{
    state: boolean;
    title: string;
    message: string;
  }>({ state: false, title: "", message: "" });

  const getData = () => {
    surveyService.getSurveys(true).then((data: any) => {
      setActive(data);
    });
    surveyService.getSurveys(false).then((data: any) => {
      setFinished(data);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <ErrorModal
        isOpen={error.state}
        close={() => {
          setError({ state: false, title: "", message: "" });
        }}
        title={error.title}
        message={error.message}
      />
      {isMobile ? null : <AddSurveys />}
      <span id="dash" />
      <Box
        style={{
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "30px",
          padding: "36px 0 0 30px",
        }}
      >
        {languages[language].Analysis}
      </Box>
      <GeneralInfoAdmin />
      <span id="analysis" />
      <Box
        style={{
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "30px",
          padding: "36px 0 0 30px",
        }}
      >
        {languages[language].ActiveSurveys}
      </Box>
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: "82rem", cols: 2, spacing: "md" },
          { maxWidth: "62rem", cols: 1, spacing: "sm" },
          { maxWidth: "48rem", cols: 1, spacing: "sm" },
        ]}
      >
        {active.map((item: any) => {
          return (
            <SurveyComponent
              key={item.id}
              setError={(title, message) => {
                setError({ state: true, title, message });
              }}
              reload={() => getData()}
              text={"asdf"}
              respondents_count={item?.respondents_count}
              finishes_at={item?.finishes_at}
              estimated_responses={item?.estimated_responses}
              title={item?.title?.[language]}
              status={item.status}
              id={item.id}
              subtitle={""}
              backgroundColor={"#9854CB"}
            />
          );
        })}
      </SimpleGrid>
      <span id="active_surveys" />
      <Box
        style={{
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "30px",
          padding: "36px 0 0 30px",
        }}
      >
        {languages[language].FinishedSurveys}
      </Box>
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: "82rem", cols: 2, spacing: "md" },
          { maxWidth: "62rem", cols: 1, spacing: "sm" },
          { maxWidth: "48rem", cols: 1, spacing: "sm" },
        ]}
      >
        {finished.map((item: any) => {
          return (
            <SurveyComponent
              key={item.id}
              setError={(title, message) => {
                setError({ state: true, title, message });
              }}
              reload={() => getData()}
              text={"asdf"}
              respondents_count={item?.respondents_count}
              estimated_responses={item?.estimated_responses}
              title={item?.title?.[language]}
              status={item.status}
              finishes_at={item?.finishes_at}
              id={item.id}
              subtitle={item.subtitle}
              backgroundColor={"#9854CB"}
            />
          );
        })}
      </SimpleGrid>
      <span id="finished" />
    </>
  );
};
