import axios from "axios";
import { Language } from "../context/LangProvider";

const axiosApiInstance = axios.create();

class DataService {
  async getAnalysis() {
    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      },
    };

    return axiosApiInstance
      .get("https://api.pikirlab.kz/analysis", headers)
      .then((response) => response.data);
  }

  async postHelp() {
    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      },
    };

    return axiosApiInstance
      .post(
        "https://api.pikirlab.kz/emails/feedback",
        {
          category: "ERROR",
          phone_number: "87088711238",
          text: "Cannot press submit button",
        },
        headers
      )
      .then((response) => response.data);
  }

  getProblem(lang: Language) {
    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        lang: lang,
      },
    };

    return axiosApiInstance
      .get("https://api.pikirlab.kz/emails/feedback/categories", headers)
      .then((response) => response.data);
  }
}
export const dashboardService = new DataService();
