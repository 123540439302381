import FastIcon from "../UI/FastIcon";
import { Box } from "@mui/material";
import LangSwitch from "./LangSwitch";
import { Link, useNavigate } from "react-router-dom";
import useMobile from "../../hooks/useMobile";
import useUser from "../../hooks/useUser";
import { balanceService } from "../../services/Balance";
import { languages } from "../../Languages/languages";
import useLang from "../../hooks/useLang";
import { useQuery } from "@tanstack/react-query";

function Uheader() {
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  const { userData } = useUser();
  const { language } = useLang();

  const { isLoading, isError, data } = useQuery({
    queryKey: ["balance"],
    queryFn: balanceService.get,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        right: 0,
        left: 0,
        zIndex: 89,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "space-between" : "flex-end",
          borderBottom: isMobile ? "unset" : "1px solid #DFDBD8",
          background: isMobile ? "#FBF7F4" : "#FFFFFF",
          padding: isMobile ? "12px 30px" : "24px 30px",
        }}
      >
        <FastIcon
          name={isMobile ? "logoMobile" : "logo"}
          style={{ display: isMobile ? "block" : "none" }}
          onClick={() => {
            navigate("/");
          }}
        />
        {userData.account.role === "ADMIN" ||
        isLoading ||
        isError ||
        !data ? null : (
          <Link to="/wallet" style={{ textDecoration: "none" }}>
            <Box
              sx={{
                fontWeight: 600,
                lineHeight: "19px",
                color: "#73A9C2",
                paddingRight: isMobile ? 0 : "30px",
              }}
            >
              {data.balance} {languages[language].balance}
            </Box>
          </Link>
        )}
        <LangSwitch />
      </Box>
    </Box>
  );
}

export default Uheader;
