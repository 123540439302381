import { Box } from "@mui/material";
import Header from "./Blocks/Header";
import Form from "./Blocks/Form";
import { useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import useMobile from "../../hooks/useMobile";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { useNavigate } from "react-router-dom";
import { dataService } from "../../services/getLogin";
import { LoginForm } from "./types";
import { LoginRequest } from "../../services/requests/LoginRequest";

export const Login = (props: any) => {
  const { language } = useLang();
  const { isMobile, setMobileFun } = useMobile();
  const [width] = useState(0);
  const [requestBody, setRequestBody] = useState<LoginRequest>({
    phone_number: "",
    password: "",
  });
  const form: LoginForm = {
    title: languages[language].Sign_in,
    fields: [
      {
        type: "tel",
        label: "Номер телефона",
        id: "phone_number",
        regex: /^77\d{9}$/,
      },
      { type: "password", label: languages[language].Password, id: "password" },
    ],
  };

  useEffect(() => {
    if (window.innerWidth <= 802) {
      setMobileFun(true);
    } else {
      setMobileFun(false);
    }
  }, [width]);

  return (
    <Layout>
      <Box
        sx={{
          maxWidth: "827px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "unset",
            gridTemplateColumns: "1fr 3fr",
            gridColumnGap: "1px",
            justifyContent: isMobile ? "none" : "center",
            alignItems: isMobile ? "none" : "center",
            margin: isMobile ? "none" : "0 120px",
          }}
        >
          <Form
            form={form}
            requestBody={requestBody}
            setRequestBody={setRequestBody}
          />
        </Box>
      </Box>
    </Layout>
  );
};
