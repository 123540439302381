import { Box, Modal } from "@mantine/core";
import { FC } from "react";
import useMobile from "../../hooks/useMobile";
import useTablet from "../../hooks/useTablet";
import { UserList } from "./UsersList";

const style = {
  position: "absolute",
  // top: "40%",
  // left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
  background: "#fff5f5",
  borderRadius: "24px",
  border: "2px solid red",
  minWidth: "358px",
  minHeight: "264px",
  padding: "24px",
};

export interface UsersModalProps {
  isOpen: boolean;
  close: () => void;
}

export const UsersModal: FC<UsersModalProps> = ({ isOpen, close }) => {
  const { isMobile } = useMobile();
  const { isTablet } = useTablet();

  return (
    <Modal
      opened={isOpen}
      onClose={() => close()}
      title="Users"
      radius={"20px"}
      lockScroll
      size="auto"
      styles={{
        root: {
          display: "flex",
          justifyContent: "center",
        },
        content: {
          width: isTablet ? "700px" : isMobile ? "90%" : "1280px",
          height: isTablet ? "auto" : isMobile ? "95%" : "700px",
        },
      }}
      xOffset={0}
    >
      <Box styles={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UserList />
        </Box>
      </Box>
    </Modal>
  );
};
