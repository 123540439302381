import { FC } from "react";
import { Transaction as TransactionModel } from "../../models/transaction";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Text,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMutation } from "react-query";
import { balanceService } from "../../services/Balance";
import { format } from "date-fns";

const useStyles = createStyles((theme) => ({
  transaction: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    background: "white",
    borderRadius: "16px",
    "&:hover": {
      boxShadow: "0px 0px 20px 0.5px #d3d3d380",
    },
    [theme.fn.largerThan("md")]: {
      cursor: "pointer",
    },
  },

  active: {
    background: "rgb(115, 169, 195)",
    color: "white",
  },
}));

interface Props extends TransactionModel {
  refetch: () => void;
  onClick: () => void;
  active: boolean;
}

export const Transaction: FC<Props> = ({
  id,
  account_id,
  created_at,
  kassa_id,
  amount,
  refetch,
  onClick,
  active = false,
}) => {
  const { cx, classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const theme = useMantineTheme();

  const mutation = useMutation(
    async (data: { type: "accept" | "reject"; transaction_id: string }) => {
      switch (data.type) {
        case "accept":
          return await balanceService.AcceptTransaction(data.transaction_id);
        case "reject":
          return await balanceService.RejectTransaction(data.transaction_id);
        default:
          break;
      }
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error: any) => {
        alert(JSON.stringify(error.response.data.error));
      },
    }
  );

  const submit = (data: {
    type: "accept" | "reject";
    transaction_id: string;
  }) => mutation.mutate(data);

  return (
    <Box
      className={cx(classes.transaction, { [classes.active]: active })}
      onClick={() => {
        onClick();
      }}
    >
      <Flex direction={"column"} justify="space-between" align="end">
        <Flex direction="column" gap="0px" w="100%">
          <Flex justify="space-between">
            <Text color={active ? "white" : "dimmed"}>ID аккаунта:</Text>
            <Text color={active ? "white" : "dimmed"}>{account_id}</Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="xs" color={active ? "white" : "dimmed"}>
              Создан:
            </Text>
            <Text size="xs" color={active ? "white" : "dimmed"}>
              {format(new Date(created_at), "hh:mm | dd-MM-yyyy")}
            </Text>
          </Flex>
          <Flex justify="space-between">
            <Text size="xs" color={active ? "white" : "dimmed"}>
              Касса ID:
            </Text>
            <Text size="xs" color={active ? "white" : "dimmed"}>
              {kassa_id}
            </Text>
          </Flex>
        </Flex>
        <Text size="lg" fw={500} color={active ? theme.colors.red[2] : "red"}>
          {amount}
        </Text>
      </Flex>
    </Box>
  );
};
