import { useEffect, useState } from "react";
import { Login } from "../components/Login/Login";
import useLoading from "../hooks/useLoading";
import useMobile from "../hooks/useMobile";
import useTablet from "../hooks/useTablet";
export const LoginPage = () => {
  const { setTabletFun } = useTablet();
  const { setMobileFun } = useMobile();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 600 && window.innerWidth <= 800) {
      setTabletFun(true);
      setMobileFun(false);
    } else if (window.innerWidth <= 600) {
      setTabletFun(false);
      setMobileFun(true);
    } else {
      setMobileFun(false);
      setTabletFun(false);
    }
  }, [width]);

  useEffect(() => {}, [window.innerWidth]);

  const { setLoading } = useLoading();
  setLoading(false);
  return <Login />;
};
