import { Box } from "@mui/system";
import { Button, Checkbox, Select, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Typography } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FastSearch from "../UI/FastSearch";
import FastIcon from "../UI/FastIcon";
import { useModal } from "../../hooks/useModal";
import { useModalContent } from "../../hooks/useModalContent";
import AddMember from "./AddMember";
import { useEffect, useState } from "react";
import { promote } from "../../services/Promote";
import useUser from "../../hooks/useUser";
import DeleteMember from "./DeleteMember";
import useMobile from "../../hooks/useMobile";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { Text } from "@mantine/core";

//
// const rows = [
//     {name:'Серік',surname:'Ибрагим',role:'Manager',email:'Ibra0@gmai.com'},
//     {name:'Серік',surname:'Ибрагим',role:'Manager',email:'Ibra0@gmai.com'}
// ];

const MemberManagement = () => {
  const { language } = useLang();
  const { isMobile } = useMobile();
  const { setModal } = useModal();
  const { setModalContent } = useModalContent();
  const { userData } = useUser();

  const [rows, setRows] = useState([]);

  const updateList = () => {
    promote.getManagers().then((data) => {
      setRows(data);
    });
  };

  useEffect(() => {
    updateList();
  }, []);

  const deleteAcc = (id: any) => {
    promote.deleteManager(id).then((data) => {
      updateList();
    });
  };
  return (
    <Box
      sx={
        isMobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }
          : {}
      }
    >
      <Box
        sx={
          isMobile
            ? {
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }
            : {
                background: "#FFFFFF",
                marginTop: "104px",
                marginBottom: "21px",
              }
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "flex-start" : "space-between",
            padding: isMobile ? "0 24px" : "32px",
            marginBottom: isMobile ? 0 : "60px",
          }}
        >
          <Box>
            <Box
              sx={{
                paddingRight: isMobile ? "0" : "12px",
                paddingBottom: "12px",
                fontWeight: 700,
                fontSize: isMobile ? "16px" : "16px",
                lineHeight: "20px",
              }}
            >
              {userData?.user?.first_name + " " + userData?.user?.last_name}
            </Box>
            <Box
              sx={{
                paddingBottom: "12px",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
              }}
            >
              {userData?.account?.role}
            </Box>
          </Box>
          <Button
            variant={"contained"}
            sx={{
              height: isMobile ? "45px" : "40px",
              width: isMobile ? "220px" : "400px",
              mt: isMobile ? "9px" : "0",
              backgroundColor: "#73A9C2",
              "&:hover": {
                backgroundColor: "#73A9C2",
              },
              // backgroundColor: isMobile ? "#73A9C2" : "#1458DD",
              // "&:hover": {
              //   backgroundColor: isMobile ? "#73A9C2" : "#1458DD",
              // },
            }}
            onClick={() => {
              setModal(true);
              setModalContent({
                title: languages[language].NewManager,
                content: <AddMember fun={updateList} />,
              });
            }}
          >
            <FastIcon
              name={"plusMember"}
              sx={{ marginRight: "8px", borderRadius: "6px" }}
            />
            {languages[language].NewManager}
          </Button>
        </Box>
        {isMobile && (
          <Box sx={{ padding: isMobile ? "0 24px" : "42px" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, fontSize: "20px" }}
            >
              {languages[language].Managers}
            </Typography>
          </Box>
        )}
      </Box>
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            padding: "0 24px",
            // width: "100%",
            // height: "150px",
          }}
        >
          {rows?.map((row: any) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // gap: "24px",
                justifyContent: "space-between",
                padding: "24px",
                borderRadius: "20px",
                border: "1px solid #73A9C2",
              }}
            >
              <Box
                key={row?.first_name}
                sx={{
                  // width: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ display: "flex", gap: "10px" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    {languages[language].Name}:
                  </Text>
                  <Text>{row?.first_name}</Text>
                </Typography>
                <Typography sx={{ display: "flex", gap: "10px" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    {languages[language].Surname}:
                  </Text>
                  <Text>{row?.last_name}</Text>
                </Typography>
                <Typography sx={{ display: "flex", gap: "10px" }}>
                  <Text style={{ fontWeight: "bold" }}>Роль:</Text>
                  <Text>{row?.role}</Text>
                </Typography>
              </Box>
              <FastIcon
                name={"trash"}
                onClick={() => {
                  setModal(true);
                  setModalContent({
                    title: languages[language].RemoveMeneger,
                    content: (
                      <DeleteMember fun={() => deleteAcc(row.account_id)} />
                    ),
                  });
                }}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ boxShadow: "unset" }}>
          <Table
            sx={{ minWidth: 650, border: "1px solid #E0E0E0" }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                {/*<TableCell sx={{borderLeft:'1px solid #E0E0E0', width:'30px'}}><Checkbox/></TableCell>*/}
                <TableCell sx={{ borderLeft: "1px solid #E0E0E0" }}>
                  Имя
                </TableCell>
                <TableCell sx={{ borderLeft: "1px solid #E0E0E0" }}>
                  Фамилия
                </TableCell>
                <TableCell sx={{ borderLeft: "1px solid #E0E0E0" }}>
                  Роль
                </TableCell>
                {/*<TableCell sx={{borderLeft:'1px solid #E0E0E0'}} >E-mail</TableCell>*/}
                <TableCell
                  sx={{ borderLeft: "1px solid #E0E0E0", width: "30px" }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row: any) => (
                <TableRow key={row?.first_name}>
                  {/*<TableCell><Checkbox/></TableCell>*/}
                  <TableCell sx={{ borderLeft: "1px solid #E0E0E0" }}>
                    {row?.first_name}
                  </TableCell>
                  <TableCell sx={{ borderLeft: "1px solid #E0E0E0" }}>
                    {row?.last_name}
                  </TableCell>
                  <TableCell sx={{ borderLeft: "1px solid #E0E0E0" }}>
                    {row?.role}
                  </TableCell>
                  {/*<TableCell sx={{borderLeft:'1px solid #E0E0E0'}} >{row?.email}</TableCell>*/}
                  <TableCell sx={{ borderLeft: "1px solid #E0E0E0" }}>
                    <FastIcon
                      name={"trash"}
                      onClick={() => {
                        setModal(true);
                        setModalContent({
                          title: languages[language].RemoveMeneger,
                          content: (
                            <DeleteMember
                              fun={() => deleteAcc(row.account_id)}
                            />
                          ),
                        });
                      }}
                      //                                                             onClick={()=>{
                      // 	deleteAcc(row.account_id)
                      // }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default MemberManagement;
