import { useContext } from "react";
import { UserContext } from "../context/UserProvider";

export default function useUser() {
  const { userData, setUserData } = useContext(UserContext);

  return {
    userData,
    setUserData,
  };
}
