import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import Header from "./Blocks/Header";
import Instruction from "./Blocks/Instruction";
import Form from "./Blocks/Form";
import useMobile from "../../hooks/useMobile";
import useLoading from "../../hooks/useLoading";

const ResetPassword = () => {
  const { isMobile, setMobileFun } = useMobile();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (window.innerWidth <= 802) {
      setMobileFun(true);
    } else {
      setMobileFun(false);
    }
  }, [width]);

  useEffect(() => {}, [window.innerWidth]);

  return (
    <Layout>
      <Box
        sx={{
          maxWidth: "827px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "unset",
            gridTemplateColumns: "1fr 3fr",
            gridColumnGap: "1px",
            justifyContent: isMobile ? "none" : "center",
            alignItems: isMobile ? "none" : "center",
            margin: isMobile ? "none" : "0 120px",
          }}
        >
          <Form />
        </Box>
      </Box>
    </Layout>
  );
};

export default ResetPassword;
