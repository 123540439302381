import { Box } from "@mui/system";
import FastIcon from "../UI/FastIcon";
import { useActionData, useLocation, useNavigate } from "react-router-dom";
import { FC, ReactElement, useEffect, useState } from "react";
import zIndex from "@mui/material/styles/zIndex";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import useUser from "../../hooks/useUser";
import { IconCreditCardFilled, IconMessage } from "@tabler/icons-react";
import { styled } from "@mui/material";

export type Roles = "ADMIN" | "MANAGER" | "USER";

interface Page {
  label: string;
  navigate: string;
  icon: FC;
}

type Data = {
  [key in Roles]: Page[];
};

const FAQIcon = styled(IconMessage)`
  path {
    fill: rgb(167, 167, 167);
    stroke: rgb(167, 167, 167);
  }
`;

const NavMobile = () => {
  const navigate = useNavigate();
  const { language } = useLang();
  const [active, setAcive] = useState("");
  const location = useLocation();
  const { userData } = useUser();
  const [role, setRole] = useState<Roles>("USER");

  useEffect(() => {
    if (userData?.account?.role) {
      setRole(userData.account.role);
    }
  }, [userData]);

  const roles: Data = {
    ADMIN: [
      {
        label: languages[language].Surveys,
        navigate: "/dashboard",
        icon: () => (
          <FastIcon
            name={"surveyMob"}
            style={{
              fill: "red !important",
            }}
          />
        ),
      },
      {
        label: languages[language].Managers,
        navigate: "/member",
        icon: () => <FastIcon name={"admin"} />,
      },
      {
        label: languages[language].transactions,
        navigate: "/transactions",
        icon: () => (
          <IconCreditCardFilled width="20px" height="20px" opacity={0.33} />
        ),
      },
      {
        label: languages[language].FAQ,
        navigate: "/faq",
        icon: () => <FAQIcon width="20px" height="20px" />,
      },
      {
        label: languages[language].Parameters,
        navigate: "/settings",
        icon: () => <FastIcon name={"settingMob"} />,
      },
    ],
    MANAGER: [
      {
        label: languages[language].Surveys,
        navigate: "/dashboard",
        icon: () => (
          <FastIcon
            name={"surveyMob"}
            style={{
              fill: "red !important",
            }}
          />
        ),
      },
      {
        label: languages[language].FAQ,
        navigate: "/faq",
        icon: () => (
          <FastIcon
            name={"surveyMob"}
            style={{
              fill: "red !important",
            }}
          />
        ),
      },
      {
        label: languages[language].Parameters,
        navigate: "/settings",
        icon: () => <FastIcon name={"settingMob"} />,
      },
    ],
    USER: [
      {
        label: languages[language].Surveys,
        navigate: "/dashboard",
        icon: () => (
          <FastIcon
            name={"surveyMob"}
            style={{
              fill: "red !important",
            }}
          />
        ),
      },
      {
        label: languages[language].Parameters,
        navigate: "/settings",
        icon: () => <FastIcon name={"settingMob"} />,
      },
    ],
  };

  useEffect(() => {
    setAcive(location.pathname);
  }, []);

  // const height = (window.innerHeight * (isMobile ? 70 : 80)) / 100;
  const width = (window.innerWidth * 50) / 100;

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        // padding: '15px 16px 24px',
        gap: "24px",
        right: 0,
        bottom: 0,
        background: "#FFFFFF",
        border: "1px solid #F6F6F6",
        boxShadow: "0px 4px 41px rgba(0, 0, 0, 0.25)",
        zIndex: 10,
        padding: "15px 15px 24px 15px",
      }}
    >
      {roles[role].map((page) => {
        return (
          <Box
            key={page.label}
            onClick={() => navigate(page.navigate)}
            sx={{
              width: "50px",
              height: "60px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: (width / 4).toString() + "px",
                height: (width / 8).toString() + "px",
                display: "flex",
                justifyContent: "center",
                alignItems: "top",
              }}
            >
              <page.icon />
            </Box>
            <Box
              sx={{
                width: (width / 4).toString() + "px",
                height: (width / 8).toString() + "px",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {page.label}
            </Box>
          </Box>
        );
      })}

      {/* <Box
        onClick={() => navigate("/dashboard")}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FastIcon
          name={"surveyMob"}
          style={{
            fill: "red !important",
          }}
        />
        <Box
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            paddingTop: "10px",
          }}
        >
          Опросы
        </Box>
      </Box> */}
      {/* <Box onClick={()=>navigate('/wallet')} sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        }}>  <FastIcon name={'walletMob'}  style={{
            color:'red !important'
        }}/>
            <Box sx={{fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',paddingTop:'10px'}}>Кошелек</Box></Box> */}
      {/* <Box
        onClick={() => navigate("/settings")}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {" "}
        <FastIcon name={"settingMob"} />
        <Box
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            paddingTop: "10px",
          }}
        >
          Профиль
        </Box>
      </Box> */}
    </Box>
  );
};

export default NavMobile;
