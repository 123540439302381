import axios from "axios";
import { CreateFAQCategoryRequest } from "./requests/CreateFAQCategoryRequest";
import { CreateFAQRequest } from "./requests/CreateFAQRequest";
import { AdminFAQCategory } from "./responses/AdminFAQCategory";
import { FAQ } from "./responses/FAQ";
import { FAQCategory } from "./responses/FAQCategory";

const axiosApiInstance = axios.create();

let headers = {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    Accept: "application/json",
    Lang: "",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  },
};

class FAQService {
  async listFAQs() {
    return axiosApiInstance
      .get<FAQCategory[]>("https://api.pikirlab.kz/faqs", headers)
      .then((response) => response.data);
  }

  async listAdminFAQs() {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .get<AdminFAQCategory[]>("https://api.pikirlab.kz/faqs-admin", headers)
      .then((response) => response.data);
  }

  async createFAQ(id: string, data: CreateFAQRequest) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .post<FAQCategory[]>(`https://api.pikirlab.kz/faqs/${id}`, data, headers)
      .then((response) => response.data);
  }

  async updateFAQ(id: string, data: FAQ, language: string) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    headers.headers.Lang = language;
    return axiosApiInstance
      .patch<AdminFAQCategory[]>(
        `https://api.pikirlab.kz/faqs/${id}`,
        data,
        headers
      )
      .then((response) => response.data);
  }

  async deleteQuestion(id: string) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .delete<AdminFAQCategory[]>(`https://api.pikirlab.kz/faqs/${id}`, headers)
      .then((response) => response.data);
  }

  async deleteCategory(id: string) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .delete<AdminFAQCategory[]>(
        `https://api.pikirlab.kz/faq-categories/${id}`,
        headers
      )
      .then((response) => response.data);
  }

  async createCategory(data: CreateFAQCategoryRequest) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .post<AdminFAQCategory[]>(
        "https://api.pikirlab.kz/faq-categories\n",
        data,
        headers
      )
      .then((response) => response.data);
  }
}

export const faqService = new FAQService();
