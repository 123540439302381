import { Box } from "@mui/system";
import useMobile from "../../hooks/useMobile";
import React, { useEffect, useState } from "react";
import { dashboardService } from "../../services/DashboardService";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { AnalysisBox } from "./AnalysisBox";
import { useModal } from "../../hooks/useModal";
import { useModalContent } from "../../hooks/useModalContent";
import { UserList } from "./aUsersList";
import { userService } from "../../services/UserService";
import { result } from "lodash";
import { ListUsersResponse } from "../../services/responses/ListUsersResponse";
import { Modal } from "@mantine/core";
import { UsersModal } from "./UsersModal";
import useTablet from "../../hooks/useTablet";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(255, 69, 69, 0.6)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? "#1a90ff" : "rgba(255, 69, 69, 0.6)",
  },
}));

const GeneralInfoAdmin = () => {
  const { language } = useLang();
  const ok: any = {
    total_money_spent: languages[language].Total_m,
    total_users_count: languages[language].Total_r,
    total_sms_count: languages[language].Total_s,
  };
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { setModal } = useModal();
  const { setModalContent } = useModalContent();
  const [open, setOpen] = useState<boolean>(false);
  const { isMobile } = useMobile();
  const { isTablet } = useTablet();

  useEffect(() => {
    dashboardService.getAnalysis().then((result) => {
      setData(result);
    });
  }, []);

  return (
    <React.Fragment>
      <UsersModal isOpen={open} close={() => setOpen(false)}></UsersModal>
      <Box
        sx={{
          display: "flex",
          color: "#FFFFFF",
          padding: isMobile ? "20px" : "30px",
          flexDirection: isTablet ? "column" : isMobile ? "column" : "row",
          gap: isTablet ? "30px" : "0px",
        }}
      >
        <Box
          sx={{
            padding: "12px",
            background: "#FFFFFF",
            color: "#333333",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
            width: isTablet ? "auto" : isMobile ? "auto" : "480px",
            marginRight: "24px",
            marginBottom: isMobile ? "24px" : "unset",
          }}
        >
          <Box
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
            }}
          >
            {languages[language].Sidebar_Seven}
          </Box>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: "24px",
              lineHeight: "20px",
              paddingBottom: "8px",
              padding: "13px 0",
            }}
          >
            {data?.survey?.count ?? 0}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: "13px",
            }}
          >
            <Box>
              <Box
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19px",
                  paddingBottom: "13px",
                }}
              >
                {languages[language].ActiveSurveys}
              </Box>
              <Box
                sx={{
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "24px",
                }}
              >
                {(data?.survey?.count ?? 0) - (data?.survey?.finished ?? 0)}{" "}
                <span
                  style={{
                    fontSize: "16px",
                    color: "#73A9C2",
                  }}
                >
                  {}
                </span>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19px",
                  paddingBottom: "13px",
                }}
              >
                {languages[language].FinishedSurveys}
              </Box>
              <Box
                sx={{
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "24px",
                  textAlign: "center",
                }}
              >
                {data?.survey?.finished ?? 0}{" "}
                <span
                  style={{
                    fontSize: "16px",
                    color: "#FF4545",
                  }}
                >
                  {}
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: isMobile ? "flex" : "grid",
            flexDirection: isMobile ? "column" : "unset",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "24px",
          }}
        >
          <AnalysisBox
            title={ok.total_users_count}
            label={data.total_users_count ?? 0}
            onClick={() => {
              setOpen(true);
              // setModalContent({
              //   title: "Активные пользователи",
              //   content: <UserList />,
              // });
              // setModal(true);
            }}
          />
          <AnalysisBox
            title={ok.total_money_spent}
            label={data.total_money_spent ?? 0}
          />
          <AnalysisBox
            title={ok.total_sms_count}
            label={data.total_sms_count ?? 0}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default GeneralInfoAdmin;
