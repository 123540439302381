import { ActionIcon, Button, Flex, Paper, Textarea } from "@mantine/core";
import { FC, useRef, useState } from "react";
import useLang from "../../hooks/useLang";
import { IconTableImport } from "@tabler/icons-react";
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import * as XLSX from "xlsx";

const languages = {
  kaz: {
    send: "Жіберу",
    clear: "Тазарту",
    upload: "Загрузить excel",
  },
  rus: { upload: "Загрузить excel", send: "Отправить", clear: "Очистить" },
};

interface Props {
  isLoading: boolean;
  message: { kaz: string; rus: string };
  setKazMessage: (message: string) => void;
  setRusMessage: (message: string) => void;
  setValues: (values: { phone_number: string; amount: number }[]) => void;
  onClear: () => void;
}

export const Controls: FC<Props> = ({
  message,
  setKazMessage,
  setRusMessage,
  setValues,
  onClear,
  isLoading,
}) => {
  const { language } = useLang();
  const openRef = useRef<() => void>(null);

  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        setValues(
          (jsonData as { phone_number: number; amount: number }[]).map(
            (item) => ({
              phone_number: item.phone_number.toString(),
              amount: item.amount,
            })
          )
        );
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Paper p="md" radius="lg" pos="fixed" w="25vw">
      <Flex direction="column" gap="md" w="100%">
        <Dropzone
          accept={[MIME_TYPES.xlsx]}
          openRef={openRef}
          onDrop={(files) => handleDrop(files)}
          activateOnClick={false}
          styles={{ inner: { pointerEvents: "all" } }}
        >
          <Button
            fullWidth
            color="green"
            leftIcon={<IconTableImport />}
            onClick={() => {
              if (openRef.current) openRef.current();
            }}
          >
            {languages[language].upload}
          </Button>
        </Dropzone>
        <Textarea
          value={message.kaz}
          label="Текст на казахском"
          onChange={(event) => setKazMessage(event.target.value)}
        />
        <Textarea
          value={message.rus}
          label="Текст на русском"
          onChange={(event) => setRusMessage(event.target.value)}
        />
        <Flex gap="md">
          <Button fullWidth color="red" onClick={onClear}>
            {languages[language].clear}
          </Button>
          <Button
            fullWidth
            type="submit"
            style={{ background: "#73A9C2" }}
            loading={isLoading}
          >
            {languages[language].send}
          </Button>
        </Flex>
      </Flex>
    </Paper>
  );
};
