import { BrowserRouter as Router } from "react-router-dom";
import { CustomRouter } from "./Router";
import Loading from "./components/Shared/Loading";
import PopUp from "./components/Shared/PopUp";
import { ContextProviders } from "./context/ContextProviders";

function App() {
  return (
    <ContextProviders>
      <Router>
        <Loading />
        <CustomRouter />
        <PopUp />
      </Router>
    </ContextProviders>
  );
}

export default App;
