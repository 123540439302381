import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import FastIcon from "../UI/FastIcon";
import { useModalContent } from "../../hooks/useModalContent";
import { useModal } from "../../hooks/useModal";
import AddCategory from "./AddCategory";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { faqService } from "../../services/faqService";
import { AdminFAQCategory } from "../../services/responses/AdminFAQCategory";
import { Grid } from "@mantine/core";
import useMobile from "../../hooks/useMobile";
import { AddQuestion } from "./AddQuestion";
import { EditQuestion } from "./EditQuestion";
import { AdminFAQ } from "../../services/responses/AdminFAQs";

export const FAQs = () => {
  const [categories, setCategories] = useState<AdminFAQCategory[]>([]);
  const { setModalContent } = useModalContent();
  const { setModal } = useModal();
  const [active, setActive] = useState("");

  const listFAQs = () => {
    faqService.listAdminFAQs().then((data) => {
      setCategories(data);
      setActive(data[data.length - 1]?.id);
    });
  };

  useEffect(() => {
    (async () => {
      const data = await faqService.listAdminFAQs().then((data) => {
        setCategories(data);
        return data;
      });
      setActive(data[0]?.id);
    })();
  }, []);

  const addQuestion = () => {
    setModal(true);
    setModalContent({
      title: languages[language].Add_quest,
      content: <AddQuestion id={active} func={listFAQs} />,
    });
  };

  const editQuestion = (data: AdminFAQ) => {
    setModal(true);
    setModalContent({
      title: languages[language].Add_quest,
      content: <EditQuestion data={data} func={listFAQs} />,
    });
  };

  const deleteQuestion = (id: string) => {
    faqService.deleteQuestion(id).then((data) => {
      listFAQs();
    });
  };

  const deleteCategory = () => {
    faqService.deleteCategory(active).then((data) => {
      listFAQs();
      setActive(data[categories.length <= 2 ? 0 : categories.length - 2]?.id);
    });
  };
  const { language } = useLang();
  const { isMobile } = useMobile();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <Box>
        <Grid
          align="center"
          sx={{
            marginTop: isMobile ? "20px" : "50px",
            marginLeft: "24px",
            marginBottom: "36px",
            gap: isMobile ? "10px" : "20px",
          }}
        >
          {categories.map((category) => {
            return (
              <Grid.Col
                span="content"
                sx={{
                  padding: "13px 20px",
                  background: active === category.id ? "#73A9C2" : "#DDDDDD",
                  borderRadius: "8px",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "15px",
                  cursor: "pointer",
                  color: active === category.id ? "#FFFFFF" : "#000000",
                }}
                onClick={() => setActive(category.id)}
              >
                {category[language].name}
              </Grid.Col>
            );
          })}
          <Grid.Col
            span="content"
            sx={{
              padding: "13px 20px",
              background: "#FFFFFF",
              borderRadius: "8px",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "15px",
              marginRight: "24px",
              border: "1px solid #73A9C2",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setModal(true);
              setModalContent({
                title: "Новая категория",
                content: <AddCategory fun={() => listFAQs()} />,
              });
            }}
          >
            {languages[language].Add_category}{" "}
            <FastIcon sx={{ marginLeft: "14px" }} name={"plusCate"} />
          </Grid.Col>
        </Grid>

        <Box
          sx={{
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "24px",
            marginLeft: "24px",
            display: "flex",
            justifyContent: "space-between",
            marginRight: "24px",
          }}
        >
          <span>{languages[language].Landing_Fifth_Title}</span>
          {!isMobile ? (
            <span
              onClick={() => deleteCategory()}
              style={{
                color: "#FF0000",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "19px",
                cursor: "pointer",
              }}
            >
              {languages[language].Delete_category}
            </span>
          ) : (
            <></>
          )}
        </Box>

        <Box
          sx={{
            margin: "24px",
          }}
        >
          {categories
            .filter((category) => category.id === active)[0]
            ?.[language].faqs?.map((faq: AdminFAQ, i: number) => {
              return (
                <Grid
                  sx={{
                    padding: "0px",
                    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.05)",
                    background: "white",
                    margin: "0px",
                  }}
                >
                  <Grid.Col
                    span={"auto"}
                    sx={{ padding: "24px 16px", cursor: "pointer" }}
                    onClick={() => {
                      editQuestion(faq);
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <FastIcon
                        name={"circle"}
                        style={{ paddingRight: "16px" }}
                      />
                      {faq.question}
                    </Box>
                  </Grid.Col>
                  <Grid.Col
                    span="content"
                    sx={{ padding: "24px 16px", cursor: "pointer" }}
                    onClick={() => {
                      deleteQuestion(faq.id);
                    }}
                  >
                    <FastIcon name={"trash"} />
                  </Grid.Col>
                </Grid>
              );
            })}
          <Box
            sx={{
              padding: "24px 16px",
              boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.05)",
              cursor: "pointer",
              background: "white",
            }}
            onClick={() => {
              addQuestion();
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                width: "max-content",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                <FastIcon name={"plusFaq"} style={{ paddingRight: "16px" }} />
                {languages[language].Add_quest}
              </span>
            </span>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          marginLeft: "24px",
          display: "flex",
          marginRight: "24px",
        }}
      >
        {isMobile ? (
          <span
            onClick={() => deleteCategory()}
            style={{
              color: "#FF0000",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "19px",
              cursor: "pointer",
            }}
          >
            {languages[language].Delete_category}
          </span>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
