import { Box, Paper, SimpleGrid, createStyles } from "@mantine/core";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { Transaction as TransactionModel } from "../../models/transaction";
import { balanceService } from "../../services/Balance";
import { Transaction } from "./Transaction";
import FastIcon from "../UI/FastIcon";
import useLang from "../../hooks/useLang";
import { TransactionData } from "./TransactionData";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { DownloadTransactions } from "./DownloadTransactions";

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: "26px 16px",
  },

  transactions: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  transaction_data: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
    display: "flex",
    flexDirection: "column",

    gap: "20px",
  },
}));

const languages = {
  kaz: {
    title: "Әзірше әрекеттер жоқ",
  },
  rus: {
    title: "Пока нет операций",
  },
};

export const ListTransactions: FC = () => {
  const { cx, classes } = useStyles();
  const { language } = useLang();
  const [transactions, SetTransactions] = useState<TransactionModel[]>([]);
  const [selected, setSelected] = useState<TransactionModel | null>(null);
  const { refetch } = useQuery(
    "ListTransactions",
    async () => {
      return await balanceService.listTransactions("pending");
    },
    {
      onSuccess: (response) => {
        SetTransactions(response);
      },
      onError: () => {},
    }
  );

  return (
    <Box className={classes.wrapper}>
      {transactions.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FastIcon name="cards" />
          <Box
            sx={{
              marginTop: "16px",
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            {languages[language].title}
          </Box>
        </Box>
      ) : (
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          <Box className={classes.transactions}>
            {transactions.map((transaction) => (
              <Transaction
                key={transaction.id}
                {...transaction}
                refetch={refetch}
                active={transaction === selected}
                onClick={() => {
                  setSelected(transaction);
                }}
              />
            ))}
          </Box>
          <Box className={classes.transaction_data}>
            <DownloadTransactions />
            <TransactionData
              selected={selected}
              refetch={refetch}
              clear={() => setSelected(null)}
            />
          </Box>
        </SimpleGrid>
      )}
    </Box>
  );
};
