import { useEffect, useState } from "react";
import { Signup } from "../components/Signup/Signup";
import useLoading from "../hooks/useLoading";
import useMobile from "../hooks/useMobile";
import useTablet from "../hooks/useTablet";

export const SignupPage = () => {
  const { setTabletFun } = useTablet();
  const { setMobileFun } = useMobile();
  const [width, setWidth] = useState(0);
  const { setLoading } = useLoading();

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 600 && window.innerWidth <= 800) {
      setTabletFun(true);
      setMobileFun(false);
    } else if (window.innerWidth <= 600) {
      setTabletFun(false);
      setMobileFun(true);
    } else {
      setMobileFun(false);
      setTabletFun(false);
    }
  }, [width]);
  useEffect(() => {}, [window.innerWidth]);
  useEffect(() => {
    setLoading(false);
  }, []);
  return <Signup />;
};
