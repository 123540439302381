import React, { createContext, useState } from "react";

export const TabletContext = createContext<any>({});

export const TabletProvider = ({ children }: any) => {
  const [isTablet, setTablet] = useState(true);

  const setTabletFun = (moduleState: any) => {
    setTablet(moduleState);
  };

  return (
    <TabletContext.Provider
      value={{
        isTablet,
        setTabletFun,
      }}
    >
      {children}
    </TabletContext.Provider>
  );
};
