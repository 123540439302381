import { Box } from "@mui/material";
import useMobile from "../../../hooks/useMobile";
import FastIcon from "../../UI/FastIcon";
import LangSwitch from "../../Shared/LangSwitch";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  if (isMobile) {
    return (
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FastIcon
          name={"logoMobile"}
          height={"20px"}
          width={"98px"}
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        />
        <LangSwitch />
      </Box>
    );
  } else
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          borderBottom: "1px solid #B9B8B5",
          flexDirection: "column",
          marginTop: "106px",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <FastIcon
            name={"logo"}
            height={"36px"}
            width={"178px"}
            onClick={() => {
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          />
          <LangSwitch />
        </Box>
      </Box>
    );
};
