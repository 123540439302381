import { Flex, Spoiler, Text } from "@mantine/core";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { Transaction } from "../../models/transaction";
import { balanceService } from "../../services/Balance";
import { TransactionCard } from "./TransactionCard";

interface Props {
  account_id: string;
}

export const ListAccountTransactions: FC<Props> = ({ account_id }) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  useQuery(
    ["ListAccountTransactions", account_id],
    async () => {
      return await balanceService.ListAccountTransactions(account_id);
    },
    {
      onSuccess: (response) => {
        setTransactions(response);
      },
    }
  );

  return (
    <Flex direction="column">
      <Text fw={700} size="lg">
        История транзакции пользователя
      </Text>
      <Spoiler
        mt="xs"
        maxHeight={212}
        showLabel="Показать все"
        hideLabel="Скрыть"
      >
        <Flex direction="column" gap="12px">
          {transactions.map((item) => (
            <TransactionCard key={item.id} {...item} />
          ))}
        </Flex>
      </Spoiler>
    </Flex>
  );
};
