import {
  Anchor,
  Avatar,
  Group,
  HoverCard,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileViewerModal } from "../components/FileViewer/FileViewerModal";
import { HelpWindow } from "../components/HelpWindow/HelpWindow";
import LangSwitch from "../components/Shared/LangSwitch";
import FastButton from "../components/UI/FastButton";
import FastIcon from "../components/UI/FastIcon";
import useLanguage from "../hooks/useLang";
import useLoading from "../hooks/useLoading";
import useMobile from "../hooks/useMobile";
import useTablet from "../hooks/useTablet";
import pageTwo from "../Images/2page.png";
import pageFour from "../Images/4page.png";
import back from "../Images/newBack.png";
import { languages } from "../Languages/languages";
import { faqService } from "../services/faqService";
import { FAQCategory } from "../services/responses/FAQCategory";
import logo from "../Images/logo.svg";
import { IconBrandYoutubeFilled } from "@tabler/icons-react";
import paperlab from "../Images/paperlab.png";
import the_guests from "../Images/the_guests.png";

const LandingPage = () => {
  const { setLoading } = useLoading();
  const { language } = useLanguage();
  const [fileID, setFileID] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  setLoading(false);
  const [selectedQuestionIndex, SetSelectedQuestionIndex] = useState<number>(0);
  const [hoverIndex, setHoverIndex] = useState(0);
  const navigate = useNavigate();

  const [categories, setCategories] = useState<FAQCategory[]>([]);
  const [active, setActive] = useState("");
  const { isTablet, setTabletFun } = useTablet();
  const { isMobile, setMobileFun } = useMobile();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 600 && window.innerWidth <= 800) {
      setTabletFun(true);
      setMobileFun(false);
    } else if (window.innerWidth <= 600) {
      setTabletFun(false);
      setMobileFun(true);
    } else {
      setMobileFun(false);
      setTabletFun(false);
    }
  }, [width]);

  useEffect(() => {}, [window.innerWidth]);

  const getFaq = () => {
    faqService.listFAQs().then((data) => {
      setCategories(data);
    });
  };

  useEffect(() => {
    getFaq();
  }, []);

  useEffect(() => {
    setActive(categories[0]?.id);
  }, [categories]);

  // useEffect(() => {
  // 	landingService.getFAQ().then(
  // 		data => {
  // 			setFaq(data)
  // 			setSelectQuestion(data[0])
  // 		}
  // 	)
  //
  // }, [])

  return (
    <React.Fragment>
      <FileViewerModal
        isOpen={open}
        handleClose={() => setOpen(false)}
        title={title}
        fileID={fileID}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "#FBFAFA",
        }}
      >
        {/*header*/}
        <Box
          sx={{
            width: isMobile ? "-webkit-fill-available" : "1200px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: isMobile ? "0 24px" : "30px 0",
            margin: "auto",
          }}
        >
          <FastIcon
            name={isMobile ? "logoMobile" : "logo"}
            onClick={() => {
              navigate("/");
            }}
            style={{ paddingTop: "15px", cursor: "pointer" }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "row-reverse" : "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "24px",
              }}
            >
              <FastButton
                variant={isMobile ? "contained" : "unset"}
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "22px",
                }}
                click={() => {
                  navigate("/signin");
                }}
              >
                {languages[language].Btn_Sing_in}
              </FastButton>
              {isMobile ? null : (
                <FastButton
                  variant="contained"
                  style={{
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "22px",
                    padding: "16px 24px",
                    marginRight: "52px",
                  }}
                  click={() => {
                    navigate("/signup");
                  }}
                >
                  {languages[language].Btn_Sing_up}
                </FastButton>
              )}
            </Box>
            <LangSwitch />
          </Box>
        </Box>
        {/*1-page*/}
        <Box
          sx={{
            width: "100%",
            backgroundImage: isMobile
              ? "unset"
              : `linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(252, 252, 252, 0.45) 100%),url(${back})`,
            color: isMobile ? "black" : "#FFFFFF",
            backgroundSize: "cover",
            marginBottom: isMobile ? "0px" : "80px",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: isMobile ? "100%" : "1200px",
            }}
          >
            <img
              src={back}
              style={{
                display: isMobile ? "block" : "none",
                width: "100%",
                objectFit: "contain",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                padding: "24px",
              }}
            >
              <Box
                sx={
                  isMobile
                    ? {
                        fontSize: "30px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "140%",
                        letterSpacing: "-1px",
                      }
                    : {
                        fontWeight: 500,
                        fontSize: "60px",
                        lineHeight: "68px",
                        paddingTop: "80px",
                        margin: "0",
                      }
                }
              >
                {languages[language].Landing_Main_Title}
              </Box>
              <Box
                sx={
                  isMobile
                    ? {
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }
                    : {
                        fontWeight: 500,
                        fontSize: isMobile ? "20px" : "32px",
                        lineHeight: "39px",
                        paddingTop: isMobile ? "24px" : "36px",
                        paddingBottom: isMobile ? "35px" : "121px",
                        margin: isMobile ? "0 24px" : "0",
                        maxWidth: "700px",
                      }
                }
              >
                {languages[language].Landing_Main_Subtitle}
              </Box>
            </Box>
            <Box
              sx={{
                paddingBottom: isMobile ? "0" : "80px",
              }}
            >
              <FastButton
                variant="contained"
                click={() => {
                  navigate("/signin");
                }}
                style={{
                  display: isMobile ? "none" : "block",

                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "22px",
                  padding: "24px 40px",
                  margin: "0",
                }}
              >
                {languages[language].Btn_Try_Now}
              </FastButton>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: isMobile ? "60px" : "0px",
          }}
        >
          {/*2-page*/}
          <Box
            sx={{
              background: "#F5F4F3",
              width: "100%",
              marginBottom: isMobile ? "0px" : "100px",
            }}
          >
            <Box
              sx={{
                display: isMobile ? "flex" : "grid",
                gridTemplateColumns: "1fr 1fr",
                flexDirection: isMobile ? "column-reverse" : "row",
                width: isMobile ? "unset" : "1200px",
                margin: isMobile ? "0 24px" : "0 auto",
              }}
            >
              <Box
                sx={
                  isMobile
                    ? {
                        padding: "36px 0px 36px 0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }
                    : {
                        width: "550px",
                        padding: "36px 40px 36px 0",
                      }
                }
              >
                <Box
                  sx={
                    isMobile
                      ? {
                          alignSelf: "stretch",
                          color: "#73A9C2",
                          fontSize: "30px",
                          fontStyle: "normal",
                          fontWeight: 800,
                          lineHeight: "normal",
                        }
                      : {
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "40px",
                          lineHeight: "120%",
                          color: "#73A9C2",
                          paddingBottom: "24px",
                        }
                  }
                >
                  {languages[language].Landing_Second_Title}
                </Box>
                <Box
                  sx={
                    isMobile
                      ? {
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }
                      : {
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "32px",
                          lineHeight: "38px",
                          color: "#000000",
                        }
                  }
                >
                  {languages[language].Landing_Second_Subtitle}
                </Box>
              </Box>
              <Box sx={{ paddingLeft: 0 }}>
                <img src={pageTwo} style={{ width: "100%" }} />
              </Box>
            </Box>
          </Box>

          {/*3page*/}
          <Box
            sx={
              isMobile
                ? {
                    padding: "0 24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }
                : {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    width: "1200px",
                    justifyContent: "space-between",
                    margin: "auto",
                    flexDirection: "row",
                  }
            }
          >
            <Box
              sx={{
                width: "100%",
                padding: "0",
              }}
            >
              <Box
                sx={
                  isMobile
                    ? {
                        fontSize: "30px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "130%",
                      }
                    : {
                        maxWidth: "550px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "40px",
                        lineHeight: "130%",
                        paddingBottom: "86px",
                      }
                }
              >
                {languages[language].Landing_Third_Title}
              </Box>
              <Box>
                <FastButton
                  variant="contained"
                  click={() => {
                    navigate("/signin");
                  }}
                  style={{
                    display: isMobile ? "none" : "block",

                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "22px",
                    padding: "24px 140px",
                  }}
                >
                  {languages[language].Btn_Try_Now}
                </FastButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: isMobile ? "24px" : "36px",
                width: isMobile ? "unset" : "100%",
              }}
            >
              {[
                languages[language].Landing_Third_first,
                languages[language].Landing_Third_second,
                languages[language].Landing_Third_third,
              ].map((el: any, index: any) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        width: "50px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: isMobile ? "32px" : "52px",
                        lineHeight: "44px",
                        color: "#73A9C2",
                      }}
                    >
                      {index + 1}
                    </span>
                    <Box
                      sx={
                        isMobile
                          ? {
                              fontSize: "20px",
                              padding: "0px",
                              fontStyle: "normal",
                              fontWeight: 800,
                              lineHeight: "44px",
                              verticalAlign: "middle",
                            }
                          : {
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: "36px",
                              lineHeight: "44px",
                            }
                      }
                    >
                      {el}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <FastButton
              variant="contained"
              click={() => {
                navigate("/signin");
              }}
              style={
                isMobile
                  ? {
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                      display: "flex",
                      padding: "16px 24px",
                      width: "100%",
                    }
                  : {
                      display: !isMobile ? "none" : "block",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "24px",
                      lineHeight: "22px",
                      width: isMobile ? "unset" : "100%",
                      padding: "24px 0",
                      margin: "22px",
                    }
              }
            >
              {languages[language].Btn_Start_Now}
            </FastButton>
          </Box>

          {/*4-page*/}
          <Box
            sx={{
              background: "#F5F4F3",
              width: isMobile ? "auto" : "100%",
              marginTop: isMobile ? "0px" : "100px",
              marginBottom: isMobile ? "0px" : "0px",
            }}
          >
            <Box
              sx={{
                display: isMobile ? "flex" : "grid",
                gridTemplateColumns: "1fr 1fr",
                flexDirection: isMobile ? "column" : "row",
                width: isMobile ? "auto" : "1200px",
                margin: isMobile ? "0 24px" : "0 auto",
              }}
            >
              <Box
                sx={{
                  width: isMobile ? "100%" : "550px",
                  padding: isMobile ? "36px 0px" : "36px 40px 36px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    fontSize: isMobile ? "30px" : "40px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: isMobile ? "normal" : "120%",
                    color: "#73A9C2",
                  }}
                >
                  {languages[language].Landing_Fourth_Title}
                </Box>
                <Box
                  sx={
                    isMobile
                      ? {
                          fontSize: "20px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }
                      : {
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "32px",
                          lineHeight: "38px",
                          color: "#000000",
                        }
                  }
                >
                  {languages[language].Landing_First_Body}
                </Box>
              </Box>
              <Box sx={{ paddingLeft: isMobile ? "0" : "0" }}>
                <img src={pageFour} style={{ width: "100%" }} />
              </Box>
            </Box>
          </Box>

          <Box
            sx={
              isMobile
                ? {
                    padding: "0 24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }
                : {
                    display: "flex",
                    width: "1200px",
                    margin: "80px auto",
                    flexDirection: "column",
                    gap: "24px",
                  }
            }
          >
            <Box
              sx={
                isMobile
                  ? {
                      fontSize: "30px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }
                  : {
                      fontWeight: 400,
                      fontSize: "60px",
                      lineHeight: "72px",
                      marginBottom: "6px",
                    }
              }
            >
              {languages[language].Landing_Fifth_Title}
            </Box>
            <Box
              sx={{
                overflowY: "scroll",
                whiteSpace: "nowrap",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              }}
            >
              {categories.map((category: FAQCategory) => {
                return (
                  <Box
                    sx={{
                      display: "inline-block",
                      padding: "13px 20px",
                      background:
                        active === category.id ? "#73A9C2" : "#DDDDDD",
                      borderRadius: "8px",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "15px",
                      marginRight: "24px",
                      cursor: "pointer",
                      color:
                        active === category.id
                          ? "#FFFFFF"
                          : category[language].faqs?.length == 0
                          ? "#00000050"
                          : "#000000",
                    }}
                    onClick={() => {
                      if (category[language].faqs?.length > 0) {
                        setActive(category.id);
                        SetSelectedQuestionIndex(0);
                      }
                    }}
                  >
                    {category[language].name}
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box
                sx={{
                  zIndex: 10,
                  width: isMobile ? "100%" : "unset",
                }}
              >
                {categories
                  .filter((category) => category.id === active)[0]
                  ?.[language].faqs?.map((el: any, index: any) => {
                    return (
                      <>
                        <Box
                          onClick={() => {
                            setHoverIndex(index);
                            SetSelectedQuestionIndex(index);
                          }}
                          sx={{
                            width: isMobile ? "unset" : "600px",
                            padding: "24px 16px",
                            boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.05)",
                            background:
                              hoverIndex === index ? "#FBFAFA" : "#FFFFFF",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight:
                                  selectedQuestionIndex === index
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              <FastIcon
                                name={
                                  selectedQuestionIndex === index
                                    ? "circle2"
                                    : "circle"
                                }
                                style={{ paddingRight: "16px" }}
                              />
                              {el.question}
                            </span>

                            <FastIcon
                              name={
                                selectedQuestionIndex === index
                                  ? "right"
                                  : "right2"
                              }
                            />
                          </span>
                        </Box>
                        <Box
                          sx={{
                            padding: "24px",
                            display:
                              selectedQuestionIndex === index && isMobile
                                ? "block"
                                : "none",
                          }}
                        >
                          {
                            categories.filter(
                              (category) => category.id === active
                            )[0]?.[language].faqs[selectedQuestionIndex]?.answer
                          }
                        </Box>
                      </>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginLeft: "-100px",
                  display: isMobile ? "none" : "block",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.15)",
                    borderRadius: "16px",
                    width: "auto",
                    height: "390px",
                    right: 0,
                    padding: "40px 72px 40px 144px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "28px",
                      color: "#18191F",
                      paddingBottom: "40px",
                    }}
                  >
                    {
                      categories.filter(
                        (category) => category.id === active
                      )[0]?.[language].faqs[selectedQuestionIndex]?.question
                    }
                  </Box>
                  <Box sx={{ whiteSpace: "pre-line" }}>
                    {" "}
                    {
                      categories.filter(
                        (category) => category.id === active
                      )[0]?.[language].faqs[selectedQuestionIndex]?.answer
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={
              isMobile
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    padding: "0 24px",
                    background: "#F5F4F3",
                  }
                : {
                    background: "#F5F4F3",
                    width: "100%",
                  }
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: isMobile ? "100%" : "1200px",
                margin: "0 auto",
                padding: "56px 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: isMobile ? "center" : "unset",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "center",
                  gap: "40px",
                }}
              >
                <Anchor href="https://paperlab.kz/">
                  <Image src={paperlab} maw={"200px"} />
                </Anchor>
                <Anchor href="https://the-guests.com/">
                  <Image src={the_guests} maw={"200px"} />
                </Anchor>
              </Box>
              <Box
                sx={
                  isMobile
                    ? {
                        padding: "20px",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                        textAlign: "center",
                      }
                    : {
                        width: "100%",
                        textAlign: "center",
                        padding: "24px 0",
                        fontWeight: 500,
                        fontSize: "32px",
                        lineHeight: "24px",
                      }
                }
              >
                {languages[language].Landing_Second_Body}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "unset" : "1200px",
              justifyContent: "space-between",
              padding: isMobile ? "24px" : "100px 0",
              margin: "auto",
              gap: isMobile ? "24px" : 0,
            }}
          >
            <Box>
              <Box
                sx={
                  isMobile
                    ? {
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "30px",
                      }
                    : {
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "60px",
                      }
                }
              >
                {languages[language].Landing_Third_Body}
              </Box>
              <Box
                sx={
                  isMobile
                    ? {
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "20px",
                      }
                    : {
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "52px",
                        paddingBottom: "32px",
                      }
                }
              >
                {languages[language].Landing_Fourth_Body}
              </Box>
            </Box>

            <HelpWindow />
          </Box>

          <Box
            sx={
              isMobile
                ? { padding: "24px", background: "#333333" }
                : {
                    background: "#333333",
                  }
            }
          >
            <Box
              sx={
                isMobile
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      gap: "24px",
                    }
                  : {
                      display: "flex",
                      flexDirection: "column",
                      width: "1200px",
                      margin: "0 auto",
                      padding: "56px 0",
                    }
              }
            >
              <Box
                sx={
                  isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        margin: "auto",
                        gap: "24px",
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: "32px",
                      }
                }
              >
                {isMobile ? (
                  <FastIcon
                    name={"logo_white"}
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{
                      paddingTop: "15px",
                      cursor: "pointer",
                      color: "white",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "48px",
                      lineHeight: "59px",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    PIKIRLAB
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "25px",
                    padding: "0 25px",
                  }}
                >
                  <a href="https://www.facebook.com/pikirlab/" target="_blank">
                    <FastIcon name="facebook" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/pikirlab/"
                    target="_blank"
                  >
                    <FastIcon name="linkedin" />
                  </a>
                  <a href="https://www.instagram.com/pikirlab/" target="_blank">
                    <FastIcon name="insta" />
                  </a>
                </Box>
              </Box>
              <Box
                sx={
                  isMobile
                    ? {
                        display: "flex",
                        color: "#FFFFFF",
                        flexDirection: "column-reverse",
                        justifyContent: "center",
                        margin: "auto",
                        textAlign: "center",
                        gap: "24px",
                      }
                    : {
                        color: "#FFFFFF",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }
                }
              >
                <HoverCard
                  width={320}
                  shadow="md"
                  openDelay={200}
                  closeDelay={200}
                >
                  <HoverCard.Target>
                    <Box>© PIKIRLAB, 2023</Box>
                  </HoverCard.Target>
                  <HoverCard.Dropdown c="black">
                    <Text size="sm" mt="md">
                      <b>Наименование:</b> Товарищество с ограниченной
                      ответственностью «PikirLab»
                    </Text>
                    <Text size="sm" mt="md">
                      <b>БИН:</b> 230740013132
                    </Text>
                    <Text size="sm" mt="md">
                      <b>Юридический адрес:</b> РК, город Астана, район Есиль,
                      улица Түркістан, дом 2, кв.119, 010000
                    </Text>

                    <Group mt="md" spacing="xl">
                      <Text size="sm">
                        <b>+7 701 494 01 38</b>
                      </Text>
                      <Text size="sm">
                        <b>info@pikirlab.kz</b>
                      </Text>
                    </Group>
                  </HoverCard.Dropdown>
                </HoverCard>
                <Box
                  sx={
                    isMobile
                      ? {
                          display: "flex",
                          color: "#FFFFFF",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          margin: "auto",
                          gap: "24px",
                        }
                      : {
                          display: "flex",
                          flexDirection: "row",
                        }
                  }
                >
                  <Box
                    onClick={() => {
                      setOpen(true);
                      setTitle(languages[language].Landing_First_Footer);
                      setFileID("1DM5mrmOcZJx1drtBsOFkWUVMHmxerkRy");
                    }}
                    sx={
                      isMobile
                        ? {
                            textAlign: "center",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "16px",
                          }
                        : {
                            paddingRight: "40px",
                            cursor: "pointer",
                          }
                    }
                  >
                    {languages[language].Landing_First_Footer}
                  </Box>
                  <Box
                    onClick={() => {
                      setOpen(true);
                      setTitle(languages[language].Landing_Second_Footer);
                      setFileID("1aGeGiYWCy8AmgPGxzL_XaT9SmAJ2JSj6");
                    }}
                    sx={
                      isMobile
                        ? {
                            textAlign: "center",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 800,
                          }
                        : {
                            cursor: "pointer",
                          }
                    }
                  >
                    {languages[language].Landing_Second_Footer}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LandingPage;
