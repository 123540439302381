import { useContext } from "react";
import { ModalContext } from "../context/ModalProvider";

export function useModal() {
  const { isModalOpen, setModal } = useContext(ModalContext);

  return {
    isModalOpen,
    setModal,
  };
}
