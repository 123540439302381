import axios from "axios";

const axiosApiInstance = axios.create();

class DataService {
  async addMember(promo: any) {
    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      },
    };

    return axiosApiInstance
      .get(`https://api.pikirlab.kz/promote/${promo}`, headers)
      .then((response) => response.data);
  }

  async deleteManager(id: any) {
    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      },
    };

    return axiosApiInstance
      .delete(`https://api.pikirlab.kz/managers/${id}`, headers)
      .then((response) => response.data);
  }
  async getManagers() {
    const headers = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      },
    };

    return axiosApiInstance
      .get("https://api.pikirlab.kz/managers", headers)
      .then((response) => response.data);
  }
}

export const promote = new DataService();
