import { FC, useState } from "react";
import { Text, Image, SimpleGrid, Flex } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE, FileWithPath } from "@mantine/dropzone";
import { useMutation } from "@tanstack/react-query";
import { surveyService } from "../../../services/Surveys";
import useLang from "../../../hooks/useLang";
import { languages } from "../../../Languages/languages";
import FastIcon from "../../UI/FastIcon";

export const ImageDropZone: FC<{ data: any; SetData: any }> = ({
  data,
  SetData,
}) => {
  const { language } = useLang();
  const [files, setFiles] = useState<FileWithPath[]>([]);

  const mutation = useMutation(
    async (fileWithPath: FileWithPath) => {
      const response = await surveyService.UploadImage(fileWithPath);
      SetData({ ...data, image_link: response.data?.link });
      return fileWithPath;
    },
    {
      onSuccess: (fileWithPath) => {
        setFiles((stateOld) => [...stateOld, fileWithPath]);
      },
      onError: (error: any) => {
        alert(JSON.stringify(error.response.data.error));
      },
    }
  );
  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        imageProps={{
          onLoad: () => {
            return URL.revokeObjectURL(imageUrl);
          },
        }}
      />
    );
  });

  const onDrop = async (fileWithPath: FileWithPath[]) => {
    mutation.mutate(fileWithPath[0]);
  };

  return (
    <div>
      {files.length == 0 ? (
        <Dropzone
          accept={IMAGE_MIME_TYPE}
          onDrop={onDrop}
          mb="18px"
          radius="lg"
          p="12px 12px 12px 18px"
        >
          <Flex>
            <FastIcon
              name={"addImg"}
              style={{ marginRight: "10px", marginBottom: "-5px" }}
            />
            <Text color="#73A9C2" align="left">
              {languages[language].SurveyImageDropZone}
            </Text>
          </Flex>
        </Dropzone>
      ) : (
        <SimpleGrid
          cols={4}
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          mt={previews.length > 0 ? "xl" : 0}
        >
          {previews}
        </SimpleGrid>
      )}
    </div>
  );
};
