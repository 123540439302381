import React, { createContext, useState } from "react";

export const ModalContext = createContext<any>({});

export const ModalProvider = ({ children }: any) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const setModal = (modalState: any) => {
    setModalOpen(modalState);
  };

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
