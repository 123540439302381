import { List, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { Box } from "@mui/system";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { languages } from "../../../Languages/languages";
import useLang from "../../../hooks/useLang";
import useMobile from "../../../hooks/useMobile";
import useUser from "../../../hooks/useUser";
import { ErrorResponse } from "../../../models/error";
import { userService } from "../../../services/UserService";
import { verificationsService } from "../../../services/Verifications";
import { LoginResponse } from "../../../services/responses/LoginResponse";
import FastButton from "../../UI/FastButton";
import FastInput from "../../UI/FastInput";
import block from "../block.svg";
import { SetEmailModal } from "./SetEmailModal";
import { VerifyPhoneNumberModal } from "./VerifyPhoneNumberModal";

const _languages = {
  kaz: {
    retrieve: "Өтінімді жіберу",
    warning: "Техникалық жұмыстар жүріп жатыр",
    email_modal: {
      title: "Поштаны еңгізу",
    },
  },
  rus: {
    retrieve: "Отправить заявку",
    warning: "Идут технические работы",
    email_modal: {
      title: "Укажите почту",
    },
  },
};

const Instructions = {
  kaz: (
    <>
      <Text size={"20px"} weight={700}>
        Қаражатты алуға арналған нұсқаулық:
      </Text>
      <List type="ordered">
        <List.Item>
          Қанша ұпай жинағыңыз келетінін енгізіңіз. Минималды - 500 ұпай
        </List.Item>
        <List.Item>"Өтінім жіберу" түймесін басыңыз</List.Item>
        <List.Item>
          Сіз тіркелген телефон нөмірі арқылы жіберген SMS-тен кодты растаңыз
        </List.Item>
        <List.Item>
          Деректерді қауіпсіз тасымалдау үшін біздің провайдерлердің жаңа
          терезесі ашылады! (Сайт шолғыш құлыптауы мүмкін, бұл жағдайда
          құлыпталған белгіше терезесін ашыңыз немесе шолғыш параметрлерін
          тексеріңіз)
        </List.Item>
        <List.Item>Телефон нөміріңізді енгізіңіз</List.Item>
        <List.Item>
          Банктік картаңызға немесе телефон нөміріңізге, «Әрі қарай» (Далее)
          және «Тенгемен төлеу» (Выплатить тенге) пернелерін басыңыз
        </List.Item>
        <List.Item>
          Осылайша сіз біздің менеджерлер аптасына 1-2 рет қарайтын өтінімді
          жібердіңіз және сіздің қаражатыңыз сізге түседі.
        </List.Item>
      </List>
      <Text>
        P. S. Егер қате орын алса немесе сіз парақтан шықсаңыз, онда 24 сағат
        күтіп, қайталап көріңіз. Сіз ала алмаған ақша сіздің шотыңызға қайта
        оралады.
      </Text>
    </>
  ),
  rus: (
    <>
      <Text size={"20px"} weight={700}>
        Инструкция для вывода средств:
      </Text>
      <List type="ordered">
        <List.Item>
          Введите сколько баллов вы хотите вывести. Минимально – 500
        </List.Item>
        <List.Item>Нажмите «Отправить заявку»</List.Item>
        <List.Item>
          Подтвердите код из СМС, который мы отправили по номеру телефона, на
          который вы зарегистрировались
        </List.Item>
        <List.Item>
          Откроется новое окно наших провайдеров для безопасной передачи данных!
          (Окно может быть заблокировано вашим браузером, в этом случае откройте
          заблокированное окно - значок , либо проверьте ваши настройки
          браузера)
        </List.Item>
        <List.Item>Введите ваш номер телефона</List.Item>
        <List.Item>
          Выберите способ вывода средств на вашу банковскую карту или номер
          телефона «Далее» и «Выплатить тенге»
        </List.Item>
        <List.Item>
          Тем самым вы отправили заявку, которую наши менеджера будут
          рассматривать 1-2 раза в неделю, и вам поступят ваши средства.
        </List.Item>
        <Text>
          P.S. Если произошла ошибка или вы вышли из страницы, то тогда
          дождитесь 24 часа и попробуйте снова. Деньги, которые вам не удалось
          вывести, вернуться вам на счет.
        </Text>
      </List>
    </>
  ),
};

export interface RightProps {
  balance: number;
  _refetch: () => void;
}

const Right: React.FC<RightProps> = ({ balance, _refetch }) => {
  const { language } = useLang();
  const { isMobile } = useMobile();
  const [user, setUser] = useState<LoginResponse | null>(null);
  const { userData } = useUser();
  const [amount, setAmount] = useState<number>();

  const send_sms = useMutation(
    async () => {
      return await verificationsService.Create();
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  const { refetch } = useQuery(
    "GetUser",
    async () => {
      return await userService.GetUser();
    },
    {
      onSuccess: (response: LoginResponse) => {
        setUser(response);
      },
      onError: (error: ErrorResponse) => {},
      refetchInterval: () => 3000,
    }
  );

  const sms = () => {
    send_sms.mutate();
    modals.open({
      title: languages[language].inner_title,
      size: "sm",
      radius: "lg",
      xOffset: 0,
      children: (
        <VerifyPhoneNumberModal
          phone_number={userData.account.phone_number}
          amount={amount}
          refetch={() => {
            refetch();
            setAmount(0);
            _refetch();
          }}
        />
      ),
    });
  };

  return (
    <Box sx={{ padding: isMobile ? "16px" : "0 24px 0 0" }}>
      <Box
        sx={{
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "28px",
          paddingBottom: "8px",
          borderBottom: "1px solid #ACACAC",
          marginBottom: "12px",
          marginTop: "40px",
        }}
      >
        {languages[language].Right_f}
      </Box>
      <Box
        sx={{
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "22px",
          paddingBottom: "16px",
          paddingTop: "24px",
        }}
      >
        {languages[language].Right_t}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <FastInput
          label={languages[language].Right_th}
          value={amount}
          type="number"
          // disabled
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const amount =
              +event.target.value > balance ? balance : +event.target.value;
            setAmount(amount);
          }}
        />
        <FastButton
          style={{
            width: "100%",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "12px",
            padding: "20px",
            borderRadius: "0",
          }}
          variant={"contained"}
          click={() => {
            {
              user?.user.email && user.user.email.verified
                ? sms()
                : modals.open({
                    modalId: "set_email_modal",
                    title: _languages[language].email_modal.title,
                    size: "sm",
                    radius: "lg",
                    xOffset: 0,
                    children: <SetEmailModal refetch={refetch} />,
                  });
            }
          }}
          disabled={!amount || amount < 500}
          // disabled
        >
          {_languages[language].retrieve}
        </FastButton>
      </Box>
      <Box
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "22px",
          color: "#ACACAC",
          paddingTop: "24px",
        }}
      >
        {languages[language].Right_s}
      </Box>
      {Instructions[language]}
    </Box>
  );
};

export default Right;
