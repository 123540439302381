import { Box } from "@mui/material";
import Layout from "./Layout";
import SideBar from "./Sidebar";
import Uheader from "./Uheader";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { useEffect, useState } from "react";
import { dataService } from "../../services/getLogin";
import useMobile from "../../hooks/useMobile";
import useLoading from "../../hooks/useLoading";
import NavMobile from "./NavMobile";

const MainLayout = ({ children, pageClick }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUserData } = useUser();
  const { isMobile, setMobileFun } = useMobile();
  const { isLoading, setLoading } = useLoading();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (location.pathname !== "/" && !window.localStorage.getItem("token")) {
      setLoading(false);
      navigate("/");
    } else {
      dataService
        .getData()
        .then((data: any) => {
          setUserData(data);
          dataService.getPromo().then((data) => {});
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/");
          }
        });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 802) {
      setMobileFun(true);
    } else {
      setMobileFun(false);
    }
  }, [width]);

  useEffect(() => {}, [window.innerWidth]);

  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        overFlowX: "unset",
      }}
    >
      {isMobile ? null : <SideBar pageClick={pageClick} />}
      <Box sx={{ width: "100%" }}>
        <Uheader />
        <Box
          sx={{
            marginLeft: isMobile ? "0px" : " 311px",
            marginTop: "85px",
            backgroundColor: "#FBF7F4",
            paddingBottom: "100px",
          }}
        >
          {children}
        </Box>
        {!isMobile ? null : <NavMobile />}
      </Box>
    </Layout>
  );
};

export default MainLayout;
