import { Box } from "@mui/system";
import FastIcon from "../UI/FastIcon";
import { Button, styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import FastButton from "../UI/FastButton";
import { surveyService } from "../../services/Surveys";
import { FC, useEffect, useState } from "react";
import useMobile from "../../hooks/useMobile";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { Text } from "@mantine/core";
import { useModal } from "../../hooks/useModal";
import useTablet from "../../hooks/useTablet";
import { useQuery } from "react-query";
import { AnalysisCard } from "../Surveys/Blocks/AnalysisCard";
import { ErrorResponse } from "../../models/error";
import { AdminSurveyResponse } from "../../services/responses/SurveyResponse";
import { useNavigate } from "react-router-dom";

interface SurveyAnalysisProps {
  id: string;
  status: string;
  reload: () => void;
}

const categories = [
  "age",
  "gender",
  "residence",
  "country",
  "region",
  "nationality",
  "language",
  "family_status",
  "family_members_count",
  "children_count",
  "total_income",
  "financial_situation",
  "employment_status",
  "work_obligation",
  "ownership",
  "social_network",
  "location",
];

const SurveyInfo: FC<SurveyAnalysisProps> = ({ id, status, reload }) => {
  const { language } = useLang();
  const { isMobile } = useMobile();
  const { setModal } = useModal();
  const { isTablet } = useTablet();
  const [time, setTime] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [survey, setSurvey] = useState<AdminSurveyResponse | null>(null);

  useQuery(
    "GetSurveyAnalysisTime",
    async () => {
      return await surveyService.GetSurveyAnalysisTime(id);
    },
    {
      onSuccess: (response) => {
        setTime(response.response_time);
      },
      onError: (error: ErrorResponse) => {},
    }
  );

  useQuery(
    "GetSurveyAnalysisCount",
    async () => {
      return await surveyService.GetSurveyAnalysisCount(id);
    },
    {
      onSuccess: (response) => {
        setCount(response.respondents_count);
      },
      onError: (error: ErrorResponse) => {},
    }
  );

  useQuery(
    "GetSurvey" + id,
    async () => {
      return await surveyService.getSurvey(id);
    },
    {
      onSuccess: (response: AdminSurveyResponse) => {
        setSurvey(response);
      },
      onError: (error: any) => {},
    }
  );

  const deleteSurvey = (id: any) => {
    surveyService.deleteSurvey(id).then((data) => {
      setModal(false);
      reload();
    });
  };

  const stopSurvey = (id: any) => {
    surveyService
      .patchSurvey(id, {
        status: "stopped",
      })
      .then((data) => {
        setModal(false);
        reload();
      });
  };

  const startSurvey = (id: any) => {
    surveyService
      .patchSurvey(id, {
        status: "active",
      })
      .then((data) => {
        setModal(false);
        reload();
      });
  };

  const mins = Math.floor(time / count / 60);
  const secs = Math.floor((time / count) % 60);
  const height = (window.innerHeight * (isMobile ? 70 : 80)) / 100;
  return (
    <Box
      sx={{
        width: isTablet ? "95%" : isMobile ? "90%" : "935px",
        // height: isTablet ? "auto" : isMobile ? "95%" : "1000px",
        padding: "20px",
        height: isMobile ? height : "80vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 16px 24px 4px rgba(90, 82, 128, 0.12)",
            borderRadius: "26px",
            padding: "26px 36px",
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: isMobile ? "24px" : "18px",
              lineHeight: isMobile ? "30px" : "40px",
            }}
          >
            {languages[language].SurveyStatistics}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "unset" : "100px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <FastIcon name={"circleBlue"} />
                {languages[language].RespondentsCount}
              </Box>
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "40px",
                }}
              >
                {count}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <FastIcon name={"circleBlue"} />
                {languages[language].SpentMoney}
              </Box>
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "40px",
                }}
              >
                {count * (survey?.money ?? 0)}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <FastIcon name={"circleBlue"} />
                {languages[language].AverageTime}
              </Box>
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "40px",
                }}
              >
                {mins > 0 ? mins + " мин " : ""}
                {secs > 0 ? secs + " сек" : ""}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: isMobile ? "flex" : "grid",
            gridTemplateColumns: "1fr 1fr",
            gridAutoRows: "minmax(100px, auto)",
            gridGap: "24px",
            flexDirection: isMobile ? "column" : "unset",
            alignItems: isMobile ? "stretch" : "start",
          }}
        >
          {categories.map((category) => (
            <AnalysisCard survey_id={id} key={category} category={category} />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "20px",
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: "center",
          width: "100%",
          paddingTop: "24px",
        }}
      >
        <FastButton
          variant={"contained"}
          click={() => {
            deleteSurvey(id);
          }}
          style={{
            marginRight: isMobile ? "unset" : "24px",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: "red",
            backgroundColor: "#FFFFFF",
            boxShadow:
              "0px 2px 5px rgba(60, 66, 87, 0.04), 0px 0px 0px 1px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.06)",
            border: "none",
          }}
        >
          {languages[language].DeleteSurvey}
        </FastButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "20px",
          }}
        >
          {status === "active" ? (
            <FastButton
              variant={"contained"}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
              }}
              click={() => {
                stopSurvey(id);
              }}
            >
              {languages[language].StopSurvey}
            </FastButton>
          ) : status === "stopped" ? (
            <FastButton
              variant={"contained"}
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "24px",
              }}
              click={() => {
                startSurvey(id);
              }}
            >
              {languages[language].RestartSurvey}
            </FastButton>
          ) : (
            <></>
          )}
          <FastButton
            variant={"contained"}
            style={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "24px",
              background: "#73A9C2",
              border: "unset",
            }}
            click={() => {
              surveyService.SendInvitations(id);
            }}
          >
            {languages[language].SendInvitation}
          </FastButton>
          <FastButton
            variant={"contained"}
            style={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "24px",
              background: "#73A9C2",
              border: "unset",
            }}
            click={() => {
              surveyService.downloadFile(id, language);
            }}
          >
            {languages[language].DownloadSurveyAnalysis}
          </FastButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyInfo;
