import { Box } from "@mui/material";
import FastButton from "../../UI/FastButton";
import FastInput from "../../UI/FastInput";
import Countdown from "react-countdown";
import { useModal } from "../../../hooks/useModal";
import { useEffect, useState } from "react";
import { dataService } from "../../../services/getLogin";
import useUser from "../../../hooks/useUser";
import useLoading from "../../../hooks/useLoading";
import { useNavigate } from "react-router-dom";
import { languages, phone_verify_errors } from "../../../Languages/languages";
import useLang from "../../../hooks/useLang";
import useMobile from "../../../hooks/useMobile";
import { notifications } from "@mantine/notifications";
import { Loader } from "@mantine/core";

export const PhoneVerify = ({
  phone_number,
  resendSMS,
  navigate,
}: {
  phone_number: string;
  resendSMS?: () => void;
  navigate: () => void;
}) => {
  const { setModal } = useModal();
  const [targetDate, setTargetDate] = useState(Date.now() + 30000);
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
  const [showResend, setShowResend] = useState(true);
  const { setUserData } = useUser();
  const { setLoading } = useLoading();
  const [code, setCode] = useState<string>("");
  const { language } = useLang();
  const { isMobile } = useMobile();
  const [error, setError] = useState<string>("");
  const [isDisabled, SetDisabled] = useState<boolean>(false);

  const handleResendClick = () => {
    if (targetDate > Date.now()) return;
    setShowResend(false);
    setCurrentTimeIndex(currentTimeIndex + 1);
    setTargetDate(Date.now() + 30000);
    if (resendSMS) resendSMS();
  };

  const renderer = ({ seconds, completed }: any) => {
    if (completed) {
      setShowResend(true);
      return <span onClick={handleResendClick}>0</span>;
    } else {
      return <span>{seconds}</span>;
    }
  };

  const submit = (phone_number: string, code: string) => {
    SetDisabled(true);
    dataService
      .passwordVerify({ phone_number: phone_number, code: code }, language)
      .then((response) => {
        SetDisabled(false);
        if ("access_token" in response) {
          window.localStorage.setItem("token", response.access_token);
          dataService.getData().then((el) => {
            setError("");
            setUserData(el);
            setLoading(false);
            setModal(false);
            navigate();
          });
        } else {
          notifications.show({
            message: response.message,
            autoClose: 10000,
            styles: (theme) => ({
              root: {
                backgroundColor: "#73A9C2",
                borderColor: "#73A9C2",
                "&::before": { backgroundColor: theme.white },
              },
              color: "red",
              description: { color: theme.white },
              closeButton: {
                color: theme.white,
                "&:hover": { backgroundColor: theme.colors.blue[7] },
              },
            }),
          });
        }
      })
      .catch(() => {
        SetDisabled(false);
        setError(phone_verify_errors[language].invalid_code);
      });
  };

  return (
    <Box
      sx={{
        maxWidth: isMobile ? "auto" : "500px",
      }}
    >
      <Box
        sx={{
          paddingTop: "16px",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
        }}
      >
        {languages[language].PhoneVerifyCodeTextPart1} +
        {phone_number.slice(0, 1)} ({phone_number.slice(1, 4)}){" "}
        {phone_number.slice(4, 7)} {phone_number.slice(7)}{" "}
        {languages[language].PhoneVerifyCodeTextPart2}
      </Box>
      <Box
        sx={{
          padding: "26px 0 16px 0",
          fontWeight: 300,
          fontSize: "12px",
          lineHeight: "15px",
        }}
      >
        Код
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "20px",
          alignItems: isMobile ? "start" : "center",
        }}
      >
        <FastInput
          style={{
            width: "276px",
          }}
          value={code}
          type="number"
          change={(value: any) => setCode(value.target.value)}
          inputProps={{ maxLength: 4 }}
          InputProps={{
            sx: {
              borderRadius: 3,
              backgroundColor: "#FFFFFF",
            },
          }}
          error={error !== ""}
          helperText={error}
        />
        {resendSMS ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <Box
              sx={{
                padding: isMobile ? "0px" : "0 6px 0 16px",
                fontWeight: 400,
                fontSize: "14px",
                opacity: 0.5,
                width: "45px",
              }}
            >
              <Countdown
                key={currentTimeIndex}
                date={targetDate}
                renderer={renderer}
              />{" "}
              сек
            </Box>
            <Box
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                opacity: 0.8,
                color: "#000",
                textDecorationLine: "underline",
                textUnderlineOffset: "3px",
                cursor: "pointer",
              }}
              onClick={showResend ? handleResendClick : undefined}
            >
              {languages[language].ResendCode}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "30px",
        }}
      >
        <FastButton
          click={() => {
            setLoading(false);
            setModal(false);
          }}
          style={{
            padding: "8px 30px",
            fontSize: "14px",
            border: "none",
            background: "none",
            opacity: 0.5,
          }}
        >
          {languages[language].Back}
        </FastButton>
        <FastButton
          type="submit"
          variant={"contained"}
          style={{
            padding: "8px 30px",
            fontSize: "14px",
          }}
          click={() => submit(phone_number, code)}
        >
          {isDisabled ? (
            <Loader color="white" size="1.5rem" />
          ) : (
            languages[language].Next
          )}
        </FastButton>
      </Box>
    </Box>
  );
};
