import axios from "axios";
import { CreateTransactionsRequest } from "./requests/CreateTransactionsRequest";
import { DownloadTransactionRequest } from "./requests/DownloadTransactionRequest";
import { PaymentRequest } from "./requests/PaymentRequest";
import { BalanceResponse } from "./responses/BalanceResponse";
import { CountTransactionsResponse } from "./responses/CountTransactions";
import { CreateInvoiceResponse } from "./responses/CreateInvoiceResponse";
import { ListTransactionsResponse } from "./responses/ListTransactions";

const axiosApiInstance = axios.create();

let headers = (lang: string = "rus") => ({
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    Accept: "application/json",
    Lang: lang,
  },
});

class BalanceService {
  async get() {
    return axiosApiInstance
      .get<BalanceResponse>("https://api.pikirlab.kz/wallet", headers())
      .then((response) => response.data);
  }

  async createInvoice(data: PaymentRequest, language: string) {
    return axiosApiInstance
      .post<CreateInvoiceResponse>(
        "https://api.pikirlab.kz/wallet/invoices/create",
        data,
        headers(language)
      )
      .then((response) => response.data);
  }

  async updateInvoice(data: PaymentRequest, language: string) {
    return axiosApiInstance
      .post<CreateInvoiceResponse>(
        "https://api.pikirlab.kz/wallet/invoices/create",
        data,
        headers(language)
      )
      .then((response) => response.data);
  }

  async listTransactions(status?: string) {
    return axiosApiInstance
      .get<ListTransactionsResponse[]>(
        `https://api.pikirlab.kz/transactions${
          status ? "?status=" + status : ""
        }`,
        headers()
      )
      .then((response) => response.data);
  }

  async CountTransactions(status?: string) {
    return axiosApiInstance
      .get<CountTransactionsResponse>(
        `https://api.pikirlab.kz/transactions/count${
          status ? "?status=" + status : ""
        }`,
        headers()
      )
      .then((response) => response.data);
  }

  async AcceptTransaction(transaction_id: string) {
    return (
      await axiosApiInstance.post(
        "https://api.pikirlab.kz/transactions/accept",
        { transaction_id: transaction_id },
        headers()
      )
    ).data;
  }

  async RejectTransaction(transaction_id: string) {
    return (
      await axiosApiInstance.post(
        "https://api.pikirlab.kz/transactions/reject",
        { transaction_id: transaction_id },
        headers()
      )
    ).data;
  }

  async CreateTransactions(data: CreateTransactionsRequest) {
    return (
      await axiosApiInstance.post(
        "https://api.pikirlab.kz/transactions",
        data,
        headers()
      )
    ).data;
  }

  async ListAccountTransactions(account_id: string) {
    return (
      await axiosApiInstance.get<ListTransactionsResponse[]>(
        `https://api.pikirlab.kz/accounts/${account_id}/transactions`,
        headers()
      )
    ).data;
  }

  async DownloadTransactions(data: DownloadTransactionRequest) {
    return axiosApiInstance
      .post("https://api.pikirlab.kz/transactions/download", data, headers())
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "users.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
}

export const balanceService = new BalanceService();
