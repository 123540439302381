import axios from "axios";
import { Language } from "../context/LangProvider";
import { SetUserDataRequest } from "./requests/SetUserDataRequest";
import { ListUsersResponse } from "./responses/ListUsersResponse";
import { LoginResponse } from "./responses/LoginResponse";
import { UpdateUserRequest } from "./requests/UpdateUserRequest";

const axiosApiInstance = axios.create();

let headers = {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    Lang: "",
  },
};

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

class UserService {
  async GetUser() {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .get<LoginResponse>("https://api.pikirlab.kz/user", headers)
      .then((response) => response.data);
  }

  async GetUserData(account_id: string) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .get<LoginResponse>(`https://api.pikirlab.kz/user/${account_id}`, headers)
      .then((response) => response.data);
  }

  async UpdateUser(data: UpdateUserRequest) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .patch(`https://api.pikirlab.kz/user`, data, headers)
      .then((response) => response.data);
  }

  async SetUserData(data: SetUserDataRequest, language: Language) {
    await sleep(1000);
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    headers.headers.Lang = language;
    return axiosApiInstance
      .post(`https://api.pikirlab.kz/user`, data, headers)
      .then((response) => response.data);
  }

  async ListUsers(page?: number, limit?: number) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .get<ListUsersResponse[]>(
        `https://api.pikirlab.kz/users?${
          page !== undefined ? "page=" + page + "&" : ""
        }${limit !== undefined ? "limit=" + limit : ""}`,
        headers
      )
      .then((response) => response.data);
  }

  async downloadFile() {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .get(`https://api.pikirlab.kz/users/download`, headers)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "users.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
}

export const userService = new UserService();
