import { Badge, Box, Flex, Text } from "@mantine/core";
import { useQuery } from "react-query";
import { FC } from "react";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { balanceService } from "../../services/Balance";
import FastIcon from "../UI/FastIcon";
import { Transaction } from "../../models/transaction";
import { ListTransactionsResponse } from "../../services/responses/ListTransactions";

interface Props {
  data: ListTransactionsResponse[] | undefined;
}

export const ListTransactions: FC<Props> = ({ data }) => {
  const { language } = useLang();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
      }}
    >
      {data === undefined || data.length === 0 ? (
        <Box
          sx={{
            width: 400,
            height: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <FastIcon name="cards" />
          <Box
            sx={{
              marginTop: "16px",
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            {languages[language].Left_th}
          </Box>
          <Box
            sx={{
              marginTop: "1px",
              textAlign: "center",
              marginLeft: "20px",
              fontSize: "20px",
            }}
          >
            {languages[language].Left_fo}
          </Box>
        </Box>
      ) : (
        data?.map((transaction) => {
          const dateObj = new Date(transaction.created_at);
          const month = dateObj.getUTCMonth() + 1;
          const formattedDate = `${dateObj.getUTCDate()}/${
            month < 10 ? 0 : ""
          }${month}/${dateObj.getUTCFullYear()}, ${dateObj.getHours()}:${dateObj.getMinutes()}`;
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: transaction.kassa_link ? "pointer" : "unset",
                "&:hover": {
                  boxShadow: transaction.kassa_link
                    ? "0px 0px 20px 0.5px #73A9C275"
                    : "unset",
                },
                padding: "16px",
                borderRadius: "16px",
              }}
              onClick={() => {
                if (
                  transaction.status !== "pending" &&
                  transaction.status !== "accepted"
                )
                  return;
                window.open(transaction.kassa_link, "_blank", "noreferrer");
              }}
            >
              <>
                <Flex direction={"column"}>
                  <Box
                    sx={{
                      fonWweight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#333333",
                      paddingBottom: "2px",
                    }}
                  >
                    {transaction.title[language]}
                  </Box>
                  {transaction.kassa_link && (
                    <Text size="xs" color="dimmed">
                      click here to get your money
                    </Text>
                  )}
                </Flex>
                <Box
                  sx={{
                    fonWweight: "400",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#1A1A1A",
                    mixBlendMode: "normal",
                    opacity: 0.4,
                  }}
                >
                  {formattedDate}
                </Box>
              </>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={(theme) => ({
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px",
                    lineHeight: "120%",
                    color:
                      transaction.amount > 0 ? "#73A9C2" : theme.colors.red[5],
                  })}
                >
                  {transaction.amount > 0 ? "+" : null}
                  {transaction.amount}
                </Box>
                <Badge
                  size="xs"
                  sx={{
                    background:
                      transaction.status === "waiting"
                        ? "#73A9C2"
                        : transaction.status === "pending"
                        ? "orange"
                        : transaction.status === "accepted"
                        ? "green"
                        : "red",
                    color: "white",
                  }}
                >
                  {languages[language][transaction.status]}
                </Badge>
              </Box>
            </Box>
          );
        })
      )}
    </Box>
  );
};
