import { FC, useEffect, useState } from "react";
import { useModal } from "../../../hooks/useModal";
import { useModalContent } from "../../../hooks/useModalContent";
import { useNavigate } from "react-router-dom";
import useLang from "../../../hooks/useLang";
import { languages } from "../../../Languages/languages";
import { SignupRequest } from "../../../services/requests/SignupRequest";
import { authService } from "../../../services/auth";
import { useForm } from "@mantine/form";
import { useMutation } from "react-query";
import { ErrorResponse } from "../../../models/error";
import { UserFields } from "./UserFields";
import { PhoneVerify } from "./PhoneVerify";
import { AccountFields } from "./AccountFields";

const errors = {
  kaz: {
    account_exists: "Бұндай аккаунт бар",
    phone_number_incorrect: "Телефон нөмірі қате",
    password_incorrect: "Әлсіз құпиясөз",
    passwords_not_same: "Құпиясөздер бірдей емес",
    birth_date: "Қызмет 18 жастан асқан адамдарға қол жетімді",
  },
  rus: {
    account_exists: "Аккаунт уже существует",
    phone_number_incorrect: "Нестандартный номер телефона",
    password_incorrect: "Cлабый пароль",
    passwords_not_same: "Пароли не совпадают",
    birth_date: "Сервис доступен для лиц старше 18 лет",
  },
};

interface Data {
  account: {
    phone_number: string;
    accept_date: Date;
    accepted: boolean;
    password: string;
    confirm_password: string;
  };
  user: {
    first_name: string;
    last_name: string;
    birth_date: Date | null;
    promo?: string;
  };
}

export const Form: FC = () => {
  const { language } = useLang();
  const navigate = useNavigate();
  const { setModal } = useModal();
  const { setModalContent } = useModalContent();
  const [step, setStep] = useState<number>(0);

  const form = useForm<Data>({
    clearInputErrorOnChange: false,
    initialValues: {
      account: {
        phone_number: "",
        password: "",
        confirm_password: "",
        accepted: false,
        accept_date: new Date(),
      },
      user: {
        last_name: "",
        first_name: "",
        birth_date: null,
        promo: undefined,
      },
    },
    validate: {
      account: {
        phone_number: (value, values) => {
          if (values.account.phone_number === "") return;
          return /^77\d{9}$/.test(value)
            ? null
            : errors[language].phone_number_incorrect;
        },
        password: (value, values) => {
          if (values.account.password === "") return;
          return /^(?=.*[a-zA-Z0-9\s\W]).{8,}$/.test(value)
            ? null
            : errors[language].password_incorrect;
        },
        confirm_password: (value, values) => {
          return value !== values.account.password
            ? errors[language].passwords_not_same
            : null;
        },
      },
      user: {
        birth_date: (value) => {
          if (value)
            return isOver18(value) ? null : errors[language].birth_date;
        },
      },
    },
  });

  useEffect(() => {
    form.validate();
  }, [form.values]);

  const mutation = useMutation(
    async (data: SignupRequest) => {
      return await authService.Signup(data);
    },
    {
      onSuccess: (response, request) => {
        VerifyAccountModal(request.account.phone_number);
      },
      onError: (errorResponse: ErrorResponse) => {
        if (errorResponse.response.status === 401) {
          window.localStorage.removeItem("token");
          navigate("/login");
        }
        form.setFieldError(
          "account.phone_number",
          errors[language].account_exists
        );
      },
    }
  );

  const signup = async (data: Data) => {
    mutation.mutate({
      account: {
        phone_number: data.account.phone_number,
        accept_date: data.account.accept_date,
        accepted: data.account.accepted,
        password: data.account.password,
      },
      user: {
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        birth_date: data.user.birth_date!,
      },
      promo: data.user.promo,
    });
  };

  const VerifyAccountModal = (phone_number: string) => {
    setModal(true);
    setModalContent({
      title: languages[language].ConfirmAccount,
      content: (
        <PhoneVerify
          phone_number={phone_number}
          resendSMS={() => signup(form.values)}
          navigate={() => {
            navigate("/dashboard");
          }}
        />
      ),
    });
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={form.onSubmit((values) => signup(values))}
    >
      {step < 1 ? (
        <UserFields
          data={form.values.user}
          errors={form.errors}
          SetData={(data) => {
            form.setValues({ ...form.values, user: data });
          }}
          next={() => setStep(1)}
        />
      ) : (
        <AccountFields
          data={form.values.account}
          errors={form.errors}
          SetData={(data) => {
            form.setValues({ ...form.values, account: data });
          }}
          previous={() => setStep(0)}
          loading={!mutation.isSuccess && mutation.isLoading}
        />
      )}
    </form>
  );
};

function isOver18(birth_date: Date): boolean {
  const currentDate = new Date();
  const age = currentDate.getFullYear() - birth_date.getFullYear();

  if (
    currentDate.getMonth() < birth_date.getMonth() ||
    (currentDate.getMonth() === birth_date.getMonth() &&
      currentDate.getDate() < birth_date.getDate())
  ) {
    return age - 1 >= 18;
  } else {
    return age >= 18;
  }
}
