import React, { createContext, useState } from "react";
import { LoginResponse } from "../services/responses/LoginResponse";

interface IUserContext {
  userData: LoginResponse;
  setUserData: (user: LoginResponse) => void;
}

export const UserContext = createContext<IUserContext>({
  userData: {
    account: {
      id: "",
      phone_number: "",
      role: "USER",
      verified: false,
      user_id: "",
    },
    user: {
      id: "",
      first_name: "",
      last_name: "",
      email: {
        id: "",
        email: "",
        verified: false,
      },
      birth_date: "",
      verified: false,
    },
    access_token: "",
  },
  setUserData: () => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userData, setData] = useState<LoginResponse>({
    account: {
      id: "",
      phone_number: "",
      role: "USER",
      verified: false,
      user_id: "",
    },
    user: {
      id: "",
      first_name: "",
      last_name: "",
      email: {
        id: "",
        email: "",
        verified: false,
      },
      birth_date: "",
      verified: false,
    },
    access_token: "",
  });

  const setUserData = (moduleState: LoginResponse) => {
    setData(moduleState);
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
