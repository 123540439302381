import React, { createContext, useState } from "react";

export const ModalContentContext = createContext<any>({});

export const ModalContentProvider = ({ children }: any) => {
  const [modalContent, setModalContentFun] = useState({
    title: "",
    content: null,
  });

  const setModalContent = (modalState: any) => {
    setModalContentFun(modalState);
  };

  return (
    <ModalContentContext.Provider
      value={{
        modalContent,
        setModalContent,
      }}
    >
      {children}
    </ModalContentContext.Provider>
  );
};
