import { TextField } from "@mui/material";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { FC, ReactElement, useState } from "react";
import useLang from "../../../hooks/useLang";
import { languages } from "../../../Languages/languages";
import { ResetPasswordRequest } from "../../../services/requests/ResetPasswordRequest";
import FastInput, { FastInputProps } from "../../UI/FastInput";
import PhoneInput from "./PhoneInput";
import { Error, InputFieldData } from "./types";

const TextInput: FC<FastInputProps> = (props: FastInputProps): ReactElement => {
  return (
    <FastInput
      InputProps={{
        sx: {
          borderRadius: 3,
          backgroundColor: "#FFFFFF",
        },
      }}
      {...props}
    />
  );
};

const InputForm: React.FC<{
  el: InputFieldData;
  data: ResetPasswordRequest;
  setData: (data: ResetPasswordRequest) => void;
  error: Error;
  setError: (error: Error) => void;
}> = ({ el, data, setData, error, setError }) => {
  const { language } = useLang();
  const [password, setPassword] = useState<string>("");
  switch (el.type) {
    case "code":
      return (
        <TextInput
          label={el.label}
          style={{
            marginBottom: "18px",
            borderRadius: "20px",
          }}
          onChange={(value: any) =>
            setData({ ...data, code: value.target.value })
          }
          value={data.code}
          inputProps={{ maxLength: 4 }}
        />
      );
    case "new_password":
      return (
        <TextInput
          type={"password"}
          label={languages[language].Password_new}
          style={{
            marginBottom: "18px",
            borderRadius: "20px",
          }}
          value={data.password ?? ""}
          change={(value: any) => {
            setData({ ...data, password: value.target.value });
          }}
        />
      );
    case "confirm_password":
      return (
        <TextInput
          type={"password"}
          error={password !== data.password}
          label={languages[language].Sign_in_PassVer}
          style={{
            marginBottom: "18px",
          }}
          value={password}
          change={(value: any) => {
            setPassword(value.target.value);
            setError({ ...error, 1: value.target.value !== data.password });
          }}
        />
      );
    case "phone_number":
      return (
        <PhoneInput
          value={data.phone_number ?? ""}
          onChange={(value: string) => {
            setData({ ...data, phone_number: value });
            setError({ ...error, 0: !matchIsValidTel(value || "") });
          }}
          style={{
            marginBottom: "18px",
            borderRadius: "10px",
            background: "white",
          }}
          error={!matchIsValidTel(data.phone_number || "")}
          InputProps={{
            sx: {
              borderRadius: 3,
            },
          }}
        />
      );
    default:
      return <TextField></TextField>;
  }
};

export default InputForm;
