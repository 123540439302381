import { Badge, Flex, Paper, Text, useMantineTheme } from "@mantine/core";
import { FC } from "react";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { Transaction } from "../../models/transaction";

const colors = {
  waiting: "#73A9C2",
  pending: "orange",
  accepted: "green",
  rejected: "red",
};

interface Props extends Transaction {}

export const TransactionCard: FC<Props> = (transaction) => {
  const { language } = useLang();
  const theme = useMantineTheme();
  const dateObj = new Date(transaction.created_at);
  const month = dateObj.getUTCMonth() + 1;
  const formattedDate = `${dateObj.getUTCDate()}/${
    month < 10 ? 0 : ""
  }${month}/${dateObj.getUTCFullYear()}, ${dateObj.getHours()}:${dateObj.getMinutes()}`;

  return (
    <Paper h="100px" bg="white" radius="md" withBorder p="md">
      <Flex justify="space-between">
        <Flex direction="column" gap="md">
          <Text fw={400} size="14px" color="#333333">
            {transaction.title[language]}
          </Text>
          <Text
            fw={400}
            size="14px"
            color="#333333"
            opacity={0.4}
            style={{ mixBlendMode: "normal" }}
          >
            {formattedDate}
          </Text>
        </Flex>
        <Flex direction="column" align="end" gap="md">
          <Text
            fw={700}
            ta="center"
            size="20px"
            lh="120%"
            color={transaction.amount > 0 ? "#73A9C2" : theme.colors.red[5]}
          >
            {transaction.amount > 0 ? "+" : null}
            {transaction.amount}
          </Text>
          <Badge size="xs" c="white" bg={colors[transaction.status]}>
            {languages[language][transaction.status]}
          </Badge>
        </Flex>
      </Flex>
    </Paper>
  );
};
