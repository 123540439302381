import { Box } from "@mui/material";
import styled from "@emotion/styled";
import useLoading from "../../hooks/useLoading";

const LayoutSucces = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#FBF7F4",
}));

const Layout = ({ children, ...props }: any) => {
  return <LayoutSucces {...props}>{children}</LayoutSucces>;
};

export default Layout;
