import { ActionIcon, Badge, Button, Flex, Text, Tooltip } from "@mantine/core";
import { Box } from "@mui/material";
import { IconDownload, IconEdit, IconTrash } from "@tabler/icons-react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { map } from "lodash";
import {
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_Virtualizer,
} from "mantine-react-table";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type UIEvent,
} from "react";
import { languages } from "../../Languages/languages";
import useLang from "../../hooks/useLang";
import useMobile from "../../hooks/useMobile";
import useTablet from "../../hooks/useTablet";
import { accountService } from "../../services/AccountService";
import {
  Account,
  ListUsersResponse,
} from "../../services/responses/ListUsersResponse";
import { userService } from "../../services/UserService";

const colors: Record<string, string> = {
  admin: "blue",
  manager: "cyan",
  user: "pink",
  active: "green",
  inactive: "red",
};

const columns: MRT_ColumnDef<Account>[] = [
  {
    accessorKey: "id",
    size: 200,
    header: "ID",
  },
  {
    accessorKey: "phone_number",
    size: 150,
    header: "Номер телефона",
  },
  {
    accessorKey: "role",
    size: 100,
    header: "Роль",
    Cell: ({ cell }) => (
      <Badge
        color={colors[cell.getValue<string>().toLowerCase()]}
        variant={"outline"}
      >
        {cell.getValue<string>()}
      </Badge>
    ),
  },
  {
    accessorKey: "status",
    size: 100,
    header: "Статус",
    Cell: ({ cell }) => (
      <Badge
        color={colors[cell.getValue<string>().toLowerCase()]}
        variant={"outline"}
      >
        {cell.getValue<string>()}
      </Badge>
    ),
  },
  {
    accessorFn: (originalRow) => new Date(originalRow.created_at),
    id: "created_at",
    size: 250,
    header: "Создан",
    filterVariant: "date-range",
    Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(),
  },
  {
    accessorFn: (originalRow) => originalRow.verified?.toString(),
    id: "verified",
    size: 140,
    header: "Верифицирован",
    filterVariant: "checkbox",
  },
  {
    accessorKey: "sms",
    size: 180,
    header: "СМС",
    filterVariant: "range",
    filterFn: "between",
  },
  {
    accessorKey: "money",
    size: 180,
    header: "Деньги",
    filterVariant: "range",
    filterFn: "between",
    Cell: ({ cell }) => <Text>₸ {cell.getValue<string>()}</Text>,
  },
];

const fetchSize = 25;

interface map {
  id: string;
  value: boolean;
}

const joinArrays = (arr1: map[], arr2: map[]): map[] => {
  const map = new Map<string, map>();

  [...arr1, ...arr2].forEach((item) => {
    if (!map.has(item.id)) {
      map.set(item.id, item);
    }
  });

  return Array.from(map.values());
};

export const UserList: FC = () => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const { language } = useLang();
  const [deleted, setDeleted] = useState<Map<string, boolean>>(new Map());
  const [freezed, setFreezed] = useState<map[]>([]);
  const { isMobile } = useMobile();
  const { isTablet } = useTablet();

  const HandleDisable = (status: string) => {
    accountService
      .UpdateStatus(
        status,
        Object.keys(rowSelection).filter((id) => rowSelection[id] === true)
      )
      .then(() => {
        const selected = Object.keys(rowSelection).filter(
          (id) => rowSelection[id] === true
        );
        const unique = joinArrays(
          selected.map((str) => {
            return { id: str, value: status === "inactive" };
          }),
          freezed
        );
        setFreezed(unique);
      });
  };

  const HandleDelete = () => {
    accountService
      .Delete(
        Object.keys(rowSelection).filter((id) => rowSelection[id] === true)
      )
      .then(() => {
        const deletedMap = new Map(
          Object.keys(rowSelection).map((str) => [str, true])
        );
        setDeleted(deletedMap);
      });
  };

  const { data, fetchNextPage, isError, isFetching, isLoading } =
    useInfiniteQuery<ListUsersResponse>({
      queryKey: ["table-data", columnFilters, globalFilter, sorting],
      queryFn: async ({ pageParam = 1 }) => {
        const url = new URL("https://api.pikirlab.kz/users");
        url.searchParams.set("page", `${pageParam}`);
        url.searchParams.set("limit", `${fetchSize}`);

        url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        // url.searchParams.set("globalFilter", globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

        const response = await fetch(url.href, {
          headers: new Headers({
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            Accept: "application/json",
          }),
        });
        const json = (await response.json()) as ListUsersResponse;
        return json;
      },
      getNextPageParam: (_lastGroup, groups) => groups.length + 1,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

  const flatData = useMemo(
    () =>
      data?.pages.flatMap((page) => {
        const data = page.data.filter(
          (account) => deleted.get(account.id) !== true
        );
        return data.map((account) => {
          const arr = freezed.filter((item) => item.id === account.id);
          if (arr.length !== 0) {
            account.status = arr[0]?.value ? "inactive" : "active";
          }
          return account;
        });
      }) ?? [],
    [data, deleted, freezed]
  );

  const totalDBRowCount =
    (data?.pages?.[0]?.meta?.count ?? 0) - deleted.keys.length;
  const totalFetched = flatData.length;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          totalFetched < totalDBRowCount &&
          totalDBRowCount >= fetchSize
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  //scroll to top of table when sorting or filters change
  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, columnFilters, globalFilter]);

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const mutation = useMutation(
    async () => {
      return await userService.downloadFile();
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        alert(JSON.stringify(error.response.data.error));
      },
    }
  );

  const table = useMantineReactTable({
    columns,
    data: flatData,
    enableColumnActions: false,
    enableColumnFilters: true,
    columnFilterDisplayMode: "subheader",
    initialState: {
      showColumnFilters: true,
      density: "xs",
    },
    enableTopToolbar: false,
    enablePagination: false,
    enableRowSelection: true,
    enableColumnVirtualization: true,
    enableRowVirtualization: true, //optional, but recommended if it is likely going to be more than 100 rows
    manualFiltering: true,
    manualSorting: true,
    mantineTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: { maxHeight: "90%", width: "1240px" }, //give the table a max height
      onScroll: (
        event: UIEvent<HTMLDivElement> //add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    mantineToolbarAlertBannerProps: {
      color: "red",
      children: "Error loading data",
    },
    autoResetPageIndex: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => HandleDelete()}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderBottomToolbarCustomActions: () => (
      <Box
        sx={{
          width: "100%",
          height: "200px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "5px",
        }}
      >
        <Text align="center">
          Fetched {totalFetched} of {totalDBRowCount} total rows.
        </Text>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Button
            color="green"
            loading={mutation.isLoading}
            onClick={() => {
              mutation.mutate();
            }}
            leftIcon={<IconDownload />}
          >
            {languages[language].Download}
          </Button>
          <Button
            color="red"
            onClick={() => {
              HandleDelete();
            }}
          >
            {languages[language].Delete}
          </Button>
          <Button
            variant="outline"
            color="red"
            onClick={() => {
              HandleDisable("inactive");
            }}
          >
            {languages[language].Disable}
          </Button>
          <Button
            variant="outline"
            color="white"
            onClick={() => {
              HandleDisable("active");
            }}
          >
            {languages[language].Enable}
          </Button>
        </Box>
      </Box>
    ),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      rowSelection,
    },
    getRowId: (row) => row.id,
    mantineTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () =>
        setRowSelection((prev) => ({
          ...prev,
          [row.id]: !prev[row.id],
        })),
      selected: rowSelection[row.id],
      sx: {
        cursor: "pointer",
      },
    }),
    rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerProps: { overscan: 10 },
  });

  const height = (window.innerHeight * (isMobile ? 70 : 80)) / 100;

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "1260px",
          overflowY: "auto",
          height: isTablet
            ? height.toString() + "px"
            : isMobile
            ? height.toString() + "px"
            : "550px",
          paddingTop: "24px",
          display: "flex",
          justifyContent: "center",
          borderTop: "1px solid #E0E0E0",
          marginTop: "24px",
        }}
      >
        <MantineReactTable table={table} />
      </Box>
    </React.Fragment>
  );
};
