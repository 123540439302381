import { Language } from "../context/LangProvider";

type LanguageDictionary = {
  [key in Language]: {
    [key: string]: string;
  };
};

export const signup_errors: LanguageDictionary = {
  rus: {
    name: "",
    surname: "",
    birth_date: "",
    promo: "",
    account_exists: "Аккаунт уже существует",
    phone_incorrect: "Нестандартный номер телефона",
    email_incorrect: "Нестандартный формат почты",
    password_incorrect: "Cлабый пароль",
    passwords_not_same: "Пароли не совпадают",
  },
  kaz: {
    name: "",
    surname: "",
    birth_date: "",
    promo: "",
    account_exists: "Бұндай аккаунт бар",
    phone_incorrect: "Телефон нөмірі қате",
    email_incorrect: "Пошта форматы қате",
    password_incorrect: "Әлсіз құпиясөз",
    passwords_not_same: "Құпиясөздер бірдей емес",
  },
};

export const signin_errors: LanguageDictionary = {
  rus: {
    account_not_found: "Аккаунт не найден",
    password_incorrect: "Или пароль указан неправильно",
  },
  kaz: {
    account_not_found: "Жүйеде бұндай аккаунт жоқ",
    password_incorrect: "Немесе құпиясөз қате терілді",
  },
};

export const phone_verify_errors: LanguageDictionary = {
  rus: {
    invalid_code: "Неправильный код",
  },
  kaz: {
    invalid_code: "Қате код",
  },
};

export const reset_password_errors: LanguageDictionary = {
  rus: {
    phone_number: "Аккаунт не найден",
    password_incorrect: "Или пароль указан неправильно",
  },
  kaz: {
    account_not_found: "Жүйеде бұндай аккаунт жоқ",
    password_incorrect: "Немесе құпиясөз қате терілді",
  },
};

export const languages: LanguageDictionary = {
  rus: {
    transactions: "Выплаты",
    add_transactions: "Начислить деньги",
    pending: "в обработке",
    waiting: "в рассмотрении",
    accepted: "принят",
    rejected: "отказан",
    Error: "Ошибка",
    FeedbackSuccess: "Успешно отправлен",
    EmptyFields: "Заполните все поля",
    Btn_Sing_in: "Войти",
    Logout: "Выйти",
    Btn_Sing_up: "Регистрация",
    Sign_in_head: "Войти в аккаунт",
    Btn_Create: "Создать аккаунт",
    Btn_Try_Now: "Попробовать сейчас",
    Btn_Forget_Pass: "Забыли пароль?",
    Btn_Start_Now: "Начать сейчас",
    Btn_Save: "Сохранить",
    Sign_in: "Войти в аккаунт",
    Sign_up: "Регистрация",
    Refer_code: "Ввести реферальный код",
    Sign_in_have: "Если у вас есть аккаунт, нажмите",
    Have: "Войти",
    Next: "Далее",
    Back: "Назад",
    ReturnBack: "Вернуться назад",
    Name: "Имя",
    Surname: "Фамилия",
    Mail: "Почта",
    Sign_in_PassVer: "Подвердить пароль",
    Password: "Пароль",
    Password_new: "Придумайте пароль",
    PhoneNumber: "Номер телефона",
    BankCardNumber: "Банковская карта",
    Birth_date: "Дата рождения",
    New_survey: "Новый Опрос",
    Forget_Pass_title: "Восстановление пароля",
    Forget_Pass_subtitle: "Аккаунт может быть восстановлен по смс коду",
    Landing_Main_Title: "Приветствуем вас на PIKIRLAB",
    Landing_Main_Subtitle:
      "сайт, где вы влияете на решения, отвечая на простые вопросы!",
    Landing_Second_Title:
      "Мы независимая казахстанская платформа для опросов общественного мнения",
    Landing_Second_Subtitle:
      "Мы помогаем людям делиться своим мнением, влиять на принятие решений и получить дополнительный заработок",
    Landing_Third_Title: "Начните зарабатывать в три простых шага: ",
    Landing_Third_first: "ЗАРЕГИСТРИРУЙТЕСЬ на сайте",
    Landing_Third_second: "ПРОЙДИТЕ опрос",
    Landing_Third_third: "ПОЛУЧИТЕ деньги!",
    Landing_Fourth_Title:
      "Зарегистрируйтесь сейчас, пройдите приветственный опрос и получите первые 150 баллов!",
    Landing_Fifth_Title: "Часто задаваемые вопросы",
    Landing_First_Body:
      "Приглашайте друзей и получите по 100 реферальных баллов за каждого",
    Landing_Second_Body: "Наши партнеры и компании, которые нам доверились",
    Landing_Third_Body: "Остались вопросы",
    Landing_Fourth_Body: "или хотите заказать исследование?",
    Landing_First_Footer: "Политика конфиденциальности",
    Landing_Second_Footer: "Пользовательское соглашение",
    Not_First_Title: "Страница не найдена",
    Not_Second_Title: "На главную страницу",
    AdminPanel: "Админ панель",
    CreateSurvey: "Новый опрос",
    Sidebar_Third: "Настройки профиля",
    Wallet: "Баланс",
    FinishedSurveys: "Завершенные опросы",
    Surveys: "Oпросы",
    Administrator: "Администраторы",
    Sidebar_Seven: "Всего опросов",
    Sidebar_Eight: "Черновые опросы",
    ActiveSurveys: "Активные опросы",
    Side_one: "Доступные опросы",
    Parameters: "Настройки",
    Analysis: "Общий анализ",
    Invite: "Реферальный код",
    Invite_f: "Пригласить друзей",
    Invite_text:
      "Поделитесь реферальным кодом с друзьями и получите 100 баллов за каждого полностью зарегистрированного пользователя. Максимум 10 пользователей",
    Survey_f: "Пока нет опросов :(",
    Survey_t:
      "Поздравляем! Вы успешно завершили все опросы. Следите за приглашениями — новые опросы ждут вас совсем скоро!",
    FeedBackCategory: "Тема вашего обращения",
    FeedBackPhoneNumber: "Укажите номер телефона",
    FeedBackEmail: "Укажите почту",
    FeedbackSend: "Отправить",
    FeedBackText: "Ваш вопрос",
    WalletAmount: "Накоплено баллов: ",
    BankCardPlaceHolder: "Введите номер банковской карты",
    Left_t: "История операции",
    Left_th: "Пока нет операций :(",
    Left_fo: "Поскорее заработайте свой первый балл и переведите себе на карту",
    Left_fi: "Баллы за опрос",
    Left_s: "На карту",
    Right_f: "Перевод баллов",
    Right_t: "Перевести на баланс:",
    Right_th: "Количество баллов",
    Right_fu: "Сохранить номер",
    Right_fi: "Вывести баллы",
    Right_s:
      "Минимальная сумма для вывода 500 баллов. Конвертация 1 балл = 1 тенге",
    Total_m: "Количество баллов за все время",
    Total_r: "Количество активных пользователей",
    Total_s: "Количество смс за все время",
    SurveyTitleKaz: "Введите название опроса на казахском",
    SurveyTitleRus: "Введите название опроса на русском",
    SurveyLinkRus: "Ссылка опрос на русском",
    SurveyLinkKaz: "Ссылка на опрос на казахском",
    SurveyRespondentsCount: "Цель охвата пользовтелей *",
    SurveyRespondentsCountPlaceHolder: "Введите число",

    FAQ: "FAQ",
    EnterPromo: "Введите реферальный код",

    SaveChanges: "Сохранить изменения?",
    SaveChangesPart1: "Вы поменяли данные в своем профиле.",
    SaveChangesPart2: "Если вы поменяли почту то не забудьте подтвердить ее.",
    SaveChangesPart3: `После того как вы нажмете кнопку "сохранить" ваши старые данные поменяются.`,

    EnterCode: "Введите код",
    Send: "Отправить",
    SavePassword: "Сохранить пароль",

    ConfirmAccount: "Подтвердите аккаунт",
    PhoneVerifyCodeTextPart1: "На ваш номер телефона",
    PhoneVerifyCodeTextPart2:
      "указанный до этого был отправлен 4 значный код.\n Пожалуйста укажите его",
    ResendCode: "Отправить код заново",

    Managers: "Менеджеры",
    NewManager: "Новый менеджер",
    RemoveMeneger: "Удалить менеджера",
    ConfirmDeleteManager: "Вы уверены что хотите удалить сотрудника?",

    Surveyfi: "Пол",
    Surveyse: "Семейное положение",
    Surveye: "Регион",
    Surveyn: "Количество людей в семье",
    Surveyt: "Дети до 18 лет",
    Surveyel: "Общий доход семьи",
    Surveytwe: "Занятость",
    Surveythi: "Уровень оброзования",
    Surveyfo: "Интересы",
    Surveyfif: "Наличие автомобиля",
    Surveysix: "Наличие недвижимости",

    balance: "баллов",

    SurveyImageDropZone: "Прикрепить обложку опроса",
    Deadline: "Дедлайн",
    Filter: "Фильтр для пользователей *",
    FilterName: "Название шаблона *",
    FilterNameInput: "Bведите название шаблона",
    FilterDelete: "Удалить шаблон",
    FilterDeleteConfirm: "Вы действительно хотите удалить шаблон?",
    SurveyMoney: "Баллы за прохождение",
    Appropriate: "Число подходящих респондентов: ",
    Cancel: "Отменить",
    Save_Survey: "Сохранить шаблон",
    Post: "Опубликовать",
    SurveyFilterAge: "Возраст *",
    SurveyMoneyPlaceHolder: "Укажите количество баллов",
    DeadlineDate: "Укажите срок",
    Add_category: "Добавить категорию",
    Delete_category: "Удалить категорию",
    Add_quest: "Новый вопроc",
    Question: "Вопрос *",
    Answer: "Ответ *",
    AnswerInput: "Введите ответ",
    Delete: "Удалить",
    Download: "Скачать",
    Disable: "Заморозить",
    Enable: "Восстановить",

    age: "Возраст",
    gender: "Пол",
    residence: "Местность проживания",
    country: "Страна проживания",
    region: "Регион",
    nationality: "Национальность",
    language: "Язык общаение",
    family_status: "Семейное положение",
    family_members_count: "Количество людей в семье",
    children_count: "Дети до 18 лет",
    total_income: "Общий доход семьи",
    financial_situation: "Финансовое положение",
    employment_status: "Статус занятости",
    work_obligation: "Занятость",
    ownership: "Что имеется из указанного",
    social_network: "Чем из перечисленного пользуются",

    questionsCount: "Вопросов",

    checkboxSignUpAccept: "Принимаю ",
    checkboxSignUpFirst: "пользовательское соглашение ",
    And: "и ",
    checkboxSignUpSecond: "политику конфиденциальности",

    SurveyStatistics: "Статистика опроса",
    SpentMoney: "Потрачено денег",
    AverageTime: "Среднее время",
    RespondentsCount: "Прошли",
    DeleteSurvey: "Удалить опрос",
    SendInvitation: "Отправить приглашение",
    StopSurvey: "Остановить опрос",
    RestartSurvey: "Восстановить опрос",
    DownloadSurveyAnalysis: "Скачать данные",
    ShowMore: "Показать еще",
    ShowLess: "Показать меньше",

    NotSelected: "Не выбрано",

    feedback_title: "Обратная связь",
    start_survey: "Пройти опрос",
  },
  kaz: {
    transactions: "Төлемдер",
    add_transactions: "Төлемдер еңгізу",
    pending: "өңделуде",
    waiting: "қаралуда",
    accepted: "қабылданды",
    rejected: "қабылданбады",
    Error: "Қателік",
    FeedbackSuccess: "Сәтті жіберілді",
    EmptyFields: "Барлық деректерді толтырыңыз",
    Btn_Sing_in: "Кіру",
    Logout: "Шығу",
    Sign_in_head: "Аккаунтқа кіру",
    Btn_Sing_up: "Тіркелу",
    Btn_Create: "Аккаунт жасау",
    Btn_Forget_Pass: "Құпиясөзді ұмыттыңыз ба?",
    Btn_Try_Now: "Қазір көріңіз",
    Btn_Start_Now: "Қазір бастаңыз",
    Btn_Save: "Сақтау",
    Sign_in: "Аккаунтқа кіру",
    Sign_up: "Тіркелу",
    Sign_in_have: "Аккаунтыңыз бар болса, басыңыз",
    Have: "Кіру",
    Next: "Келесі",
    Back: "Артқа қайту",
    ReturnBack: "Артқа қайту",
    Name: "Аты",
    Surname: "Тегі",
    Mail: "Пошта",
    Sign_in_PassVer: "Құпиясөзді қайталаңыз",
    Password_new: "Құпиясөзді ойлап табыңыз",
    PhoneNumber: "Телефон нөмірі",
    BankCardNumber: "Банк картасы",
    Birth_date: "Туған күні",
    Password: "Құпиясөз",
    New_survey: "Жана Сауалнама",
    Forget_Pass_title: "Құпиясөзді қалпына келтіру",
    Forget_Pass_subtitle:
      "Есептік жазбаны смс код арқылы қалпына келтіруге болады",
    Refer_code: "Рефералды кодты енгізу",
    Landing_Main_Title: "PIKIRLAB-қа қош келдіңіз! ",
    Landing_Main_Subtitle:
      "Осы сайтта, сіз оңай сұрақтарға жауап беру арқылы шешімдерге әсер етесіз!",
    Landing_Second_Title:
      "Біз қоғамдық пікірді зерттеуге арналған тәуелсіз қазақстандық платформамыз",
    Landing_Second_Subtitle:
      "Біз адамдарға өз пікірлерімен бөлісуге, қоршаған ортаға әсер етуге және қосымша табыс табуға көмектесеміз",
    Landing_Third_Title: "Табыс табуды үш әрекеттен бастаңыз: ",
    Landing_Third_first: "Сайтқа ТІРКЕЛІҢІЗ",
    Landing_Third_second: "Сауалнамаға ҚАТЫСЫҢЫЗ",
    Landing_Third_third: "Ақшаңызды АЛЫҢЫЗ!",
    Landing_Fourth_Title:
      "Қазір тіркеліңіз, бірінші сауалнамаға қатысыңыз және алғашқы 150 ұпай жинаңыз!",
    Landing_Fifth_Title: " Жиі қойылатын сұрақтаp",
    Landing_First_Body:
      "Достарыңызды шақырыңыз және әрқайсысынан рефералдық 100 ұпай алыңыз",
    Landing_Second_Body: "Бізге сенген серіктестеріміз бен компанияларымыз",
    Landing_Third_Body: "Сұрақтарыңыз бар ма",
    Landing_Fourth_Body: "немесе зерттеуге тапсырыс бергіңіз келе ме?",
    Landing_First_Footer: "Құпиялылық саясаты",
    Landing_Second_Footer: "Қолдану ережелері",
    Not_First_Title: "Бет табылмады",
    Not_Second_Title: "Бастапқы бетке өту",
    AdminPanel: "Админ панель",
    CreateSurvey: "Сауалнаманы құру",
    Sidebar_Third: "Профильді баптау",
    Wallet: "Теңгерім",
    Surveys: "Cауалнамалар",
    FinishedSurveys: "Аяқталған сауалнамалар",
    Administrator: "Әкімші",
    Sidebar_Seven: "Жалпы сауалнамалар",
    Sidebar_Eight: "Өрескел сауалнамалар",
    ActiveSurveys: "Белсенді сауалнамалар",
    Side_one: "Қолжетімді сауалнамалар",
    Parameters: "Параметрлер",
    Analysis: "Жалпы анализ",
    Invite: "Рефералды код",
    Invite_f: "Достарды шақыру",
    Invite_text:
      "Достарыңызбен рефералды кодыңызбен бөлісіп, әр толық тіркелген қолданушы үшін 100 балл алыңыз. Ең көбі 10 пайдаланушы",
    Survey_f: "Құттықтаймыз! Сіз барлық сауалнамаларды сәтті аяқтадыңыз.",
    Survey_t:
      "Шақыруларға назар аударыңыз-жаңа сауалнамалар сізді жақын арада күтеді!",
    FeedBackCategory: "Хабарламаның тақырыбы",
    FeedBackPhoneNumber: "Телефон нөмірін көрсетіңіз",
    FeedBackEmail: "Поштаны көрсетіңіз",
    FeedbackSend: "Жіберу",
    FeedBackText: "Мәселені сипаттаңыз",
    WalletAmount: "Жиналған ұпайлар:",
    BankCardPlaceHolder: "Банк карта нөмірін еңгізіңіз",
    Left_t: "Әрекеттер тарихы",
    Left_th: "Әзірше әрекеттер жоқ :(",
    Left_fo: "Сауалнамаларды өтіп, балл жинаңыз",
    Left_fi: "Сауалнама үшін берілген балл",
    Left_s: "Картаға",
    Right_f: "Балл аударымы",
    Right_t: "Теңгерімге аудару:",
    Right_th: "Ұпай саны",
    Right_fu: "Нөмірді сақтау",
    Right_fi: "Ұпайды шығару",
    Right_s: "Шығарудың минималды сомасы 500 ұпай. Айырбастау 1 балл = 1 теңге",
    Total_m: "Барлық уақыттағы ұпайлар саны",
    Total_r: "Белсенді пайдаланушылар саны",
    Total_s: "Барлық уақыттағы SMS саны",
    SurveyTitleKaz: "Сауалнаманың қазақша аты",
    SurveyTitleRus: "Сауалнаманың орысша аты",
    SurveyLinkRus: "Орыс тіліндегі сауалнамаға сілтеме",
    SurveyLinkKaz: "Қазақ тіліндегі сауалнамаға сілтеме",
    SurveyRespondentsCount: "Қолданушыларды қамту мақсаты *",
    SurveyRespondentsCountPlaceHolder: "Сан еңгізіңіз",

    Surveyfi: "Жыныс",
    Surveyse: "Отбасылық жағдайы",
    Surveye: "Регион",
    Surveyn: "Отбасындағы адамдар саны",
    Surveyt: "18 жасқа дейінгі балалар",
    Surveyel: "Отбасының жалпы табысы",
    Surveytwe: "Жұмыспен қамту",
    Surveythi: "Білім деңгейі",
    Surveyfo: "Қызығушылықтар",
    Surveyfif: "Автокөліктің болуы",
    Surveysix: "Жылжымайтын мүліктің болуы",
    SurveyImageDropZone: "Сауалнама мұқабасын бекіту",

    balance: "ұпай",

    FAQ: "FAQ",

    SaveChanges: "Өзгерістерді сақтау?",
    SaveChangesPart1: "Сіз профильдегі деректерді өзгерттіңіз.",
    SaveChangesPart2:
      "Егер сіз поштанызды өзгерткен болсаңыз, растауды ұмытпаңыз.",
    SaveChangesPart3: `"Сақтау" бастырмасын басқаннан кейін ескі деректеріңіз жоғалады.`,

    EnterCode: "Кодты еңгізіңіз",
    Send: "Жіберу",
    SavePassword: "Құпиясөзді сақтау",

    ConfirmAccount: "Aккаунты растаңыз",
    PhoneVerifyCodeTextPart1: "Сізге бұрын көрсетілген",
    PhoneVerifyCodeTextPart2:
      "телефон нөміріңізге 4-санды код жіберілді.\n Соны еңгізіңіз",
    ResendCode: "Кодты қайтадан жіберу",

    Managers: "Менеджерлер",
    NewManager: "Жаңа менеджер",
    RemoveMeneger: "Менеджерді өшіру",
    ConfirmDeleteManager: "Сіз менеджерді нақты өшіргіңіз келеді ме?",

    Deadline: "Дедлайн",
    Filter: "Қолданушылар сүзгісі *",
    FilterName: "Шаблона аты *",
    FilterNameInput: "Шаблонның атын еңгізіңіз",
    FilterDelete: "Шаблонды Өшіру",
    FilterDeleteConfirm: "Шаблонды өшіруге шынымен де қалайсыз ба?",
    SurveyMoney: "Өткені үшін берілетін балл",
    Appropriate: "Лайықты сұхбаткерлер саны: ",
    Cancel: "Жою",
    Save_Survey: "Алғашқы нұсқаны сақтау",
    Post: "Жариялау",
    Filter_user: "Сұхбаткерлерді баптау",
    SurveyFilterAge: "Жас *",
    SurveyMoneyPlaceHolder: "Ұпайлар санын көрсетіңіз",
    DeadlineDate: "Мерзімін көрсетіңіз",
    Add_category: "Категория қосу",
    Delete_category: "Категорияны жою",
    Add_quest: "Жаңа сұрақ",
    Question: "Сұрақ *",
    Answer: "Жауап *",
    AnswerInput: "Жауапты енгізу",
    Delete: "Өшіру",
    Download: "Жүктеу",
    Disable: "Тоқтату",
    Enable: "Қалпына келтіру",

    EnterPromo: "Рефералды кодты еңгізіңіз",

    age: "Жас",
    gender: "Жыныс",
    residence: "Тұратын жер",
    country: "Тұратын ел",
    region: "Облыс",
    nationality: "Ұлт",
    language: "Сөйлесу тілі",
    family_status: "Тұрмыстық жағдай",
    family_members_count: "Отбасыдағы адамдар саны",
    children_count: "18 жасқа дейінгі балалар",
    total_income: "Отбасының жалпы кірісі",
    financial_situation: "Қаржылық жағдайы",
    employment_status: "Жумыс статусы",
    work_obligation: "Қызмет көрсету",
    ownership: "Көрсетілгендерден не бар",
    social_network: "Тізімделгендерден не пайдаланады",

    questionsCount: "Сұрақтар саны",
    checkboxSignUpFirst: "Қолдану ережелерін ",
    And: "және ",
    checkboxSignUpSecond: "құпиялылық саясатты ",
    checkboxSignUpAccept: "қабылдаймын",

    SurveyStatistics: "Сауалнама статистикасы",
    SpentMoney: "Жұмсалған ақша",
    AverageTime: "Орташа уақыт",
    RespondentsCount: "Қатысушылар",
    DeleteSurvey: "Сауалнаманы жою",
    SendInvitation: "Шақыру жіберу",
    StopSurvey: "Сауалнаманы тоқтату",
    RestartSurvey: "Сауалнаманы жалғастыру",
    DownloadSurveyAnalysis: "Сауалнаманы жүктеу",
    ShowMore: "Көбірек көрсету",
    ShowLess: "Азырақ көрсету",

    NotSelected: "Таңдалмаған",

    feedback_title: "Кері байланыс",
    start_survey: "Сауалнамадан өту",
  },
};
