import { useEffect, useState } from "react";
import MainLayout from "../components/Shared/MainLayout";
import useMobile from "../hooks/useMobile";
import useTablet from "../hooks/useTablet";
import useUser from "../hooks/useUser";
import "../styles/mainpage.css";
import { AdminPage } from "./AdminPage";
import { UserPage } from "./UserPage";

const MainPage = () => {
  const { userData } = useUser();
  const handleClickScroll = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const { setTabletFun } = useTablet();
  const { setMobileFun } = useMobile();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 600 && window.innerWidth <= 820) {
      setTabletFun(true);
      setMobileFun(false);
    } else if (window.innerWidth <= 600) {
      setTabletFun(false);
      setMobileFun(true);
    } else {
      setMobileFun(false);
      setTabletFun(false);
    }
  }, [width]);

  useEffect(() => {}, [window.innerWidth]);
  return (
    <MainLayout
      pageClick={handleClickScroll}
      style={{ backgroundColor: "#FBF7F4" }}
    >
      {userData?.account?.role === "ADMIN" ||
      userData?.account?.role === "MANAGER" ? (
        <AdminPage />
      ) : (
        <UserPage />
      )}
    </MainLayout>
  );
};

export default MainPage;
