import { Image, Text } from "@mantine/core";
import { Box } from "@mui/system";
import { PopupButton } from "@typeform/embed-react";
import axios from "axios";
import { FC, useEffect, useRef, useState } from "react";
import { languages } from "../../../Languages/languages";
import useLang from "../../../hooks/useLang";
import { surveyService } from "../../../services/Surveys";
import { userService } from "../../../services/UserService";
import { UserSurveyResponse } from "../../../services/responses/SurveyResponse";
import Placeholder from "./../../../Images/Placeholder.png";
import { useDisclosure } from "@mantine/hooks";

export interface SurveyCardProps {
  survey: UserSurveyResponse;
  update: () => void;
  open_on_start: boolean;
}

const SurveyCard: FC<SurveyCardProps> = ({ survey, update, open_on_start }) => {
  const ref = useRef<HTMLButtonElement>();
  const { language } = useLang();

  return (
    <PopupButton
      ref={ref}
      id={survey.link.filter((el: any) => el.language === language)[0].form_id}
      style={{
        background: "unset",
        border: "unset",
      }}
      open={open_on_start ? "load" : undefined}
      onSubmit={(typeform: any) => {
        const data = {
          id: survey.id,
          form_id: survey.link.filter((el: any) => el.language === language)[0]
            .form_id,
        };
        if (survey.type === "registration") {
          userService
            .SetUserData(
              {
                response_id: typeform.response_id,
                survey: data,
              },
              language
            )
            .then((data) => {
              update();
            });
        } else {
          surveyService
            .postResponse(
              { response_id: typeform.response_id, survey: data },
              language
            )
            .then((data) => {
              update();
            });
        }
      }}
    >
      <Box
        style={{
          width: "auto",
          maxHeight: "282px",
          borderRadius: "20px",
          background: "#FFFFFF",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Image
          src={survey.image_link}
          withPlaceholder
          placeholder={<Image src={Placeholder} />}
          height="200px"
          fit="cover"
          styles={{
            imageWrapper: {
              borderRadius: "20px 20px 0px 0px",
            },
            image: { borderRadius: "20px 20px 0px 0px" },
            placeholder: {
              height: "200px",
              background: "gray",
              borderRadius: "20px 20px 0px 0px",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "29px",
                paddingBottom: "4px",
              }}
            >
              {survey.questions_count}
            </Box>

            <Box
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "19px",

                color: "#AEAEAE",
              }}
            >
              {languages[language].questionsCount}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "29px",
                paddingBottom: "4px",
              }}
            >
              {survey.money}
            </Box>

            <Box
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "19px",

                color: "#AEAEAE",
              }}
            >
              {languages[language].balance}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "28px",
            left: "18px",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "29px",
            color: "#FFFFFF",
            zIndex: 1,
          }}
        >
          {survey.title[language]}
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              border: "2px solid gray",
              borderRadius: "12px",
              padding: "10px 16px",
              background: "white",
              fontSize: "20px",
              fontWeight: 700,
              color: "#000000c9",
              cursor: "pointer",
            }}
          >
            {languages[language].start_survey}
          </Text>
        </Box>
      </Box>
    </PopupButton>
  );
};

export default SurveyCard;
