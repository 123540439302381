import { Box, Button, Modal } from "@mui/material";
import React, { FC, useState } from "react";
import useLang from "../../../hooks/useLang";
import useMobile from "../../../hooks/useMobile";
import { useModal } from "../../../hooks/useModal";
import { languages } from "../../../Languages/languages";
import { surveyService } from "../../../services/Surveys";
import FastButton from "../../UI/FastButton";
import FastSearch from "../../UI/FastSearch";
import TextInput from "../../Shared/TextInput";
import Icon from "../../Icon/icon";

export interface FilterOptions {
  name: string;
  filter: {};
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
  background: "#FBF7F4",
  borderRadius: "24px",
  // width: "558px",
  padding: "24px",
};

export const CreateFilter: FC<{
  title: string;
  filter: any;
  open: boolean;
  setOpen: (state: boolean) => void;
  handleClose: () => void;
  setFilterCards: (options: FilterOptions[]) => void;
}> = ({ title, filter, open, setOpen, handleClose, setFilterCards }) => {
  const { language } = useLang();
  const { setModal } = useModal();
  const { isMobile } = useMobile();
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    name: "",
    filter: {},
  });

  const saveFilter = () => {
    setFilterOptions({ ...filterOptions, filter: filter });
    surveyService
      .SaveFilter({ name: filterOptions.name, filter: filter })
      .then((data) => {
        surveyService.getFilters().then((filterCards) => {
          setFilterCards(filterCards);
        });
        setModal(false);
      })
      .catch((err) => {});
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontWeight: 700, fontSize: "22px", lineHeight: "27px" }}>
            {title}
          </Box>
          <Box>
            <button
              onClick={() => setOpen(false)}
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                padding: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              {Icon.exit}
            </button>
          </Box>
        </Box>
        <Box
          sx={{
            width: isMobile ? "300px" : "818px",
            paddingTop: isMobile ? "50px" : "0",
            paddingBottom: isMobile ? "10px" : "0",
            fontWeight: 400,
            fontSize: isMobile ? "14px" : "16px",
            lineHeight: isMobile ? "18px" : "20px",
          }}
        >
          <Box
            sx={{
              fontWeight: 300,
              fontSize: isMobile ? "14px" : "16px",
              lineHeight: isMobile ? "15px" : "15px",
              paddingBottom: isMobile ? "9px" : "16px",
              marginTop: isMobile ? "9px" : "24px",
              borderTop: "1px solid #E0E0E0",
              paddingTop: "24px",
            }}
          >
            {languages[language].FilterName}
          </Box>
          <TextInput
            id="name"
            placeholder={languages[language].FilterNameInput}
            data={filterOptions}
            setData={setFilterOptions}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingTop: isMobile ? "10px" : "24px",
              marginTop: isMobile ? "10px" : "24px",
              width: "100%",
              borderTop: "1px solid #E0E0E0",
            }}
          >
            <FastButton
              click={() => setOpen(false)}
              style={{
                padding: isMobile ? "5px 10px" : "1px 30px",
                fontSize: isMobile ? "12px" : "16px",
                border: "none",
                background: "none",
                marginBottom: isMobile ? "10px" : "",
              }}
            >
              {languages[language].Back}
            </FastButton>
            <FastButton
              click={() => saveFilter()}
              variant={"contained"}
              style={{ padding: "8px 30px", fontSize: "14px" }}
              disabled={filterOptions.name === ""}
            >
              {languages[language].Btn_Save}
            </FastButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
