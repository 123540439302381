import MainLayout from "../components/Shared/MainLayout";
import { FAQs } from "../components/FAQ/FAQs";
import useMobile from "../hooks/useMobile";
import { useEffect, useState } from "react";
import useTablet from "../hooks/useTablet";

const FAQPage = () => {
  const { isTablet, setTabletFun } = useTablet();
  const { isMobile, setMobileFun } = useMobile();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 600 && window.innerWidth <= 800) {
      setTabletFun(true);
      setMobileFun(false);
    } else if (window.innerWidth <= 600) {
      setTabletFun(false);
      setMobileFun(true);
    } else {
      setMobileFun(false);
      setTabletFun(false);
    }
  }, [width]);

  useEffect(() => {}, [window.innerWidth]);

  return (
    <MainLayout>
      <FAQs />
    </MainLayout>
  );
};

export default FAQPage;
