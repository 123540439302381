import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Loader,
  LoadingOverlay,
  Modal,
  NumberInput,
  TextInput,
  createStyles,
} from "@mantine/core";
import { FC, useEffect } from "react";
import useMobile from "../../hooks/useMobile";
import useTablet from "../../hooks/useTablet";
import { useForm } from "@mantine/form";
import DateTime from "../AddSurveys/helpers/DateTime";
import useLang from "../../hooks/useLang";
// import { useMutation, useQuery } from "@tanstack/react-query";
import { useMutation, useQuery } from "react-query";
import { surveyService } from "../../services/Surveys";
import { useNavigate } from "react-router-dom";
import { AdminSurveyResponse } from "../../services/responses/SurveyResponse";
import { UpdateSurveyRequest } from "../../services/requests/UpdateSurveyRequest";
import { notifications } from "@mantine/notifications";
import { ImageDropZone } from "./ImageDropZone";
import { useForceUpdate } from "@mantine/hooks";

const style = {
  position: "absolute",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
  background: "#fff5f5",
  borderRadius: "24px",
  border: "2px solid red",
  minWidth: "358px",
  minHeight: "264px",
  padding: "24px",
};

const useStyles = createStyles(() => ({
  form: {
    width: "100%",
  },
}));

const languages = {
  kaz: {
    deadline: "Дедлайн",
    deadline_date: "Мерзімін көрсетіңіз",
    update_success: "Өзгерістер сәтті аяқталды",
    error_title: "Қате",
  },
  rus: {
    deadline: "Дедлайн",
    deadline_date: "Укажите срок",
    update_success: "Изменения успешно были внесены",
    error_title: "Ошибка",
  },
};

export interface ChangeSurveyModalProps {
  id: string;
  isOpen: boolean;
  close: () => void;
}

export const ChangeSurveyModal: FC<ChangeSurveyModalProps> = ({
  id,
  isOpen,
  close,
}) => {
  const { classes } = useStyles();
  const { language } = useLang();
  const navigate = useNavigate();

  const form = useForm<UpdateSurveyRequest>({
    initialValues: {
      finishes_at: undefined,
      title: undefined,
      image_link: undefined,
      estimated_responses: undefined,
      money: undefined,
      status: undefined,
    },
  });

  const { isLoading, isFetching, isError, data, refetch } = useQuery(
    "GetSurvey" + id,
    async () => {
      return await surveyService.getSurvey(id);
    },
    {
      onSuccess: (response: AdminSurveyResponse) => {
        form.setValues({
          finishes_at: response.finishes_at,
          title: response.title,
          image_link: response.image_link,
          estimated_responses: response.estimated_responses,
          money: response.money,
          status: response.status,
        });
      },
      onError: (error: any) => {
        if (error.response.status === 401) {
          window.localStorage.removeItem("token");
          navigate("/login");
        }
        if (error.response.status === 404) {
        }
      },
    }
  );

  useEffect(() => {
    if (!isOpen) return;
    else refetch();
  }, [isOpen]);

  const mutation = useMutation(
    async (data: UpdateSurveyRequest) => {
      return await surveyService.UpdateSurvey(id, data);
    },
    {
      onSuccess: (data) => {
        notifications.show({
          message: languages[language].update_success,
          autoClose: 10000,
          styles: (theme) => ({
            root: {
              backgroundColor: "#73A9C2",
              borderColor: "#73A9C2",
              "&::before": { backgroundColor: theme.white },
            },
            title: { color: theme.white },
            description: { color: theme.white },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.blue[7] },
            },
          }),
        });
        close();
        form.reset();
      },
      onError: (error: any) => {
        notifications.show({
          title: languages[language].error_title,
          message: error,
          autoClose: 10000,
          styles: (theme) => ({
            root: {
              borderColor: "#73A9C2",
              "&::before": { backgroundColor: theme.colors.red[5] },
            },
            title: { color: theme.colors.red[5] },
            closeButton: {
              color: theme.white,
              "&:hover": { backgroundColor: theme.colors.blue[7] },
            },
          }),
        });
      },
    }
  );

  if (data === undefined) return <></>;

  const submit = async (data: UpdateSurveyRequest) => {
    mutation.mutate(data);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        close();
        form.reset();
      }}
      radius={"20px"}
      lockScroll
      size="md"
      styles={{
        root: {
          display: "flex",
          justifyContent: "center",
          width: "100%",
        },
      }}
      xOffset={0}
    >
      {isFetching ? (
        <Center mih="200px">
          <Loader />
        </Center>
      ) : (
        <Box styles={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className={classes.form}
              onSubmit={form.onSubmit((values) => submit(values))}
            >
              <ImageDropZone
                data={form.values}
                SetData={(values: UpdateSurveyRequest) => {
                  form.setValues(values);
                }}
              />
              <TextInput
                label="Title in kazakh"
                defaultValue={form.values.title?.kaz}
                {...form.getInputProps("title.kaz")}
              />
              <TextInput
                label="Title in russian"
                defaultValue={form.values.title?.rus}
                {...form.getInputProps("title.rus")}
              />
              <NumberInput label="Money" {...form.getInputProps("money")} />
              <NumberInput
                label="Estimated Responses"
                {...form.getInputProps("estimated_responses")}
              />
              <DateTime
                id="finishes_at"
                title={languages[language].deadline}
                placeholder={languages[language].deadline_date}
                data={form.values}
                setData={(values: UpdateSurveyRequest) => {
                  form.setValues(values);
                }}
              />

              <Flex justify="end" pt="20px">
                <Button type="submit">Submit</Button>
              </Flex>
            </form>
          </Box>
        </Box>
      )}
    </Modal>
  );
};
