import {
  ActionIcon,
  Flex,
  Grid,
  Input,
  NumberInput,
  Paper,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { FC } from "react";
import { IMask, IMaskInput } from "react-imask";
import useLang from "../../hooks/useLang";

const masked = IMask.createMask({
  mask: "+7 (700) 000-00-00",
});

const languages = {
  kaz: {
    phone_number: "Телефон нөмірі",
    sum: "Сомма",
  },
  rus: {
    phone_number: "Номер телефона",
    sum: "Сумма",
  },
};

interface Props {
  value: { phone_number: string; amount: number };
  setValue: (data: { phone_number: string; amount: number }) => void;
  onClear: () => void;
}

export const TransactionInputCard: FC<Props> = ({
  value,
  setValue,
  onClear,
}) => {
  const { language } = useLang();

  return (
    <Paper p="md" radius="lg">
      <Grid columns={10}>
        <Grid.Col span={6}>
          <Input
            radius="md"
            id="phone_number"
            placeholder={languages[language].phone_number}
            component={IMaskInput}
            mask="+7 (700) 000-00-00"
            value={value.phone_number}
            onAccept={(phone_number: string) => {
              masked.resolve(phone_number);
              setValue({ ...value, phone_number: `77${masked.unmaskedValue}` });
            }}
            required
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex gap="md" align="center">
            <NumberInput
              required
              w="100%"
              radius="md"
              placeholder={languages[language].sum}
              hideControls
              value={value.amount}
              onChange={(amount: number) => setValue({ ...value, amount })}
            />
            <ActionIcon size="lg" color="red" onClick={onClear}>
              <IconTrash />
            </ActionIcon>
          </Flex>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
