import { useContext } from "react";
import { ModalContentContext } from "../context/ModalContentProvider";

export function useModalContent() {
  const { modalContent, setModalContent } = useContext(ModalContentContext);

  return {
    modalContent,
    setModalContent,
  };
}
