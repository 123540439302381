import axios from "axios";
import { PostFeedbackRequest } from "./requests/PostFeedbackRequest";
import { GetFeedbackCategories } from "./responses/GetFeedbackCategories";

const axiosApiInstance = axios.create();

let headers = {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  },
};

class EmailsService {
  async GetFeedbackCategories() {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .get<GetFeedbackCategories>(
        "https://api.pikirlab.kz/emails/feedback/categories",
        headers
      )
      .then((response) => response.data);
  }

  async PostFeedback(data: PostFeedbackRequest) {
    headers.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    return axiosApiInstance
      .post("https://api.pikirlab.kz/emails/feedback", data, headers)
      .then((response) => response.data);
  }
}

export const emailsService = new EmailsService();
