import { Box } from "@mui/system";
import FastInput from "../UI/FastInput";
import FastButton from "../UI/FastButton";
import { FC, useState } from "react";
import { useModal } from "../../hooks/useModal";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { faqService } from "../../services/faqService";
import useMobile from "../../hooks/useMobile";
import { CreateFAQRequest } from "../../services/requests/CreateFAQRequest";
import TextInput from "../Shared/TextInput";
import { Textarea } from "@mantine/core";
import useTablet from "../../hooks/useTablet";

export interface AddQuestionProps {
  id: string;
  func: () => void;
}

export const AddQuestion: FC<AddQuestionProps> = ({ id, func }) => {
  const { language } = useLang();
  const [faq, setFAQ] = useState<CreateFAQRequest>({
    kaz: {
      question: undefined,
      answer: undefined,
    },
    rus: {
      question: undefined,
      answer: undefined,
    },
  });
  const { setModal } = useModal();
  const { isMobile } = useMobile();
  const { isTablet } = useTablet();

  const submitQuestion = (id: string) => {
    faqService.createFAQ(id, faq).then((data) => {
      setModal(false);
      func();
    });
  };

  return (
    <Box
      sx={{
        paddingTop: "24px",
        width: isTablet ? "700px" : isMobile ? "100%" : "800px",
        height: isMobile ? window.screen.height - 200 : "auto",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          overflowY: "auto",
          borderTop: "1px solid #E0E0E0",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: isTablet ? "320px" : isMobile ? "300px" : "380px",
          }}
        >
          <Box
            sx={{
              marginTop: "24px",
              paddingBottom: "8px",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {languages.kaz.Question}
          </Box>
          <TextInput
            id="kaz.question"
            placeholder={"Қазақша сұрақты еңгізіңіз"}
            data={faq}
            setData={setFAQ}
            InputProps={{
              sx: {
                borderRadius: 2,
                marginBottom: "16px",
                backgroundColor: "#FBF7F4",
              },
            }}
          />
          <Box
            sx={{
              padding: "8px 0",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {languages.kaz.Answer}
          </Box>
          <textarea
            value={faq.kaz.answer}
            onChange={(value: any) => {
              const update = {
                ...faq.kaz,
                answer: value.target.value,
              };
              setFAQ({ ...faq, kaz: update });
            }}
            style={{
              resize: "none",
              width: "100%",
              height: "181px",
              background: "#FBF7F4",
              fontSize: "16px",
              padding: "20px",
              border: "1px solid #767676",
              borderRadius: "12px",
              outline: "none",
              boxSizing: "border-box",
              borderColor: "#0000003b",
            }}
            placeholder={"Жауапты еңгізіңіз"}
          />
        </Box>
        <Box
          sx={{
            width: isTablet ? "320px" : isMobile ? "300px" : "380px",
          }}
        >
          <Box
            sx={{
              marginTop: "24px",
              paddingBottom: "8px",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {languages.rus.Question}
          </Box>
          <TextInput
            id="rus.question"
            placeholder={"Введите вопрос на русском"}
            data={faq}
            setData={setFAQ}
            InputProps={{
              sx: {
                borderRadius: 2,
                marginBottom: "16px",
                backgroundColor: "#FBF7F4",
              },
            }}
          />
          <Box
            sx={{
              padding: "8px 0",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {languages.rus.Answer}
          </Box>
          <textarea
            value={faq.rus.answer}
            onChange={(value: any) => {
              const update = {
                ...faq.rus,
                answer: value.target.value,
              };
              setFAQ({ ...faq, rus: update });
            }}
            style={{
              resize: "none",
              width: "100%",
              height: "181px",
              background: "#FBF7F4",
              fontSize: "16px",
              padding: "20px",
              border: "1px solid #767676",
              borderRadius: "12px",
              outline: "none",
              boxSizing: "border-box",
              borderColor: "#0000003b",
            }}
            placeholder={"Введите ответ"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          paddingTop: "24px",
          borderTop: "1px solid #E0E0E0",
        }}
      >
        <FastButton
          variant={"contained"}
          style={{
            padding: "8px 30px",
            fontSize: "14px",
            marginLeft: "24px",
          }}
          click={() => submitQuestion(id)}
        >
          {languages[language].Btn_Save}
        </FastButton>
        <FastButton
          style={{
            padding: "8px 30px",
            fontSize: "14px",
          }}
          click={() => setModal(false)}
        >
          {languages[language].Cancel}
        </FastButton>
      </Box>
    </Box>
  );
};
