import FastInput from "../UI/FastInput";

const TextInput: React.FC<{
  id: string;
  placeholder: string;
  data: any;
  setData: any;
  value?: string;
  InputProps?: {
    sx: {
      borderRadius?: number;
      marginBottom?: string;
      backgroundColor?: string;
    };
  };
}> = ({ id, placeholder, data, setData, value, InputProps }) => {
  return (
    <FastInput
      value={value}
      placeholder={placeholder}
      change={(value: any) => {
        if (id.split(".")[0] === "link") {
          const link = { ...data.link, [id.split(".")[1]]: value.target.value };
          setData({ ...data, link: link });
        } else if (id.split(".")[0] === "kaz" || id.split(".")[0] === "rus") {
          const child = id.split(".")[1];
          const update = {
            ...data[child],
            [child]: value.target.value,
          };
          setData({ ...data, [id.split(".")[0]]: update });
        } else if (id.split(".")[1] === "kaz" || id.split(".")[1] === "rus") {
          // const child = id.split(".")[0];
          const update = {
            ...data[id.split(".")[0]],
            [id.split(".")[1]]: value.target.value,
          };
          setData({ ...data, [id.split(".")[0]]: update });
        } else setData({ ...data, [id]: value.target.value });
      }}
      InputProps={
        InputProps ?? {
          sx: {
            borderRadius: 3,
            marginBottom: "16px",
            backgroundColor: "#FFFFFF",
          },
        }
      }
    />
  );
};
export default TextInput;
