import { TextField, TextFieldProps } from "@mui/material";
import styled from "@emotion/styled";
import { FC } from "react";

const SuccessInput = styled(TextField)(() => ({
  width: "100%",
  // height: "44px",
  fontFamily: "Gilroy",
  zIndex: 2,
  "& input": {
    fontFamily: "Gilroy",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#000000",
    opacity: 0.6,
    borderRadius: "10px",
    padding: "12px 20px",
  },
  "& fieldset": {
    borderColor: "#0000003b",
  },
  "& label": {
    fontFamily: "Gilroy",
    left: "8px",
    top: "-2px",
    zIndex: 1,

    "&.MuiInputLabel-shrink": {
      fontFamily: "Gilroy",
      left: "0px",
      top: "0px",
    },
  },
}));

export interface FastInputProps {
  label?: string;
  style?: React.CSSProperties;
  type?: string;
  change?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  helperText?: string;
  error?: boolean;
  params?: TextFieldProps;
  [x: string]: any; // For additional props
}

const FastInput: FC<FastInputProps> = ({
  label,
  style,
  type = "text",
  change,
  value,
  helperText,
  error,
  params,
  ...props
}) => {
  return (
    <SuccessInput
      error={error}
      label={label}
      variant="outlined"
      value={value}
      onChange={change}
      style={style}
      type={type}
      helperText={helperText}
      {...props}
    />
  );
};

export default FastInput;
