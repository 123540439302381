import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import useLoading from "../../hooks/useLoading";
import { useModal } from "../../hooks/useModal";
import { useModalContent } from "../../hooks/useModalContent";
import Icon from "../Icon/icon";

const PopUp = ({ children }: any) => {
  const { isModalOpen, setModal } = useModal();
  const { setLoading } = useLoading();
  const { modalContent } = useModalContent();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 4,
    background: "#FBF7F4",
    borderRadius: "24px",
    padding: "24px",
  };

  return (
    <Box>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setLoading(false);
          setModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ fontWeight: 700, fontSize: "22px", lineHeight: "27px" }}>
              {modalContent.title}
            </Box>
            <Box>
              <button
                onClick={() => {
                  setLoading(false);
                  setModal(false);
                }}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {Icon.exit}
              </button>
            </Box>
          </Box>
          <Box>{modalContent.content}</Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PopUp;
