import {
  Box,
  Button,
  createStyles,
  Flex,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { FC, useState } from "react";
import useLang from "../../hooks/useLang";
import { useForm } from "@mantine/form";
import { useMutation } from "react-query";
import { userService } from "../../services/UserService";
import { UpdateUserRequest } from "../../services/requests/UpdateUserRequest";
import { CreateInvoiceResponse } from "../../services/responses/CreateInvoiceResponse";
import { useForceUpdate } from "@mantine/hooks";
import { ErrorResponse } from "../../models/error";
import { notifications } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  wrapper: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    background: theme.colors.orange[1],
    border: "2px solid",
    borderColor: theme.colors.orange[5],
    borderRadius: "24px",
    padding: "20px",
  },

  email_wrapper: {
    flexDirection: "row",
    gap: "20px",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  email_input: {
    width: "400px",
    [theme.fn.smallerThan("sm")]: {
      width: "unset",
    },
  },
}));

const languages = {
  kaz: {
    title: "Респонденттер панеліне қош келдіңіз!",
    placeholder: "Сіздің поштаңыз",
    submit: "сақтау",
    body: [
      "Тіркеуді аяқтау (жолдама ұпайларын есептеу үшін) және ұпайларға қол жеткізуді ашу үшін электрондық поштаны растауыңыз керек! Бұл поштаға біз сіз жіберіп алмау үшін жаңа сауалнамаларға шақыруларды жібереміз.",
      "Рақмет! Өтетін сауалнамаларыңыз қызықты болсын!",
      "Pikirlab тобы",
    ],
    notification: {
      title: "Электрондық поштаңызды растаңыз",
      body: "Біз сіздің поштаңызға белсендіретін сілтеме бар хат жібердік, оны 1 күн ішінде растаңыз.",
    },
  },
  rus: {
    title: "Добро пожаловать в Панель респондента!",
    placeholder: "Ваша почта",
    submit: "cохранить",
    body: [
      "Для завершения регистрации (и для начисление реферальных баллов) и открытия доступа к баллам вам необходимо подтвердить электронную почту, куда мы будем высылать приглашения на новые опросы, чтобы вы их не пропустили!",
      "Благодарим вас и желаем интересных опросов!",
      "Команда PikirLab",
    ],
    notification: {
      title: "Подтвердите вашу электронную почту",
      body: "Мы выслали письмо с активирующей ссылкой на вашу почту, подтвердите ее в течение 1 дня.",
    },
  },
};

export const EmailVerification: FC = () => {
  const { classes } = useStyles();
  const { language } = useLang();
  const forceUpdate = useForceUpdate();
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  const mutation = useMutation(
    async (data: UpdateUserRequest) => {
      setLoading(true);
      return await userService.UpdateUser(data);
    },
    {
      onSuccess: (data: CreateInvoiceResponse) => {
        setLoading(false);
        notifications.show({
          style: {
            top: 0,
          },
          title: languages[language].notification.title,
          message: languages[language].notification.body,
        });
        forceUpdate();
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        if (error.response.status === 403) {
          form.setFieldError(
            "email",
            error?.response?.data?.error?.[0]?.message
          );
        }
      },
    }
  );

  return (
    <Box className={classes.wrapper}>
      <Text size={"20px"}>{languages[language].title}</Text>
      <Box
        style={{
          whiteSpace: "pre-line",
        }}
      ></Box>
      <Text>{languages[language].body[0]}</Text>
      <Flex className={classes.email_wrapper}>
        <TextInput
          className={classes.email_input}
          placeholder={languages[language].placeholder}
          data-autofocus
          {...form.getInputProps("email")}
        />
        <Button
          loading={loading}
          styles={(theme) => ({
            root: {
              background: "#73A9C2",
              ["&:hover "]: {
                background: theme.fn.darken("#73A9C2", 0.1),
              },
            },
          })}
          type="submit"
          onClick={() => {
            mutation.mutate(form.values);
          }}
        >
          {languages[language].submit}
        </Button>
      </Flex>
      <Text>{languages[language].body[1]}</Text>
      <Text>{languages[language].body[2]}</Text>
    </Box>
  );
};
