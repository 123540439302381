import { Box } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { languages } from "../../../Languages/languages";
import useLang from "../../../hooks/useLang";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { useQuery } from "react-query";
import { surveyService } from "../../../services/Surveys";
import { ErrorResponse } from "../../../models/error";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#73A9C2",
    // opacity:theme.palette.mode === 'light'?0.1:1
  },
}));

interface Props {
  survey_id: string;
  //   key: string;
  category: string;
}

export const AnalysisCard: FC<Props> = ({ survey_id, category }) => {
  const { language } = useLang();
  const [data, setData] = useState<{ [key: string]: number }>({});
  const [count, setCount] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);

  useQuery(
    "GetSurveyAnalysis" + category,
    async () => {
      return await surveyService.getSurveyAnalysis(
        survey_id,
        language,
        category
      );
    },
    {
      onSuccess: (response) => {
        setData(response.analysis[category]);
      },
      onError: (error: ErrorResponse) => {},
    }
  );

  useEffect(() => {
    if (!data) return;
    setCount(
      Object.entries(data)
        .map(([k, v]) => v)
        .reduce((sum, current) => sum + current, 0)
    );
  }, [data]);

  if (!data) return <></>;

  return (
    <Box
      key={category}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 16px 24px 4px rgba(90, 82, 128, 0.12)",
        borderRadius: "26px",
        padding: "26px 36px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          sx={{
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          {languages[language][category]}
        </Box>
        <Box>
          {Object.entries(data)
            .map(([k, v]) => ({
              k,
              v,
              calculatedValue: (v * 100) / count,
            }))
            .sort((a, b) => b.calculatedValue - a.calculatedValue)
            .map((item, i) => {
              if (!open && i > 3) return;
              return (
                <Box key={i} sx={{ paddingTop: "16px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ paddingBottom: "5px" }}>{item.k}</Box>
                    <Box
                      style={{
                        paddingTop: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {Math.round(item.calculatedValue * 10) / 10 + "%"}
                    </Box>
                  </Box>
                  <BorderLinearProgress
                    variant="determinate"
                    value={item.calculatedValue}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
      {Object.keys(data).length > 4 ? (
        <Box
          style={{
            paddingTop: "10px",
            marginLeft: "0px",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: "#73A9C2",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {!open ? languages[language].ShowMore : languages[language].ShowLess}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
