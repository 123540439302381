import axios from "axios";

const axiosApiInstance = axios.create();

let headers = (lang: string = "rus") => ({
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    Accept: "application/json",
    Lang: lang,
  },
});

class VerificationsService {
  async Create() {
    return (
      await axiosApiInstance.post(
        "https://api.pikirlab.kz/verifications/sms",
        null,
        headers()
      )
    ).data;
  }
}

export const verificationsService = new VerificationsService();
