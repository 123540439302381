import { Box } from "@mui/material";
import { languages } from "../../Languages/languages";
import useLang from "../../hooks/useLang";
import useMobile from "../../hooks/useMobile";
import FastIcon from "../UI/FastIcon";

export default () => {
  const { language } = useLang();
  const { isMobile } = useMobile();
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (error) => {
        console.error(`Error copying ${text} to clipboard: ${error}`);
      }
    );
  };

  const promoCode: any = window.localStorage.getItem("promo");
  const width = (window.innerWidth * (isMobile ? 70 : 100)) / 100;

  return (
    <Box
      sx={{
        height: "300px",
        width: isMobile ? width.toString() + "px" : "700px",
      }}
    >
      <Box
        sx={{
          padding: isMobile ? "24px 0 40px 0" : "24px 0 90px 0",
          fontSize: "16px",
        }}
      >
        {languages[language].Invite_text}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "14px",
          borderBottom: "1px solid #333333",
          color: "#333333",
          fontSize: "30px",
        }}
      >
        {promoCode}
        <FastIcon
          name="copy"
          sx={{
            marginLeft: "30px",
            fontSize: "30px",
            cursor: "pointer",
          }}
          onClick={() => copyToClipboard(promoCode)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          right: "0px",
          bottom: "15px",
        }}
      >
        <a href="https://www.facebook.com/pikirlab/" target="_blank">
          <FastIcon name="facebook2" sx={{ marginRight: "15px" }} />
        </a>
        <a href="https://www.linkedin.com/company/pikirlab/" target="_blank">
          <FastIcon name="linkedin2" sx={{ marginRight: "15px" }} />
        </a>
        <a href="https://www.instagram.com/pikirlab/" target="_blank">
          <FastIcon name="insta2" sx={{ marginRight: "15px" }} />
        </a>
      </Box>
    </Box>
  );
};
