import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import FastButton from "../UI/FastButton";
import FastInput from "../UI/FastInput";
import { useState } from "react";
import { useModalContent } from "../../hooks/useModalContent";
import { useModal } from "../../hooks/useModal";
import SaveChanges from "../Shared/SaveChanges";
import useMobile from "../../hooks/useMobile";
import { Button } from "@mui/material";
import useLang from "../../hooks/useLang";
import { stringify } from "querystring";
import { languages } from "../../Languages/languages";
import InviteFriends from "../Shared/InviteFriends";

interface BoxProps {
  label: string;
  content: string;
  width: string;
  disabled: boolean;
  onChange: (value: any) => void;
}

const ReusableBox = ({ label, content, disabled, onChange }: BoxProps) => {
  return (
    <Box>
      <Box
        sx={{
          paddingBottom: "11px",
          color: "#8D98AA",
          fonWeight: 500,
          fontSize: "16px",
        }}
      >
        {label}
      </Box>
      <FastInput
        disabled={disabled}
        value={content}
        onChange={onChange}
        style={{
          background: "linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF",
        }}
      />
    </Box>
  );
};

interface formData {
  first_name?: string;
  last_name?: string;
  email?: string;
}

const validate = (pattern: any, value: any, setContent: any) => {
  if (value === "") {
    value = null;
    setContent();
  }
  if (!pattern || (pattern && new RegExp(pattern).test(value))) {
    setContent();
  }
};

const UserProfile = ({ props }: any) => {
  const { language } = useLang();
  const { userData, setUserData } = useUser();
  const [userForm, setUserForm] = useState<formData>();
  const navigate = useNavigate();
  // const [firstName, setFirstName] = useState(userData?.user?.first_name || "");
  // const [lastName, setLastName] = useState(userData?.user?.last_name || "");
  // const [phoneNumber, setPhoneNumber] = useState(userData?.account?.phone_number || "");
  const { setModalContent } = useModalContent();
  const { setModal } = useModal();

  // Adjust layout for mobile devices
  const { isMobile } = useMobile();
  const gridTemplateColumns = isMobile ? "1fr" : "1fr 1fr";
  const gridTemplateRows = isMobile ? "repeat(3, auto)" : "1fr 1fr";
  const gridColumnGap = isMobile ? "0" : "25px";
  const gridRowGap = isMobile ? "16px" : "24px";
  const boxWidth = isMobile ? "100%" : "95%";
  const phone_number = `+${userData?.account?.phone_number.slice(
    0,
    1
  )} (${userData?.account?.phone_number.slice(
    1,
    4
  )}) ${userData?.account?.phone_number.slice(
    4,
    7
  )} ${userData?.account?.phone_number.slice(7)}`;

  const LogOut = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "26px 31px",
        background: isMobile ? "" : "#FFFFFF",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: gridTemplateColumns,
          gridTemplateRows: gridTemplateRows,
          width: "100%",
          gridColumnGap: gridColumnGap,
          gridRowGap: gridRowGap,
        }}
      >
        <ReusableBox
          label={languages[language].Name}
          content={userForm?.first_name ?? userData?.user?.first_name}
          width={boxWidth}
          disabled={false}
          onChange={(value: any) => {
            const func = () =>
              setUserForm({ ...userForm, first_name: value.target.value });
            validate("^[a-zA-Zа-яА-Я]*$", value.target.value, func);
          }}
        />
        <ReusableBox
          label={languages[language].Surname}
          content={userForm?.last_name ?? userData?.user?.last_name}
          width={boxWidth}
          disabled={false}
          onChange={(value: any) => {
            const func = () =>
              setUserForm({ ...userForm, last_name: value.target.value });
            validate("^[a-zA-Zа-яА-Я]*$", value.target.value, func);
          }}
        />
        <ReusableBox
          label={languages[language].Mail}
          content={userForm?.email ?? userData?.user?.email?.email ?? ""}
          width={boxWidth}
          disabled={false}
          onChange={(value: any) => {
            const func = () =>
              setUserForm({ ...userForm, email: value.target.value });
            validate("", value.target.value, func);
          }}
        />
        <ReusableBox
          label={languages[language].PhoneNumber}
          content={phone_number ?? ""}
          width="95%"
          disabled={true}
          onChange={() => {
            setUserForm(userForm);
          }}
        />

        {isMobile ? (
          <FastButton
            variant="contained"
            click={() => {
              setModal(true);
              setModalContent({
                title: languages[language].Invite,
                content: <InviteFriends />,
              });
            }}
            style={{
              backgroundColor: "#333333",
              height: "47px",
              marginTop: "20px",
              borderRadius: "12px",
            }}
          >
            {languages[language].Invite_f}
          </FastButton>
        ) : (
          <></>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "26px 0",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "unset",
        }}
      >
        <FastButton
          style={{
            width: isMobile ? "60%" : "auto",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "10px",
            padding: "20px",
            borderRadius: "15px",
            marginTop: "23px",
          }}
          // width={"fit-content"}
          variant="contained"
          click={() => {
            setModal(true);
            setModalContent({
              title: languages[language].SaveChanges,
              content: (
                <SaveChanges userForm={userForm} setUserForm={setUserForm} />
              ),
            });
          }}
        >
          {languages[language].Btn_Save}
        </FastButton>
        {isMobile && (
          <Box sx={{ marginTop: "23px" }}>
            <Button
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                color: "red",
                marginLeft: isMobile ? 0 : "50px",
              }}
              onClick={() => LogOut()}
            >
              {languages[language].Logout}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserProfile;
