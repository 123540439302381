import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import FastButton from "../UI/FastButton";
import TextInput from "../Shared/TextInput";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { surveyService } from "../../services/Surveys";
import { Field } from "./Survey";
import { CreateSurveyRequest } from "../../services/requests/CreateSurveyRequest";
import { ImageDropZone } from "./helpers/ImageDropZone";
import DateTime from "./helpers/DateTime";
import NumberInput from "./helpers/NumberInput";
import SliderInput from "./helpers/SliderInput";
import SelectInput from "./helpers/SelectInput";
import { useModal } from "../../hooks/useModal";
import { useModalContent } from "../../hooks/useModalContent";
import { CreateFilter, FilterOptions } from "./helpers/CreateFilter";
import { filter } from "lodash";
import { DeleteFilterModal } from "./helpers/DeleteFilter";
import { useNavigate } from "react-router-dom";

const CreateSurvey: FC<{
  filterOptions: FilterOptions | undefined;
  setFilterCards: (options: FilterOptions[]) => void;
  onPost: () => void;
}> = ({ filterOptions, setFilterCards, onPost }) => {
  const { language } = useLang();
  const { setModal } = useModal();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [dataSelect, SetDataSelect] = useState<Field[]>();
  const [count, setCount] = useState<number>(0);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenCreateFilter = () => {
    setOpenCreate(true);
  };
  const handleOpenDeleteFilter = () => {
    setOpenDelete(true);
  };
  const handleCloseDeleteFilter = () => {
    setOpenDelete(false);
  };
  const handleCloseCreateFilter = () => {
    setOpenCreate(false);
  };
  const [surveyData, setSurveyData] = useState<CreateSurveyRequest>({
    title: {
      kaz: "",
      rus: "",
    },
    image_link: "",
    money: 0,
    estimated_responses: 0,
    finishes_at: "",
    link: {
      kaz: "",
      rus: "",
    },
    type: "survey",
    filter:
      filterOptions === undefined || filterOptions == null
        ? {
            age: {
              min: 16,
              max: 100,
            },
          }
        : filterOptions.filter,
  });
  if (dataSelect === undefined) {
    surveyService
      .getCreateSurveyFilters("A2ht7wIr")
      .then((response) => {
        SetDataSelect(response);
      })
      .catch((error) => alert(error));
  }

  const submit = () => {
    surveyService
      .CreateSurvey(surveyData)
      .then((response) => {
        setModal(false);
        onPost();
      })
      .catch((error) => alert(error));
  };

  const SaveFilter = () => {
    if (filterOptions === undefined || filterOptions === null)
      handleOpenCreateFilter();
    else
      surveyService
        .UpdateFilter({
          name: filterOptions.name,
          filter: surveyData.filter,
        })
        .then(() => {
          surveyService.getFilters().then((data) => {
            setFilterCards(data);
          });
          setModal(false);
        });
  };

  const DeleteFilter = () => {
    handleOpenDeleteFilter();
  };

  useEffect(() => {
    surveyService
      .GetFilterUsersCount({ lang: language, filter: surveyData.filter })
      .then((response) => {
        setCount(response.count);
      });
  }, [surveyData.filter]);
  return (
    <React.Fragment>
      <DeleteFilterModal
        title={languages[language].FilterDelete}
        open={openDelete}
        setOpen={setOpenDelete}
        handleClose={handleCloseDeleteFilter}
        submit={() => {
          if (filterOptions?.name === undefined) return;
          surveyService.DeleteFilter(filterOptions?.name).then(() => {
            surveyService.getFilters().then((data) => {
              setFilterCards(data);
            });
            setModal(false);
          });
        }}
      ></DeleteFilterModal>
      <CreateFilter
        title={languages[language].Save_Survey}
        filter={surveyData.filter}
        open={openCreate}
        setOpen={setOpenCreate}
        handleClose={handleCloseCreateFilter}
        setFilterCards={setFilterCards}
      />
      <Box
        sx={{
          width: "781px",
          overflowY: "auto",
          height: "80vh",
          paddingTop: "48px",
          borderTop: "1px solid #E0E0E0",
          marginTop: "24px",
        }}
      >
        <TextInput
          id="title.kaz"
          placeholder={languages[language].SurveyTitleKaz}
          data={surveyData}
          setData={setSurveyData}
        />

        <TextInput
          id="title.rus"
          placeholder={languages[language].SurveyTitleRus}
          data={surveyData}
          setData={setSurveyData}
        />

        <TextInput
          id="link.rus"
          placeholder={languages[language].SurveyLinkRus}
          data={surveyData}
          setData={setSurveyData}
        />

        <TextInput
          id="link.kaz"
          placeholder={languages[language].SurveyLinkKaz}
          data={surveyData}
          setData={setSurveyData}
        />

        <ImageDropZone data={surveyData} SetData={setSurveyData} />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "43px",
            marginTop: "16px",
          }}
        >
          <DateTime
            id="finishes_at"
            title={languages[language].Deadline}
            placeholder={languages[language].DeadlineDate}
            data={surveyData}
            setData={setSurveyData}
          />

          <NumberInput
            id="money"
            title={languages[language].SurveyMoney}
            placeholder={languages[language].SurveyMoneyPlaceHolder}
            data={surveyData}
            setData={setSurveyData}
          />
        </Box>

        <Box
          sx={{
            marginBottom: "16px",
          }}
        >
          {languages[language].Filter}
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "43px",
          }}
        >
          <NumberInput
            id="estimated_responses"
            title={languages[language].SurveyRespondentsCount}
            placeholder={languages[language].SurveyRespondentsCountPlaceHolder}
            data={surveyData}
            setData={setSurveyData}
          />
          <SliderInput
            id="filter.age"
            title={languages[language].SurveyFilterAge}
            data={surveyData}
            setData={setSurveyData}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "43px",
          }}
        >
          {dataSelect !== undefined ? (
            dataSelect.map((field: Field) => {
              return (
                <Box>
                  <Box
                    sx={{
                      padding: "8px 0",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {field.title[language]}
                  </Box>
                  <SelectInput
                    options={field.labels[language]}
                    id={field.id}
                    data={surveyData}
                    setData={setSurveyData}
                  />
                </Box>
              );
            })
          ) : (
            <></>
          )}
        </Box>
        <Box sx={{ paddingTop: "40px" }}>
          {languages[language].Appropriate}
          {count}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "100px",
          }}
        >
          <Box>
            <FastButton
              click={() => setModal(false)}
              style={{
                padding: "8px 30px",
                fontSize: "14px",
                border: "none",
                background: "none",
              }}
            >
              {languages[language].Cancel}
            </FastButton>
            {filterOptions !== undefined && filterOptions !== null ? (
              <FastButton
                click={() => DeleteFilter()}
                style={{
                  padding: "8px 30px",
                  fontSize: "14px",
                  border: "none",
                  background: "none",
                  color: "red",
                }}
              >
                {languages[language].FilterDelete}
              </FastButton>
            ) : (
              <></>
            )}
          </Box>

          <Box>
            <FastButton
              click={() => SaveFilter()}
              style={{
                padding: "8px 30px",
                fontSize: "14px",
                border: "none",
                background: "none",
              }}
            >
              {languages[language].Save_Survey}
            </FastButton>
            <FastButton
              click={() => {
                submit();
              }}
              variant={"contained"}
              style={{
                padding: "8px 30px",
                fontSize: "14px",
                backgroundColor: disabled ? "#AEAEAE" : "#333333",
              }}
              disabled={disabled}
            >
              {languages[language].Post}
            </FastButton>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CreateSurvey;
