import { Button, TextInput } from "@mantine/core";
import { modals } from "@mantine/modals";
import { FC, useState } from "react";
import useLang from "../../../hooks/useLang";
import { useForm } from "@mantine/form";
import { useMutation } from "react-query";
import { UpdateUserRequest } from "../../../services/requests/UpdateUserRequest";
import { userService } from "../../../services/UserService";
import { CreateInvoiceResponse } from "../../../services/responses/CreateInvoiceResponse";
import { ErrorResponse } from "../../../models/error";
import { notifications } from "@mantine/notifications";

const languages = {
  kaz: {
    label: "Сіздің поштаңыз",
    placeholder: "example@pikirlab.kz",
    submit: "Поштаны сақтау",
    inner_title: "Поштаны растау",
    notification: {
      title: "Электрондық поштаңызды растаңыз",
      body: "Біз сіздің поштаңызға белсендіретін сілтеме бар хат жібердік, оны 1 күн ішінде растаңыз.",
    },
  },
  rus: {
    label: "Ваша почта",
    placeholder: "example@pikirlab.kz",
    submit: "Сохранить почту",
    inner_title: "Подтвердить почту",
    notification: {
      title: "Подтвердите вашу электронную почту",
      body: "Мы выслали письмо с активирующей ссылкой на вашу почту, подтвердите ее в течение 1 дня.",
    },
  },
};

interface Props {
  refetch: () => void;
}

export const SetEmailModal: FC<Props> = ({ refetch }) => {
  const { language } = useLang();
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  const mutation = useMutation(
    async (data: UpdateUserRequest) => {
      setLoading(true);
      return await userService.UpdateUser(data);
    },
    {
      onSuccess: (data: CreateInvoiceResponse) => {
        modals.close("set_email_modal");
        notifications.show({
          style: {
            top: 0,
          },
          title: languages[language].notification.title,
          message:  languages[language].notification.body,
        });
        refetch();
      },
      onError: (error: any) => {
        console.log(error);
        setLoading(false);
        if (error.response.status === 403) {
          form.setFieldError(
            "email",
            error?.response?.data?.error?.[0]?.message
          );
        }
      },
    }
  );

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={form.onSubmit((values) => mutation.mutate(values))}
    >
      <TextInput
        label={languages[language].label}
        placeholder={languages[language].placeholder}
        data-autofocus
        {...form.getInputProps("email")}
      />
      <Button
        fullWidth
        loading={loading}
        styles={(theme) => ({
          root: {
            background: "#73A9C2",
            ["&:hover "]: {
              background: theme.fn.darken("#73A9C2", 0.1),
            },
          },
        })}
        type="submit"
        mt="md"
      >
        {languages[language].submit}
      </Button>
    </form>
  );
};
