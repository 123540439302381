import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import FastIcon from "./FastIcon";
import { Box } from "@mui/system";

const SuccessInput = styled(TextField)(() => ({
  width: "491px",
  height: "32px",
  "& input": {
    fontWeight: 400,
    lineHeight: "10px",
    color: "#000000",
    opacity: 0.6,
    borderRadius: "10px",
    padding: "0px 10px 0px 35px",
    height: "40px",
  },
  "& label": {
    left: "8px",
    top: "-5px",

    "&.MuiInputLabel-shrink": {
      left: "0px",
      top: "0px",
    },
  },
}));

const FastSearch = ({ text, style, type = "text", change, ...props }: any) => {
  return (
    <Box sx={{ position: "relative" }}>
      <FastIcon
        name={"searchPanel"}
        sx={{ position: "absolute", top: "20px", left: "20px", width: "15px" }}
      />
      <SuccessInput
        label={text}
        variant="outlined"
        onChange={change}
        style={style}
        type={type}
        {...props}
      />
    </Box>
  );
};

export default FastSearch;
