import { FC } from "react";
import MainLayout from "../components/Shared/MainLayout";
import { ListTransactions } from "../components/Transactions/ListTransactions";

export const TransactionsPage: FC = () => {
  return (
    <MainLayout>
      <ListTransactions />
    </MainLayout>
  );
};
