import { Box } from "@mantine/core";
import { FC, ReactElement, useState } from "react";
import useLang from "../../../hooks/useLang";
import FastInput, { FastInputProps } from "../../UI/FastInput";
import { FormErrors } from "@mantine/form";

const languages = {
  kaz: {
    Refer_code: "Рефералды кодты енгізу",
    Label: "Рефералды кодты еңгізіңіз",
  },
  rus: {
    Refer_code: "Ввести реферальный код",
    Label: "Введите реферальный код",
  },
};

const TextInput: FC<FastInputProps> = (props: FastInputProps): ReactElement => {
  return (
    <FastInput
      InputProps={{
        sx: {
          borderRadius: 3,
          backgroundColor: "#FFFFFF",
        },
      }}
      style={{
        marginBottom: "18px",
        borderRadius: "20px",
      }}
      {...props}
    />
  );
};

interface UserData {
  first_name: string;
  last_name: string;
  birth_date: Date | null;
  promo?: string;
}

interface Props {
  data: UserData;
  errors: FormErrors;
  SetData: (data: UserData) => void;
}

export const Promo: FC<Props> = ({ data, SetData, errors }) => {
  const [OpenPromo, setOpenPromo] = useState<boolean>(false);
  const { language } = useLang();
  return (
    <Box
      sx={{
        paddingBottom: "31px",
      }}
    >
      <Box
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "17px",
        }}
      >
        <span
          onClick={() => setOpenPromo(true)}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            textUnderlineOffset: "2px",
          }}
        >
          {languages[language].Refer_code}
        </span>
      </Box>
      {OpenPromo ? (
        <TextInput
          label={languages[language].Label}
          style={{
            marginBottom: "18px",
            marginTop: "20px",
          }}
          value={data.promo}
          change={(event: React.ChangeEvent<HTMLInputElement>) => {
            SetData({ ...data, promo: event.target.value });
          }}
          error={errors["user.promo"] !== undefined}
          helperText={errors["user.promo"]?.toString()}
        />
      ) : null}
    </Box>
  );
};
