import { Box, alertTitleClasses } from "@mui/material";
import FastButton from "../../UI/FastButton";
import "dayjs/locale/ru";
import { useState } from "react";
import { dataService } from "../../../services/getLogin";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import useMobile from "../../../hooks/useMobile";
import useLang from "../../../hooks/useLang";
import { languages } from "../../../Languages/languages";
import { ResetPasswordRequest } from "../../../services/requests/ResetPasswordRequest";
import { MuiTelInput } from "mui-tel-input";
import InputForm from "./InputForm";
import { Error, InputFieldData, StepsData } from "./types";
import { authService } from "../../../services/auth";
import { VerifyAccountRequest } from "../../../services/requests/VerifyAccountRequest";
import { LoginResponse } from "../../../services/responses/LoginResponse";
import { Loader } from "@mantine/core";
import { ResendVerificationResponse } from "../../../services/responses/ResendVerificationResponse";
import { notifications } from "@mantine/notifications";
import { ErrorResponse } from "../../../models/error";

const Form: React.FC = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState<Error>({
    0: true,
    1: true,
    2: true,
  });
  const { isMobile } = useMobile();
  const { language } = useLang();
  const [step, setStep] = useState(0);
  const { setUserData } = useUser();
  const [requestData, setRequestData] = useState<ResetPasswordRequest>({
    phone_number: "",
    password: "",
    code: "",
  });
  const [progress, setProgress] = useState<{ [key: number]: boolean }>({
    1: false,
    2: false,
  });

  const steps: StepsData[] = [
    {
      title: "Восстановление пароля",
      description:
        "Введите номер телефона который указывался во время регистраций. \n" +
        "Мы отправим код для восстановления на номер который вы укажете  ",
      fields: [{ type: "phone_number", label: "Введите номер телефона" }],
    },
    {
      title: "Код отправлен",
      description:
        "Проверьте сообщения.\n" +
        "Код для восстановления пароля была отправлена на ваш номер",
      fields: [
        { type: "new_password", label: languages[language].Password_new },
        {
          type: "confirm_password",
          label: languages[language].Sign_in_PassVer,
        },
      ],
    },
    {
      title: "Код отправлен",
      description:
        "Проверьте сообщения.\n" +
        "Код для восстановления пароля была отправлена на ваш номер",
      fields: [{ type: "code", label: languages[language].EnterCode }],
    },
  ];

  const GetSMS = () => {
    const temp: ResetPasswordRequest = requestData;
    setProgress({ ...progress, 1: true });
    authService
      .GetSMSCode(temp)
      .then(() => {
        setProgress({ ...progress, 1: false });
        setStep(2);
      })
      .catch(
        (err: {
          response: { data: { error: { title: string; message: string } } };
        }) => {
          setProgress({ ...progress, 1: false });
          notifications.show({
            title: err.response.data.error.title,
            message: err.response.data.error.message,
            autoClose: 10000,
            styles: (theme) => ({
              root: {
                borderColor: "#73A9C2",
                "&::before": { backgroundColor: theme.colors.red[5] },
              },
              title: { color: theme.colors.red[5] },
              closeButton: {
                color: theme.white,
                "&:hover": { backgroundColor: theme.colors.blue[7] },
              },
            }),
          });
        }
      );
  };

  type ResponseType =
    | LoginResponse
    | ResendVerificationResponse
    | ErrorResponse;

  const SendCode = () => {
    const temp: VerifyAccountRequest = requestData;
    authService
      .VerifyAccount(temp, language)
      .then((response: ResponseType) => {
        if ("access_token" in response) {
          setUserData(response);
          window.localStorage.setItem("token", response.access_token);
          navigate("/dashboard");
        } else {
          alert((response as ErrorResponse).response.data.error);
          notifications.show({
            title: languages[language].Error,
            message: languages[language].EmptyFields,
            autoClose: 10000,
            styles: (theme) => ({
              root: {
                borderColor: "#73A9C2",
                "&::before": { backgroundColor: theme.colors.red[5] },
              },
              title: { color: theme.colors.red[5] },
              closeButton: {
                color: theme.white,
                "&:hover": { backgroundColor: theme.colors.blue[7] },
              },
            }),
          });
        }
      })
      .catch((err: string) => {
        alert("Аккаунт с данным номером телефона не найден");
      });
  };

  const BtnType = ({ step }: any) => {
    if (step === 0) {
      return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <FastButton
            variant={"contained"}
            style={{ padding: "8px 30px", fontSize: "14px" }}
            click={() => setStep(1)}
            disabled={err[step]}
          >
            {languages[language].Next}
          </FastButton>
          <FastButton
            click={() => navigate(-1)}
            style={{
              padding: "8px 30px",
              fontSize: "14px",
              border: "none",
              background: "none",
              color: "#000000",
              opacity: "0.5",
            }}
          >
            {languages[language].ReturnBack}
          </FastButton>
        </Box>
      );
    } else if (step === 1) {
      return (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FastButton
            click={() => setStep(0)}
            style={{
              padding: "8px 30px",
              fontSize: "14px",
              border: "none",
              background: "none",
              color: "#000000",
              opacity: "0.5",
            }}
          >
            {languages[language].ReturnBack}
          </FastButton>
          <FastButton
            variant={"contained"}
            style={{ padding: "8px 30px", fontSize: "14px" }}
            click={() => GetSMS()}
            disabled={err[step] || progress[step]}
          >
            {progress[step] ? (
              <Loader color="white" size="1.5rem" />
            ) : (
              languages[language].Send
            )}
          </FastButton>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FastButton
            click={() => setStep(1)}
            style={{
              padding: "8px 30px",
              fontSize: "14px",
              border: "none",
              background: "none",
              color: "#000000",
              opacity: "0.5",
            }}
          >
            {languages[language].ReturnBack}
          </FastButton>
          <FastButton
            variant={"contained"}
            style={{ padding: "8px 30px", fontSize: "14px" }}
            click={() => SendCode()}
            disabled={progress[step]}
          >
            {progress[step] ? (
              <Loader color="white" size="1.5rem" />
            ) : (
              languages[language].SavePassword
            )}
          </FastButton>
        </Box>
      );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            fontWeight: "700",
            fontSize: "22px",
            lineHeight: "15px",
            paddingBottom: isMobile ? "unset" : "7px",
            padding: isMobile ? "17px" : "unset",
          }}
        >
          {languages[language].Forget_Pass_title}
        </Box>

        {!isMobile && (
          <Box
            sx={{
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "15px",
              color: "#000000",
              opacity: 0.6,
              marginTop: "10px",
              paddingBottom: "20px",
            }}
          >
            {languages[language].Forget_Pass_subtitle}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: isMobile ? "17px" : "unset",
          }}
        >
          {steps[step].fields.map((el: InputFieldData) => {
            return (
              <InputForm
                el={el}
                data={requestData}
                setData={setRequestData}
                error={err}
                setError={setErr}
              />
            );
          })}
          <BtnType step={step} />
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
