import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import PopUp from "../Shared/PopUp";
import FastIcon from "../UI/FastIcon";
import { surveyService } from "../../services/Surveys";
import { useModal } from "../../hooks/useModal";
import { useModalContent } from "../../hooks/useModalContent";
import CreateSurvey from "./CreateSurvey";
import useLang from "../../hooks/useLang";
import { languages } from "../../Languages/languages";
import { FilterOptions } from "./helpers/CreateFilter";
import { Grid } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const AddSurveys = () => {
  const { language } = useLang();
  const [filterCards, setFilterCards] = useState<FilterOptions[]>([]);
  const { setModal } = useModal();
  const navigate = useNavigate();
  const { setModalContent } = useModalContent();

  const createSurvey = (filterOption?: FilterOptions) => {
    setModalContent({
      title: "Создать опрос",
      content: (
        <CreateSurvey
          filterOptions={filterOption}
          setFilterCards={setFilterCards}
          onPost={() => navigate(0)}
        />
      ),
    });
    setModal(true);
  };

  useEffect(() => {
    surveyService.getFilters().then((data) => {
      setFilterCards(data);
    });
  }, []);

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
        borderRadius: "14px",
        padding: "21px 16px",
        margin: "30px",
        overflowX: "auto",
        marginTop: "140px",
      }}
    >
      <Typography
        sx={{
          paddingBottom: "20px",
        }}
      >
        {languages[language].CreateSurvey}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ paddingRight: "20px" }}>
          <FastIcon
            name={"addSurvey"}
            onClick={() => {
              createSurvey(undefined);
            }}
            style={{ cursor: "pointer" }}
          />
          <Typography>{languages[language].New_survey}</Typography>
        </Box>

        {filterCards?.map((filterOptions: FilterOptions) => {
          return (
            <Box sx={{ paddingRight: "20px" }}>
              <Typography></Typography>
              <FastIcon
                name={"addSurvey"}
                onClick={() => {
                  createSurvey(filterOptions);
                }}
                style={{ cursor: "pointer" }}
              />
              <Typography>{filterOptions.name}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AddSurveys;
