import { Box, Modal } from "@mui/material";
import { FC, Fragment, useState } from "react";
import Icon from "../Icon/icon";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
  background: "#fff5f5",
  borderRadius: "24px",
  border: "2px solid red",
  minWidth: "358px",
  minHeight: "264px",
  padding: "24px",
};

export interface ErrorModalProps {
  isOpen: boolean;
  close: () => void;
  title: string;
  message: string;
}

export const ErrorModal: FC<ErrorModalProps> = ({
  isOpen,
  close,
  title,
  message,
}) => {
  const handleClose = () => {
    close();
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontWeight: 700, fontSize: "22px", lineHeight: "27px" }}>
            {title}
          </Box>
          <Box>
            <button
              onClick={() => handleClose()}
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                padding: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              {Icon.exit}
            </button>
          </Box>
        </Box>
        <Box>{message}</Box>
      </Box>
    </Modal>
  );
};
