import MainLayout from "../components/Shared/MainLayout";
import { Box, Typography, IconButton } from "@mui/material";
import FastButton from "../components/UI/FastButton";
import FastIcon from "../components/UI/FastIcon";
import LangSwitch from "../components/Shared/LangSwitch";
import useLang from "../hooks/useLang";
import { languages } from "../Languages/languages";
import useTablet from "../hooks/useTablet";
import useMobile from "../hooks/useMobile";
import { useEffect, useState } from "react";
import useLoading from "../hooks/useLoading";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const { setTabletFun } = useTablet();
  const { setMobileFun } = useMobile();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  setLoading(false);
  useEffect(() => {
    if (window.innerWidth > 600 && window.innerWidth <= 800) {
      setTabletFun(true);
      setMobileFun(false);
    } else if (window.innerWidth <= 600) {
      setTabletFun(false);
      setMobileFun(true);
    } else {
      setMobileFun(false);
      setTabletFun(false);
    }
  }, [width]);

  useEffect(() => {}, [window.innerWidth]);
  const { language } = useLang();
  return (
    <Box
      sx={{
        backgroundColor: "#FBF7F4",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          padding: "40px",
        }}
      >
        <FastIcon
          name={"logo"}
          height={"20px"}
          width={"98px"}
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "30px",
        }}
      >
        <LangSwitch />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography
          align="center"
          sx={{
            fontSize: "72px",
            fontWeight: 700,
          }}
        >
          404
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "34px",
          }}
          gutterBottom
        >
          {languages[language].Not_First_Title}
        </Typography>
      </Box>
      <FastButton
        style={{
          width: "auto",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "12px",
          padding: "20px 50px",
          borderRadius: "10px",
          height: "39px",
        }}
        variant={"contained"}
        click={() => {
          navigate("/");
        }}
      >
        {languages[language].Not_Second_Title}
      </FastButton>
    </Box>
  );
};

export default NotFound;
